import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Avatar,
    Center,
    Button,
    ButtonGroup,
    Icon,
    Modal,
    ModalOverlay,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup,
    Textarea,
    Tab,
    Tabs,
    TabIndicator,
    TabList,
    TabPanels,
    TabPanel,
    Tr,
    Th,
    Td,
    Table,
    Thead,
    Tbody,
    Tfoot,
    TableContainer,
    useToast
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import BuyerDashboardParentPage from '../buyer/utils/BuyerDashboardParentPage.js';

import {
    FaBan,
    FaCheck,
    FaHouse,
    FaBolt,
    FaAddressCard,
    FaLocationDot,
    FaHandshakeSimple,
    FaUser,
    FaUserPlus,
    FaFileLines
} from 'react-icons/fa6';
import { IoWarningOutline } from "react-icons/io5";

import {
    AGENCY_AGREEMENT,
    PURCHASE_AND_SALE_AGREEMENT,
    CLOSING_DISCLOSURE,
    ABOUT_DESCRIPTION,
    ASSET_SPECIALTY,
    FIRST_NAME,
    LAST_NAME,
    LICENSE_TYPE,
    GET,
    AGENTS,
    ID,
    FIRM,
    SALE_EXPERTISE,
    REPRESENTATION,
    ADDRESS_CITY,
    ADDRESS_LINE_ONE,
    ADDRESS_LINE_TWO,
    ADDRESS_STATE,
    ADDRESS_ZIP_CODE,
    DEFAULT_POST_TIMEOUT,
    NOT_INTERESTED_BY_BUYER,
    TRANSACTION_DATE,
    PURCHASE_PRICE,
    REPRESENTATION_STYLE,
    WORKING_STYLE,
    PROFILE_PICTURE_URL,
    POST,
    SINGLE_FAMILY_HOME,
    DEFAULT_GET_TIMEOUT,
    COMMISSION_PERCENTAGE,
    NUMBER_OF_MONTHS,
    RETAINER_FEE_DOLLARS,
    RETAINER_PART_OF_COMMISSION,
    IS_EXCLUSIVE,
    RESIROOTS_DEFAULT_OFFER,
    CONNECTION_REMOVED_BY_BUYER
} from '../../utils/constants.js';

import { axiosGetUser } from './methods/methods.js';

import { prettifyDate, convertStringToPrice } from '../../utils/utility.js';
import MainPanel from './MainPanel.js';
import SidePanelWrapper from './SidePanelWrapper.js';

import './AgentProfile.css';

function AgentProfilePage(props) {

    const navigate = useNavigate();

    const toast = useToast();
    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);

    const [agent, setAgent] = useState(props.agent);
    const [agentLoading, setAgentLoading] = useState(true);

    const [isActiveAgent, setIsActiveAgent] = useState(false);
    const [isActiveAgentLoading, setIsActiveAgentLoading] = useState(true);
    const [activeAgentOffer, setActiveAgentOffer] = useState(null);

    const externalView = props.externalView;
    const [agentTransactions, setAgentTransactions] = useState(null);
    const [agentAreasOfExpertise, setAgentAreasOfExpertise] = useState(null);

    const [showConfirmUnmatchModal, setShowConfirmUnmatchModal] = useState(false);

    const urlParams = useParams();

    const userId = externalView ? "" : urlParams["userId"];
    const agentId = externalView && agent ? agent[ID] : urlParams["agentId"];

    useEffect(() => {
        if (!externalView) {
            axiosGetUser(userId).then(user => {
                if (user) {
                    setUser(user);
                    setUserLoading(false);
                }
            }).catch(error => {
                // TODO
            });
        } else {
            setAgentLoading(false);
            setUserLoading(false);
            setIsActiveAgentLoading(false);
        }
    }, [externalView, userId]);

    useEffect(() => {

        axios.all([
            axios({
                method: GET,
                url: '/api/v1/user/agent/transactions/' + agentId,
                timeout: DEFAULT_GET_TIMEOUT,
            }),
            axios({
                method: GET,
                url: '/api/v1/user/agent/expertise_areas/' + agentId,
                timeout: DEFAULT_GET_TIMEOUT,
            })
        ]).then(axios.spread((transactionsResponse, areasOfExpertiseResponse) => {
            if (200 === transactionsResponse.status) {
                let transactions = transactionsResponse.data;
                setAgentTransactions(transactions);
            }
            if (200 === areasOfExpertiseResponse.status) {
                let areasOfExpertise = areasOfExpertiseResponse.data;
                setAgentAreasOfExpertise(areasOfExpertise);
            }
        })).catch(error => {
            // TODO
        });
    }, [agentId]);

    useEffect(() => {
        if (!externalView) {
            axios.all([
                axios({
                    method: GET,
                    url: '/api/v1/user/buyer/' + userId + '/agent/' + agentId,
                    timeout: DEFAULT_GET_TIMEOUT,
                }),
                axios({
                    method: GET,
                    url: '/api/v1/user/buyer/agent/active/id/' + userId,
                    timeout: DEFAULT_GET_TIMEOUT,
                })
            ]).then(axios.spread((agentResponse, activeResponse) => {
                if (200 === agentResponse.status) {
                    let agent = agentResponse.data;
                    setAgent(agent);
                    setAgentLoading(false);
                }
                if (200 === activeResponse.status) {
                    let activeAgent = activeResponse.data;
                    if (activeAgent === null) {
                        setIsActiveAgentLoading(false);
                        return;
                    }
                    let activeAgentId = activeAgent[ID];
                    // This is the buyer's current active agent.
                    if (activeAgentId === agentId) {
                        // Fetch the offer agent made us.
                        axios({
                            method: GET,
                            url: '/api/v1/user/buyer/offer/' + userId + '?agent_id=' + agentId,
                            timeout: DEFAULT_GET_TIMEOUT,
                        }).then(response => {
                            let offer = response.data;
                            setActiveAgentOffer(offer);
                        }).catch(error => {
                            // TODO
                        })
                        setIsActiveAgent(true);
                        setIsActiveAgentLoading(false);
                    }
                }
            })).catch(error => {
                setIsActiveAgentLoading(false);
            });
            setIsActiveAgentLoading(false);
        }
    }, [agentId, externalView, userId])

    const handleAddConnection = (agentId, status, callback) => {

        axios({
            method: POST,
            url: '/api/v1/user/connection/' + user[ID] + '?user_type=Buyer',
            timeout: DEFAULT_POST_TIMEOUT,
            data: {
                buyer_id: user[ID],
                agent_id: agentId,
                statuses: [{
                    status: status,
                }]
            }
        }).then(response => {
            if (200 === response.status) {
                callback();
            }
        }).catch(error => {
            toast({
                title: "Error",
                description: `${error}`,
                status: 'error',
                duration: 6000,
                isClosable: true,
                position: 'top'
            })
        })
    }
    
    const getPrettifySaleExpertise = (val) => {
        switch (val) {
            case "ShortSale":
                return "Short Sale";
            default:
                return val;
        }
    }
    
    const renderSaleExpertise = () => {
        if (!agent || !agent[SALE_EXPERTISE] || null === agent[SALE_EXPERTISE]) {
            return "Not Specified";
        }
        let saleExpertise = agent[SALE_EXPERTISE];

        let result = [];
        for (var i = 0; i < saleExpertise.length; i++) {
            result.push(getPrettifySaleExpertise(saleExpertise[i]));
            result.push(<br></br>);
        }
        return result;
    }

    const renderAreasOfExpertise = (agentAreasOfExpertise) => {
        if (agentAreasOfExpertise === null || undefined === agentAreasOfExpertise) {
            return "-";
        }

        let areasOfExpertise = [];
        for (let i = 0; i < agentAreasOfExpertise.length; i++) {
            areasOfExpertise.push(
                <p style={{
                    margin: "0px 0px 2.5px 0px",
                }}>
                    {agentAreasOfExpertise[i]["formatted_address"]}
                </p>
            );
        }

        return areasOfExpertise;
    }

    const getPrettifyAssetSpecialty = (val) => {
        switch (val) {
            case SINGLE_FAMILY_HOME:
                return "Single Family Home";
            default:
                return val;
        }
    }

    const renderAssetSpecialty = () => {
        if (!agent || !agent[ASSET_SPECIALTY] || null === agent[ASSET_SPECIALTY]) {
            return "Not Specified";
        }
        let assetSpecialty = agent[ASSET_SPECIALTY];

        let result = [];
        for (var i = 0; i < assetSpecialty.length; i++) {
            result.push(getPrettifyAssetSpecialty(assetSpecialty[i]));
            result.push(<br></br>);
        }
        return result;
    }

    const AboutPanel = () => {
        return (
            <div style={{
                borderRadius: "4px",
                display: "flex",
                height: "inherit"
            }}>
                <div style={{
                    minWidth: "200px",
                }}>
                    <div className="about_panel__info_box">
                        <p className="about_panel__title">
                            AREAS OF COVERAGE
                        </p>
                        <div style={{
                            display: "flex",
                            margin: "5px 0px 0px 0px"
                        }}>
                            <Icon as={FaLocationDot} className="agent_profile_page__about_panel__info_icon"/>
                            <p style={{
                                lineHeight: "20px",
                                margin: "0px 0px 0px 7.5px",
                            }}>
                                {agentAreasOfExpertise ? renderAreasOfExpertise(agentAreasOfExpertise) : "-"}
                            </p>
                        </div>
                    </div>

                    <div className="about_panel__info_box">
                        <p className="about_panel__title">
                            LICENSE
                        </p>
                        <div style={{
                            display: "flex",
                            margin: "5px 0px 0px 0px"
                        }}>
                            <Icon as={FaAddressCard} className="agent_profile_page__about_panel__info_icon"/>
                            <p style={{
                                lineHeight: "20px",
                                margin: "0px 0px 0px 7.5px",
                            }}>
                                {agent && agent[LICENSE_TYPE] ? agent[LICENSE_TYPE] : "Not Specified"}
                            </p>
                        </div>
                    </div>
                    <div className="about_panel__info_box">
                        <p className="about_panel__title">
                            REPRESENTATION STYLE
                        </p>
                        <div style={{
                            display: "flex",
                            margin: "5px 0px 0px 0px"
                        }}>
                            <Icon as={FaHandshakeSimple} className="agent_profile_page__about_panel__info_icon"/>
                            <p style={{
                                lineHeight: "20px",
                                margin: "0px 0px 0px 7.5px",
                            }}>
                                {agent && agent[REPRESENTATION_STYLE] ? agent[REPRESENTATION_STYLE] : "Not Specified"}
                            </p>
                        </div>
                    </div>
                    <div className="about_panel__info_box">
                        <p className="about_panel__title">
                            WORKING STYLE
                        </p>
                        <div style={{
                            display: "flex",
                            margin: "5px 0px 0px 0px"
                        }}>
                            <Icon as={FaUser} className="agent_profile_page__about_panel__info_icon"/>
                            <p style={{
                                lineHeight: "20px",
                                margin: "0px 0px 0px 7.5px",
                            }}>
                                {agent && agent[WORKING_STYLE] ? agent[WORKING_STYLE] : "Not Specified"}
                            </p>
                        </div>
                    </div>
                    <div className="about_panel__info_box">
                        <p className="about_panel__title">
                            SALE EXPERTISE
                        </p>
                        <div style={{
                            display: "flex",
                            margin: "5px 0px 0px 0px"
                        }}>
                            <Icon as={FaBolt} className="agent_profile_page__about_panel__info_icon"/>
                            <p style={{
                                lineHeight: "20px",
                                margin: "0px 0px 0px 7.5px",
                            }}>
                                {renderSaleExpertise()}
                            </p>
                        </div>
                    </div>
                    <div className="about_panel__info_box">
                        <p className="about_panel__title">
                            ASSET SPECIALITIES
                        </p>
                        <div style={{
                            display: "flex",
                            margin: "5px 0px 0px 0px"
                        }}>
                            <Icon as={FaHouse} className="agent_profile_page__about_panel__info_icon"/>
                            <p style={{
                                lineHeight: "20px",
                                margin: "0px 0px 0px 7.5px",
                            }}>
                                {agent && agent[ASSET_SPECIALTY] ? renderAssetSpecialty() : "Not Specified"}
                            </p>
                        </div>
                    </div>
                </div>
                <div style={{
                    backgroundColor: "#cccccc",
                    height: "inherit",
                    marginLeft: "40px",
                    marginRight: "30px",
                    width: "1px"
                }}></div>
                <div>
                    <p className="about_panel__description_title">
                        ABOUT {agent && agent[FIRST_NAME] ? agent[FIRST_NAME].toUpperCase() : ""}
                    </p>
                    <p style={{
                        lineHeight: "22.5px",
                        margin: "7.5px 0px 0px 0px",
                    }}>
                        {agent && agent[ABOUT_DESCRIPTION] ? agent[ABOUT_DESCRIPTION] : "Not Available"}
                    </p>
                </div>
            </div>
        );
    }

    const DocumentsPanel = () => {

        const [tabIndex, setTabIndex] = useState(0);
        const tabToFileTypeMap = new Map([
            [0, AGENCY_AGREEMENT],
            [1, PURCHASE_AND_SALE_AGREEMENT],
            [2, CLOSING_DISCLOSURE],
        ]);

        const [documentsLoading, setDocumentsLoading] = useState(true);

        const [agencyAgreementURL, setAgencyAgreementURL] = useState("");
        const [purchaseAndSaleAgreementURL, setPurchaseAndSaleAgreementURL] = useState("");
        const [closingDisclosureURL, setClosingDisclosureURL] = useState("");
        
        useEffect(() => {
            axios({
                method: GET,
                url: '/api/v1/user/buyer/active_agent/files/' + userId + '/' + agentId,
            }).then(response => {
                if (200 === response.status) {
                    let documentURLs = response.data;
                    setAgencyAgreementURL(documentURLs["agency_agreement_public_url"]);
                    setPurchaseAndSaleAgreementURL(documentURLs["purchase_and_sale_agreement_public_url"]);
                    setClosingDisclosureURL(documentURLs["closing_disclosure_public_url"]);
                    setDocumentsLoading(false);
                }
            }).catch(error => {
                setDocumentsLoading(false);
            })
        }, []);

        return (
            <Tabs align="start" orientation="vertical" variant="unstyled" index={tabIndex}>
                <TabList style={{
                    margin: "10px 0px 0px 0px",
                    width: "300px",
                }}>
                    <Tab className="agent__client_page__tab" onMouseDown={() => setTabIndex(0)} style={{
                        backgroundColor: tabIndex === 0 ? "#efefef" : "",
                    }}>
                        <FaFileLines className="agent__client_page__tab_icon"/>
                        <p className="agents__clients_page__document_modal__document_text">
                            Agency Agreement
                        </p>
                    </Tab>
                    <Tab className="agent__client_page__tab" onMouseDown={() => setTabIndex(1)} style={{
                        backgroundColor: tabIndex === 1 ? "#efefef" : "",
                    }}>
                        <FaFileLines className="agent__client_page__tab_icon"/>
                        <p className="agents__clients_page__document_modal__document_text">
                            Purchase & Sale Agreement
                        </p>
                    </Tab>
                    <Tab className="agent__client_page__tab" onMouseDown={() => setTabIndex(2)} style={{
                        backgroundColor: tabIndex === 2 ? "#efefef" : "",
                    }}>
                        <FaFileLines className="agent__client_page__tab_icon"/>
                        <p className="agents__clients_page__document_modal__document_text">
                            Closing Disclosure
                        </p>
                    </Tab>
                </TabList>
                <TabPanels >
                    <TabPanel className="tabs__tab_panel" index={0} >
                        {
                            documentsLoading ?
                            // Default values shown
                            <div className="user__clients_page_document_modal__default_background">
                                <div className="user__clients_page_document_modal__loading_spinner_box">
                                    <l-dot-pulse
                                        size="40"
                                        speed="1.5" 
                                        color="grey" 
                                    ></l-dot-pulse>
                                </div>
                            </div> : 
                            (agencyAgreementURL && agencyAgreementURL !== "" ?
                            <embed src={agencyAgreementURL + "#navpanes=0"} className="agent_profile__document_modal__embed ">
                            </embed> :
                            <NoDocumentObject/>)
                        }
                    </TabPanel>
                    <TabPanel className="tabs__tab_panel" index={1}>
                        {
                            documentsLoading ?
                            // Default values shown
                            <div className="user__clients_page_document_modal__default_background">
                                <div className="user__clients_page_document_modal__loading_spinner_box">
                                    <l-dot-pulse
                                        size="40"
                                        speed="1.5" 
                                        color="grey" 
                                    ></l-dot-pulse>
                                </div>
                            </div> : 
                            (purchaseAndSaleAgreementURL && purchaseAndSaleAgreementURL !== "" ?
                            <embed src={purchaseAndSaleAgreementURL + "#navpanes=0"} className="agent_profile__document_modal__embed ">
                            </embed> :
                            <NoDocumentObject/>)
                        }
                    </TabPanel>
                    <TabPanel className="tabs__tab_panel" index={2}>
                        {
                            documentsLoading ?
                            // Default values shown
                            <div className="user__clients_page_document_modal__default_background">
                                <div className="user__clients_page_document_modal__loading_spinner_box">
                                    <l-dot-pulse
                                        size="40"
                                        speed="1.5" 
                                        color="grey" 
                                    ></l-dot-pulse>
                                </div>
                            </div> :
                            (closingDisclosureURL && closingDisclosureURL !== "" ?
                            <embed src={closingDisclosureURL + "#navpanes=0"} className="agent_profile__document_modal__embed ">
                            </embed> :
                            <NoDocumentObject/>)
                        }
                    </TabPanel>
                </TabPanels>
            </Tabs>
        );
    }

    const NoDocumentObject = () => {
        return (
            <div style={{
                backgroundColor: "#efefef",
                borderRadius: "4px",
                float: "left",
                height: "calc(100vh - 100px - 75px",
                margin: "0px 25px 0px 25px",
                padding: "0px 25px 0px 25px",
                width: "calc(100% - 50px)",
            }}>
                <div style={{
                    margin: "250px 0px 50px 0px",
                }}>
                    <IoWarningOutline style={{
                        height: "40px",
                        margin: "0px auto 0px auto",
                        width: "40px",
                    }}/>
                    <p style={{
                        fontWeight: "bold",
                        margin: "15px 0px 0px 0px",
                        textAlign: "center",
                    }}>
                        NO FILE UPLOADED
                    </p>
                </div>
            </div>
        )
    }

    // View of only the information of agent, sans nav bars or sidebar.
    const InfoView = () => {

        const [tabIndex, setTabIndex] = useState(0);
        const [showContactAgentModal, setShowContactAgentModal] = useState(false);

        const ConfirmUnmatchModal = () => {
            return (
                <Modal size='md' isOpen={showConfirmUnmatchModal} onClose={() => setShowConfirmUnmatchModal(false)} isCentered>
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader style={{
                            paddingBottom: "0px",
                        }}>Are you sure?</ModalHeader>
                        <ModalCloseButton onClose={() => setShowConfirmUnmatchModal(false)} onMouseDown={() => setShowConfirmUnmatchModal(false)}/>
                        <ModalBody style={{
                            paddingTop: "0px",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.9em",
                                margin: "5px 0px 0px 0px",
                                paddingTop: "0px",
                            }}>
                                Are you sure you want to unmatch {agent ? "with " + agent[FIRST_NAME] + "": ""}? We won't notify them, but you will disappear from their Client's list. We'll move them to your Not Interested list.
                            </p>
                            
                        </ModalBody>
                        <ModalFooter style={{
                            paddingTop: "10px",
                        }}>
                            <Button onMouseDown={() => setShowConfirmUnmatchModal(false)} mr={3} size='sm' className="negative_button hover">Cancel</Button>
                            <Button className="positive_button hover" size='sm' onMouseDown={() => {
                                handleAddConnection(agent[ID], CONNECTION_REMOVED_BY_BUYER, () => {
                                    navigate("/user/buyer/dashboard/agents/" + user[ID]);
                                })
                            }}>
                                Confirm
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            );
        }

        const ContactAgentModal = () => {

            const [contactAgentTextareaValue, setContactAgentTextareaValue] = useState('');

            const handleSendConnect = () => {
                axios({
                    method: POST,
                    url: '/api/v1/user/buyer/agent/connect/' + userId,
                    timeout: DEFAULT_GET_TIMEOUT,
                    data: {
                        agent_id: agentId,
                        buyer_id: userId,
                        message: contactAgentTextareaValue,
                    }
                }).then(response => {
                    if (200 === response.status) {
                        toast({
                            title: 'Request sent',
                            description: "We've sent " + agent[FIRST_NAME] + " your request.",
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                            position: 'top'
                        });
                        setShowContactAgentModal(false);
                    }
                }).catch(error => {
                    if (error.response.status === 500) {
                        toast({
                            title: 'Internal server error',
                            description: "We've encountered an internal server error. Try again later or reach out to us",
                            status: 'error',
                            duration: 3000,
                            isClosable: true,
                            position: 'top'
                        });
                    }
                })
            }

            let handleContactAgentTextareaChange = (e) => {
                let inputValue = e.target.value;
                setContactAgentTextareaValue(inputValue);
            }

            return (
                <Modal size='sm' isOpen={showContactAgentModal} onClose={() => setShowContactAgentModal(false)} isCentered>
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader style={{
                            paddingBottom: "0px",
                        }}>Send {props.contactAgent ? props.contactAgent[FIRST_NAME] : "them"}  a Contact!</ModalHeader>
                        <ModalCloseButton onClose={() => setShowContactAgentModal(false)} onMouseDown={() => setShowContactAgentModal(false)}/>
                        <ModalBody style={{
                            paddingTop: "0px",
                        }}>
                            <label style={{
                                color: "#a1a9ba",
                                fontSize: "0.8em",
                                margin: "0px 0px 20px 0px",
                                paddingTop: "0px",
                            }}>
                                Reach out with a friendly message!
                            </label>
                            <InputGroup style={{
                                marginTop: "10px"
                            }}>
                                <Textarea
                                    placeholder="Message..."
                                    variant='outline'
                                    resize='none'
                                    size='sm'
                                    onChange={handleContactAgentTextareaChange}
                                    height={150}
                                >
                                </Textarea>
                            </InputGroup>
                        </ModalBody>
                        <ModalFooter style={{
                            paddingTop: "10px",
                        }}>
                            <Button onMouseDown={() => setShowContactAgentModal(false)} mr={3} size='sm' className="negative_button hover">Cancel</Button>
                            <Button className="positive_button hover" size='sm' onMouseDown={() => handleSendConnect()}>
                                Send Note
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            );
        }

        return (
            <>
                <ConfirmUnmatchModal/>
                <ContactAgentModal/>
                <div style={{
                    display: "flex",
                }}>
                    {
                        <Avatar
                            borderRadius='full'
                            boxSize='125px'
                            src={agent && agent[PROFILE_PICTURE_URL] && agent[PROFILE_PICTURE_URL] !== "" ? agent[PROFILE_PICTURE_URL] : null}
                            alt={agent[FIRST_NAME]}
                        />
                    }
                    
                    <div style={{
                        margin: "0px 0px 0px 25px",
                        minWidth: "200px",
                        position: "relative",
                        width: "100%",
                    }}>
                        <div style={{
                            bottom: "0",
                            margin: "0px",
                            padding: "0px",
                            // position: "absolute",
                        }}>
                            <div style={{
                                display: "flex",
                                margin: "40px 0px 0px 0px",
                            }}>
                                <p style={{
                                    fontSize: "1.7em",
                                    fontWeight: "bold",
                                    margin: "0px 0px 0px 0px",
                                    padding: "0px",
                                }}>
                                    {agent ? agent[FIRST_NAME] : ""} {agent ? agent[LAST_NAME] : ""}
                                </p>
                                {
                                    isActiveAgent ? 
                                    <div style={{
                                        backgroundColor: "#05b96b",
                                        borderRadius: "50px",
                                        display: "flex",
                                        height: "25px",
                                        margin: "7.5px 0px 0px 10px",
                                        padding: "0px 10px 0px 10px",
                                    }}>
                                        <FaCheck style={{
                                            color: "white",
                                            height: "12.5px",
                                            margin: "calc((25px - 12.5px)/2) 5px calc((25px - 12.5px)/2) 0px",
                                            width: "12.5px"
                                        }}/>
                                        <p style={{
                                            color: "white",
                                            fontSize: "0.7em",
                                            lineHeight: "25px",
                                        }}>
                                            Working with agent
                                        </p>
                                    </div> : null
                                }
                                <div style={{
                                    backgroundColor: "#e6e6e6",
                                    borderRadius: "25px",
                                    display: "inline-block",
                                    fontSize: "0.7em",
                                    fontWeight: "bold",
                                    lineHeight: "27.5px",
                                    height: "27.5px",
                                    margin: "7.5px 0px 0px 10px",
                                    padding: "0px 15px 0px 15px",
                                }}>
                                    {agent && agent[FIRM] ? agent[FIRM] : "NO FIRM FOUND"}
                                </div>
                            </div>
                            <ButtonGroup style={{
                                margin: "5px 0px 0px 0px",
                            }}>
                                {
                                    isActiveAgent && !externalView ? 
                                    <Button className="negative_button" leftIcon={<FaBan/>} onMouseDown={() => {
                                        setShowConfirmUnmatchModal(true);
                                    }}
                                        size='sm' style={{
                                        margin: "0px 10px 0px 0px",
                                    }}>
                                        Unmatch
                                    </Button> :
                                    <>
                                        <Button className="positive_button hover" leftIcon={<FaUserPlus/>} onMouseDown={() => {
                                            if (externalView) {
                                                toast({
                                                    title: "Disabled in external view",
                                                    description: "That action is not allowed while in external view",
                                                    status: 'info',
                                                    duration: 3000,
                                                    isClosable: true,
                                                    position: 'top'
                                                });
                                            } else {
                                                setShowContactAgentModal(true)
                                            }}}
                                            size='sm' style={{
                                            margin: "0px 10px 0px 0px",
                                        }}>
                                            Connect
                                        </Button>
                                        <Button className="negative_button hover" onMouseDown={() => {
                                            if (externalView) {
                                                toast({
                                                    title: "Disabled in external view",
                                                    description: "That action is not allowed while in external view",
                                                    status: 'info',
                                                    duration: 3000,
                                                    isClosable: true,
                                                    position: 'top'
                                                });
                                            } else {
                                                handleAddConnection(agent[ID], NOT_INTERESTED_BY_BUYER, () => {
                                                    navigate("/user/buyer/dashboard/agents/" + user[ID]);
                                                })
                                            }}} size='sm' 
                                            style={{
                                                margin: "0px 0px 0px 0px",
                                        }}>
                                            Hide
                                        </Button>
                                    </>
                                }
                                
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
                <Tabs onChange={(index) => setTabIndex(index)} variant='unstyled' style={{
                    margin: "25px 0px 0px 0px",
                }}>
                    <TabList style={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}>
                        <div style={{
                            display: "flex"
                        }}>
                            <Tab style={{
                                color: "#5a5a5a",
                                fontWeight: "bold",
                                margin: "0px 25px 0px 0px",
                                padding: "0px 0px 7.5px 0px",
                            }}>About</Tab>
                            {
                                isActiveAgent ?
                                <Tab style={{
                                    color: "#5a5a5a",
                                    fontWeight: "bold",
                                    margin: "0px 25px 0px 0px",
                                    padding: "0px 0px 7.5px 0px",
                                }}>Documents</Tab> : null
                            }
                            <Tab style={{
                                color: "#5A5A5A",
                                fontWeight: "bold",
                                margin: "0px 25px 0px 0px",
                                padding: "0px 0px 7.5px 0px",
                            }}>Historical Transactions</Tab>
                        </div>
                    </TabList>
                    <TabIndicator
                        mt="-5px"
                        height="5px"
                        bg="blue.500"
                        borderRadius="25px"
                    />
                    <div style={{
                        backgroundColor: "#dddddd",
                        height: "1px",
                        margin: "-3px 0px 0px 0px",
                        width: "100%"
                    }}></div>
                    <TabPanels>
                        <TabPanel style={{
                            height: "inherit",
                            padding: "20px 0px 20px 0px",
                        }}>
                            <AboutPanel/>
                        </TabPanel>
                        {
                            isActiveAgent ?
                            <TabPanel style={{
                                padding: "20px 0px 20px 0px",
                            }}>
                                <DocumentsPanel/>
                            </TabPanel> : null
                        }
                        <TabPanel style={{
                            padding: "20px 0px 20px 0px",
                        }}>
                            <TransactionsTable/>
                        </TabPanel>
                    </TabPanels>
                </Tabs> 
            </>
        );
    }

    const SidePanel = () => {

        const [defaultOffer, setDefaultOffer] = useState(null);
        const [defaultOfferLoading, setDefaultOfferLoading] = useState(true);

        useEffect(() => {
            // Active agent, we should use the current active offer.
            if (isActiveAgent) {
                setDefaultOffer(activeAgentOffer);
                setDefaultOfferLoading(false);
            } else {
                axios({
                    method: GET,
                    url: '/api/v1/user/agent/offer/default/' + agentId,
                    timeout: DEFAULT_GET_TIMEOUT,
                }).then(response => {
                    if (200 === response.status) {
                        let defaultOffer = response.data;
                        setDefaultOffer(defaultOffer);
                        setDefaultOfferLoading(false);
                    }
                }).catch(error => {
                    // TODO
                })
            }
        }, [])
        

        if (defaultOfferLoading) {
            return;
        }

        return (
            <SidePanelWrapper>
                <p style={{
                    color: "grey",
                    fontSize: "0.8em",
                    margin: externalView ? "-25px 0px 0px 0px" : "0px"
                }}>
                    {activeAgentOffer !== null ? "YOUR TERMS" : "DEFAULT OFFER"}
                </p>
                <div style={{
                    margin: "15px 0px 0px 0px",
                }}>
                    <div className="agent_profile__default_offer__box">
                        <p className="agent_profile__default_offer__label">
                            COMMISSION (%)
                        </p>
                        <p>
                            {defaultOffer && defaultOffer[COMMISSION_PERCENTAGE] ? defaultOffer[COMMISSION_PERCENTAGE] : RESIROOTS_DEFAULT_OFFER[COMMISSION_PERCENTAGE]}%
                        </p>
                    </div>
                    <div className="agent_profile__default_offer__box">
                        <p className="agent_profile__default_offer__label">
                            TERM (NO. OF MONTHS)
                        </p>
                        <p>
                            {defaultOffer && defaultOffer[NUMBER_OF_MONTHS] ? defaultOffer[NUMBER_OF_MONTHS] : RESIROOTS_DEFAULT_OFFER[NUMBER_OF_MONTHS]} mo.
                        </p>
                    </div>
                    <div className="agent_profile__default_offer__box">
                        <p className="agent_profile__default_offer__label">
                            RETAINER FEE ($)
                        </p>
                        <p>
                            ${defaultOffer && defaultOffer[RETAINER_FEE_DOLLARS] ? defaultOffer[RETAINER_FEE_DOLLARS] : RESIROOTS_DEFAULT_OFFER[RETAINER_FEE_DOLLARS]}
                        </p>
                    </div>
                    <div className="agent_profile__default_offer__box">
                        <p className="agent_profile__default_offer__label">
                            RETAINER PART OF COMMISSION
                        </p>
                        <p>
                            {defaultOffer && defaultOffer[RETAINER_PART_OF_COMMISSION] !== null ? (
                                defaultOffer[RETAINER_PART_OF_COMMISSION] === true ?
                                "Yes" : "No"
                            ) : (
                                RESIROOTS_DEFAULT_OFFER[RETAINER_PART_OF_COMMISSION] === true ? "Yes" : "No"
                            )}
                        </p>
                    </div>
                    <div className="agent_profile__default_offer__box">
                        <p className="agent_profile__default_offer__label">
                            REPRESENTATION
                        </p>
                        <p>
                            {defaultOffer && defaultOffer[REPRESENTATION] ? defaultOffer[REPRESENTATION] : RESIROOTS_DEFAULT_OFFER[REPRESENTATION]}
                        </p>
                    </div>
                    <div className="agent_profile__default_offer__box">
                        <p className="agent_profile__default_offer__label">
                            EXCLUSIVITY
                        </p>
                        <p>
                            {defaultOffer && defaultOffer[IS_EXCLUSIVE] !== null ? (
                                defaultOffer[IS_EXCLUSIVE] === true ? "Exclusive" : "Non-Exclusive" ) : (RESIROOTS_DEFAULT_OFFER[IS_EXCLUSIVE] === true ? "Exclusive" : "Non-Exclusive") }
                        </p>
                    </div>
                </div>
            </SidePanelWrapper>
        );
    }

    const TransactionsTable = () => {

        if (null === agentTransactions || undefined === agentTransactions || 0 === agentTransactions.length) {
            return (
                <Center style={{
                    margin: "50px 0px 0px 0px",
                }}>
                    <Icon as={InfoOutlineIcon}/>
                    <p style={{
                        color: "grey",
                        margin: "0px 0px 0px 5px",
                    }}>
                        No historical Transactions for this agent yet
                    </p>
                </Center>
            )
        }

        let tableRows = [];
        for (let i = 0; i < agentTransactions.length; i++) {
            let transaction = agentTransactions[i];
            tableRows.push(
                <Tr className="agent_transaction_dashboard_page_transaction_row">
                    <Td>{transaction[REPRESENTATION]}</Td>
                    <Td>{transaction[ADDRESS_LINE_ONE] + " " + transaction[ADDRESS_LINE_TWO] + " " + transaction[ADDRESS_CITY] + ", " + transaction[ADDRESS_STATE] + " " + transaction[ADDRESS_ZIP_CODE]}</Td>
                    <Td>{prettifyDate(transaction[TRANSACTION_DATE])}</Td>
                    <Td>{convertStringToPrice(transaction[PURCHASE_PRICE])}</Td>
                </Tr>
            );
        }

        return (
            <TableContainer style={{
                border: "1px solid #cccccc",
                borderRadius: "4px",
                margin: "10px 0px 0px 0px",
                padding: "0px",
            }}>
                <Table variant='simple' style={{
                    width: "100%"
                }}>
                    <Thead>
                        <Tr>
                            <Th>Represented</Th>
                            <Th>Address</Th>
                            <Th>Closing Date</Th>
                            <Th>Final Sales Price</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {tableRows}
                    </Tbody>
                    <Tfoot>
                    </Tfoot>
                </Table>
            </TableContainer>
        );
    }
    
    if (agentLoading || isActiveAgentLoading) return;
    return (
        <>
            {
                externalView ? 
                <div style={{
                    display: "flex",
                    minHeight: "500px",
                    padding: "0px 20px 0px 20px",
                }}>
                    <MainPanel>
                        <InfoView/>
                    </MainPanel>
                    <SidePanel/>   
                </div> :
                <BuyerDashboardParentPage user={user} tab={AGENTS}>
                    <MainPanel>
                        <InfoView/>
                    </MainPanel>    
                    <SidePanel/>           
                </BuyerDashboardParentPage>
            }
        </>
    )
}

export default AgentProfilePage;