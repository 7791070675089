import React from 'react';

function MainPanel(props) {

    return (
        <div style={{
            padding: "0px 0px 10px 0px",
            // width: "calc(100% - 215px)"
            width: "100%",
        }}>
            {props.children}
        </div>
    )
}

export default MainPanel;