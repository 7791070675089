import React, { useState } from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Link,
} from '@chakra-ui/react';
import { FaMessage, FaStar, FaUser, FaUsers, FaGear } from 'react-icons/fa6';

import {
    LEADS,
    CLIENTS,
    MESSAGES,
    PROFILE,
    FIRST_NAME,
    ID,
    LAST_NAME,
    SETTINGS,
    TRANSACTIONS
} from '../../../utils/constants.js';

import './NavigationBar.css';

function AgentNavigationBar(props) {

    const user = props.user;
    const userId = user[ID];
    const currTab = props.currTab;

    const getAccordionDefaultIndex = () => {
        switch (currTab) {
            case PROFILE:
            case TRANSACTIONS:
                return [0];
        }
        return [-1];
    }

    return (
        <>
            <div style={{
                margin: "0px 0px 0px 0px",
                height: "calc(100vh - 50px)",
                padding: "10px 0px 20px 0px",
                width: "210px",
                zIndex: "7",
            }}>
                <div>
                    <p style={{
                        fontSize: "1.1em",
                        fontWeight: "bold",
                        margin: "10px 10px 0px 20px",
                        width: "calc(100% - 20px)"
                    }}>
                        {user[FIRST_NAME]} {user[LAST_NAME]}
                    </p>                    
                </div>
                <label style={{
                    color: "grey",
                    fontSize: "0.7em",
                    margin: "0px 10px 10px 20px",
                }}>
                    DASHBOARD
                </label>
                <Accordion defaultIndex={getAccordionDefaultIndex()} allowMultiple={true} style={{
                    margin: "5px 0px 0px 0px",
                }}>
                    <AccordionItem style={{
                        border: "none",
                    }}>
                        <Link
                            href = {"/user/agent/dashboard/leads/" + userId}
                            className={currTab === LEADS ? "agent_navigation_bar_tab_box_selected agent_navigation_bar_tab_box" : "agent_navigation_bar_tab_box"}
                            style={{
                                borderTop: "none",
                                textDecoration: "none",
                            }}
                        >
                            <FaStar className="agent_navigation_bar_tab_icon" style={
                                currTab === LEADS ? 
                                {
                                    color: "#05b96b",
                                } :
                                {}}
                            />
                            <p className="agent_navigation_bar_tab_text" style={
                                currTab === LEADS ? 
                                {
                                    color: "#05b96b",
                                    fontWeight: "bold"
                                } :
                                {}}>
                                Leads
                            </p>
                        </Link>
                    </AccordionItem>
                    <AccordionItem style={{
                        border: "none",
                    }}>
                        <Link
                            href = {"/user/agent/dashboard/clients/" + userId}
                            className={currTab === CLIENTS ? "agent_navigation_bar_tab_box_selected agent_navigation_bar_tab_box" : "agent_navigation_bar_tab_box"}
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <FaUsers className="agent_navigation_bar_tab_icon" style={
                                currTab === CLIENTS ? 
                                {
                                    color: "#05b96b",
                                } :
                                {}}
                            />
                            <p className="agent_navigation_bar_tab_text" style={
                                currTab === CLIENTS ? 
                                {
                                    color: "#05b96b",
                                    fontWeight: "bold",
                                } :
                                {}}>
                                Clients
                            </p>
                        </Link>
                    </AccordionItem>
                    <AccordionItem style={{
                        backgroundColor: "transparent",
                        border: "none",
                    }}>
                        <Link
                            href={"/user/agent/dashboard/messages/" + userId}
                            className={currTab === MESSAGES ? "agent_navigation_bar_tab_box_selected agent_navigation_bar_tab_box" : "agent_navigation_bar_tab_box"}
                            style={{
                                textDecoration: "none"
                            }}
                        >
                            <FaMessage className="agent_navigation_bar_tab_icon" style={
                                currTab === MESSAGES ? 
                                {
                                    color: "#05b96b",
                                } :
                                {}}
                            />
                            <Box as="span" flex='1' textAlign='left' className="agent_navigation_bar_tab_text" style={
                                currTab === MESSAGES ? 
                                {
                                    color: "#05b96b",
                                    fontWeight: "bold"
                                } :
                                {}}>
                                Messages
                            </Box>
                        </Link>
                    </AccordionItem>
                    <AccordionItem style={{
                        border: "none",
                        padding: "0px"
                    }}>
                        <AccordionButton
                            className={"agent_navigation_bar_tab_box"}
                        >
                            <FaUser className="agent_navigation_bar_tab_icon"
                            />
                            <Box as="span" flex='1' textAlign='left' className="agent_navigation_bar_tab_text">
                                Personal
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <Link 
                            href={"/user/agent/dashboard/profile/" + userId}
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <AccordionPanel
                                className={currTab === PROFILE ? "agent_navigation_bar_subtab_box_selected agent_navigation_bar_subtab_box" : "agent_navigation_bar_subtab_box"}>
                                <p className={currTab === PROFILE ? "agent_navigation_bar_tab_subtext agent_navigation_bar_tab_subtext_selected" : "agent_navigation_bar_tab_subtext"}>
                                    Profile
                                </p>
                            </AccordionPanel>
                        </Link>
                        <Link 
                            href={"/user/agent/dashboard/transactions/" + userId}
                            style={{
                                textDecoration: "none"
                            }}
                        >
                            <AccordionPanel
                                className={currTab === TRANSACTIONS ? "agent_navigation_bar_subtab_box_selected agent_navigation_bar_subtab_box" : "agent_navigation_bar_subtab_box"}>
                                <p className={currTab === TRANSACTIONS ? "agent_navigation_bar_tab_subtext agent_navigation_bar_tab_subtext_selected" : "agent_navigation_bar_tab_subtext"}>
                                    Transactions
                                </p>
                            </AccordionPanel>
                        </Link>
                    </AccordionItem>
                    <AccordionItem style={{
                        backgroundColor: "transparent",
                        border: "none",
                    }}>
                        <Link
                            href={"/user/settings/" + userId + "?user_type=Agent"}
                            className={currTab === SETTINGS ? "agent_navigation_bar_tab_box_selected agent_navigation_bar_tab_box" : "agent_navigation_bar_tab_box"}
                            style={{
                                textDecoration: "none"
                            }}
                        >
                            <FaGear className="agent_navigation_bar_tab_icon" style={
                                currTab === SETTINGS ? 
                                {
                                    color: "#05b96b",
                                } :
                                {}}
                            />
                            <Box as="span" flex='1' textAlign='left' className="agent_navigation_bar_tab_text" style={
                                currTab === SETTINGS ? 
                                {
                                    color: "#05b96b",
                                    fontWeight: "bold"
                                } :
                                {}}>
                                Settings
                            </Box>
                        </Link>
                    </AccordionItem>
                </Accordion>
            </div>
        </>
    );

}

export default AgentNavigationBar;