import React from 'react';
import { 
    Avatar,
    Card,
    CardBody,
    ButtonGroup,
    Button
} from '@chakra-ui/react'

import {
    CREDIT_SCORE,
    FIRST_NAME,
    FIRST_TIME_BUYER,
    LAST_NAME,
    MIN_PRICE,
    MAX_PRICE,
    PRE_APPROVED,
    PROFILE_PICTURE_URL,
    ASSET_INTERESTS
} from '../../../utils/constants.js';

import { dollarfyPrice } from '../../../utils/utility.js';
import { FaFire } from "react-icons/fa6";

import './UserCard.css';
import '../../Generic.css';
import { prettifyPropertyType } from '../../../utils/utility.js';

export const getPriceRange = (lead) => {

    if (null === lead || undefined === lead) {
        return "Not Specified";
    }

    let minPrice = dollarfyPrice(lead[MIN_PRICE]);
    let maxPrice = dollarfyPrice(lead[MAX_PRICE]);

    if (minPrice !== null && maxPrice !== null) {
        if (minPrice === maxPrice) {
            if (minPrice === 0) {
                return "Not Specified";
            }
            return "~$" + minPrice;
        }
        return "$"+minPrice+ " - " + "$" + maxPrice;
    } else if (minPrice !== null ) {
        return "$" + minPrice;
    } else if (maxPrice !== null ) {
        return "$" + maxPrice;
    }
    return "Not Specified";
}

function LeadUserCard(props) {

    const lead = props.lead;
    const contactButtonCallback = props.contactButtonCallback;
    const notInterestedButtonCallback = props.notInterestedButtonCallback;

    const prettifyAssetInterests = () => {
        
        let assetInterests = lead[ASSET_INTERESTS];

        // They have all assets.
        if (assetInterests.length === 8) {
            return "Any";
        }
        for (let i = 0; i < assetInterests.length; i++) {
            assetInterests[i] = prettifyPropertyType(assetInterests[i]);
        }
        let prettifiedStr = assetInterests.join(", ");

        return prettifiedStr;
    }

    return (
        <Card style={{
            backgroundColor: "transparent",
            borderRadius: "0px",
            boxShadow: "none",
            margin: "0px 0px 0px 0px",
            width: "100%",
        }}>
            <CardBody style={{
                padding: "25px 0px 25px 0px",
            }}>
                <div style={{
                    justifyContent: "space-between",
                    display: "flex",
                    margin: "0px 0px 0px 0px",
                    width: "100%",
                }}>
                    <Avatar
                        borderRadius='full'
                        size='lg'
                        src={lead[PROFILE_PICTURE_URL]}
                        alt='Green double couch with wooden legs'
                        style={{
                            border: "2px solid white",
                            margin: "0px 15x 0px 0px",
                            padding: "0",
                        }}
                    />
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "0px 0px 0px 15px",
                        width: "100%",
                    }}>
                        <div>
                            <p style={{
                                fontSize: "1.2em",
                                fontWeight: "bold",
                            }}>
                                {lead[FIRST_NAME]} {lead[LAST_NAME]}
                            </p>
                            <div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}>
                                    <div style={{
                                        margin: "0px 40px 0px 0px",
                                        minWidth: "75px",
                                    }}>
                                        <p style={{
                                            color: "grey",
                                            fontSize: "0.6em"
                                        }}>
                                            PRE-APPROVED
                                        </p>
                                        <p 
                                            className="lead_user_card_information_text"> 
                                            {
                                                lead[PRE_APPROVED] !== null && lead[PRE_APPROVED] !== undefined ? (
                                                    lead[PRE_APPROVED] === true ? "Yes" : "No"
                                                ) : "Not Specified"
                                            }
                                        </p>
                                    </div>
                                    <div style={{
                                        margin: "0px 40px 0px 0px",
                                        minWidth: "90px",
                                    }}>
                                        <p style={{
                                            color: "grey",
                                            fontSize: "0.6em"
                                        }}>
                                            FIRST TIME BUYER
                                        </p>
                                        <p 
                                            className="lead_user_card_information_text">
                                            {
                                                lead[FIRST_TIME_BUYER] !== null && lead[FIRST_TIME_BUYER] !== undefined ? (
                                                    lead[FIRST_TIME_BUYER] === true ? "Yes" : "No"
                                                ) : "Not Specified"
                                            }
                                        </p>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        margin: "0px 0px 0px 0px"
                                    }}>
                                        <div style={{
                                            margin: "0px 40px 0px 0px",
                                            minWidth: "90px"
                                        }}>
                                            <p style={{
                                                color: "grey",
                                                fontSize: "0.6em"
                                            }}>
                                                PRICE RANGE
                                            </p>
                                            <p 
                                                className="lead_user_card_information_text">
                                                {lead ? getPriceRange(lead) : "-"}
                                            </p>
                                        </div>
                                        <div style={{
                                            margin: "0px 40px 0px 0px",
                                            minWidth: "70px"
                                        }}>
                                            <p style={{
                                                color: "grey",
                                                fontSize: "0.6em"
                                            }}>
                                                CREDIT SCORE
                                            </p>
                                            <p 
                                                className="lead_user_card_information_text">
                                                {lead[CREDIT_SCORE] !== null ? lead[CREDIT_SCORE] : "N/A"}
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}>
                                        <div style={{
                                            width: "100%",
                                        }}>
                                            <p style={{
                                                color: "grey",
                                                fontSize: "0.6em"
                                            }}>
                                                LOOKING FOR
                                            </p>
                                            <p 
                                                className="lead_user_card_information_text"> 
                                                {lead[ASSET_INTERESTS] !== null ? prettifyAssetInterests() : "Any"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}>
                            <div>
                                <ButtonGroup spacing='0' style={{
                                    margin: "10px 0px 0px 0px",
                                }}>
                                    <Button
                                        leftIcon={<FaFire/>}
                                        onMouseDown={() => contactButtonCallback()} 
                                        variant='solid'
                                        size='sm'
                                        className="positive_button hover"
                                        style={{
                                            width: "100%",
                                        }}
                                    >
                                        Make an Offer
                                    </Button>
                                </ButtonGroup>
                                <br></br>
                                <div 
                                    onMouseDown={() => notInterestedButtonCallback()}
                                    style={{
                                        cursor: "pointer",
                                        fontSize: "0.7em",
                                        margin: "5px 0px 0px 0px",
                                        textAlign: "center",
                                    }}
                                >
                                    Not Interested
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default LeadUserCard;