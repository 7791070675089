import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

import { StreamChat } from 'stream-chat';
import {
    Chat,
    Channel,
    ChannelList,
    MessageInput,
    MessageList,
    Window,
    useMessageInputContext,
    useTranslationContext,
    Attachment,
    messageHasReactions,
    MessageOptions,
    MessageRepliesCountButton,
    MessageStatus,
    MessageText,
    MessageTimestamp,
    ReactionSelector,
    SimpleReactionsList,
    useMessageContext,
} from 'stream-chat-react';
import { IoSend } from "react-icons/io5";
import { FaMessage } from "react-icons/fa6";

import { Avatar, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';

import 'stream-chat-react/dist/css/v2/index.css';

import {
    AGENT,
    BUYER,
    FIRST_NAME,
    LAST_NAME,
    ID,
    POST,
    DEFAULT_POST_TIMEOUT,
    PROFILE_PICTURE_URL
} from '../../utils/constants.js';

import './Messages.css';
import '../Generic.css';

import { Search2Icon } from '@chakra-ui/icons';
import NoObjectsDisplay from './NoObjectsDisplay.js';

const chatClient = StreamChat.getInstance("ufb64fqzh9zr");

function Messages(props) {

    const user = props.user;
    const userType = props.userType;
    const chatToken = props.chatToken;

    const [pageLoading, setPageLoading] = useState(true);

    const [userToInfoMap, setUserToInfoMap] = useState(null);
    const [currChannel, setCurrChannel] = useState(null);
    const [channels, setChannels] = useState(null);

    let userId = user[ID];

    const sort = { last_message_at: -1 };
    // const filters = {
    //     type: 'messaging',
    //     members: { $in: [userType.toLowerCase() + "_" + userId] },
    // };

    const filters = {};
    // fetch all of our user information.

    useEffect(() => {

        async function fetchChannels() {
            if (chatClient.user === undefined) {
                await chatClient.connectUser({
                    id: userType.toLowerCase()+"_"+userId,
                    name: user[FIRST_NAME],
                    image: user[PROFILE_PICTURE_URL],
                }, chatToken);

                const chatChannels = await chatClient.queryChannels(filters, sort, {
                    watch: true,
                    state: true,
                })
                let chatUserIds = getChatUserIds(chatChannels, userType);
                setChannels(chatChannels);
                axios({
                    method: POST,
                    url: '/api/v1/user/chat/user_information/' + user[ID],
                    timeout: DEFAULT_POST_TIMEOUT,
                    data: { ids: chatUserIds }
                }).then(response => {
                    if (200 === response.status) {
                        // Map user id to their information.
                        let userInfosMap = new Map();
                        let userInfos = response.data;
                        for (let i = 0; i < userInfos.length; i++) {
                            userInfosMap.set(userInfos[i][ID], userInfos[i]);
                        }
                        setUserToInfoMap(userInfosMap);
                        // find current channel. Make sure it has information associated with it.
                        // This handles the case where a user is deleted but GetStream still has
                        // a reference to it.
                        for (let i = 0; i < chatChannels.length; i++) {
                            let chatChannel = chatChannels[i];
                            let channelData = chatChannel["data"];
                            let opposingSenderId = userType === AGENT ? channelData["buyer_id"] : channelData["agent_id"];
                            // User still exists.
                            if (userInfosMap.has(opposingSenderId)) {
                                setCurrChannel(chatChannel);
                                break;
                            }
                        }
                        setPageLoading(false);
                    }
                }).catch(error => {

                })

                
            }
        }
        fetchChannels();
    }, [])

    // fetch the ids of the opposite users.
    const getChatUserIds = (channels, userType) => {

        let ids = [];
        for (let i = 0; i < channels.length; i++) {
            ids.push(userType === BUYER ? channels[i].data.agent_id : channels[i].data.buyer_id);
        }
        return ids;
    }

    const SendButton = () => {

        const { handleSubmit } = useMessageInputContext();

        return (
            <button
                className='str-chat__send-button'
                onClick={() => {
                    let chatInput = document.getElementById("chat_input");
                    chatInput.value = "";
                    handleSubmit();
                }}
                type='button'
                style={{
                    height: "40px",
                    width: "40px",
                    bottom: "0",
                    position: "absolute",
                    right: "0",
                }}
            >
                <IoSend style={{
                    color: "#05b96b",
                    height: "25px",
                    width: "25px"
                }}/>
            </button>
        );
    }
    
    const CustomMessageInput = () => {
        const { t } = useTranslationContext();

        const {
            closeEmojiPicker,
            emojiPickerIsOpen,
            handleEmojiKeyDown,
            handleSubmit,
            handleChange,
            openEmojiPicker,
        } = useMessageInputContext();

        return (
            <div className='message-input' style={{
                bottom: "0px",
                display: "flex",
                justifyContent: "space-between",
                position: "absolute",
                width: "100%",
            }}>
                <textarea 
                    className='message-input__input' 
                    id="chat_input" 
                    onChange={handleChange} 
                    placeholder={"Type a message..."} 
                    role="textbox"
                    contenteditable
                    style={{
                        backgroundColor: "#f0f0f0",
                        borderRadius: "25px",
                        padding: "10px 15px 10px 15px",
                        width: "calc(100% - 50px)",
                    }}/>
                <SendButton sendMessage={handleSubmit} />
            </div>
        );
    }

    const CustomChannelPreview = (props) => {
        const { channel, setChannel } = props;  
        const { messages } = channel.state;
        let lastMessage = messages[messages.length - 1];

        // lastSender displays in our preview who the last message sender was.
        let lastSender = lastMessage.user;
        
        // Need to trim the prefix from user.
        let lastSenderId = lastSender.id;
        lastSenderId = lastSenderId.replace("agent_", "").replace("buyer_", "");

        // Prepend either "You:" or "{Name}:" to the front preview.
        let lastSenderInfo = userToInfoMap.get(lastSenderId);
        if (!lastSenderInfo) {
            return;
        }
        let lastSenderDisplay = lastSenderId === user[ID] ? "You:" : (
            lastSenderInfo ? lastSenderInfo[FIRST_NAME] : "" ) + ":";

        // Truncate our message if we need to.
        const MAX_PREVIEW_LENGTH_CHARS = 24;
        let messagePreview = lastSenderDisplay + " " + lastMessage.text;

        let opposingUserId = userType === BUYER ? channel["data"]["agent_id"] : channel["data"]["buyer_id"];
        let opposingUserInfo = userToInfoMap.get(opposingUserId);
      
        return (
          <div
            className="agent_message__channel_preview"
            onClick={() => {
                setCurrChannel(channel);
            }}
            style={{ 
                backgroundColor: channel[ID] === currChannel[ID] ? "#f0f0f0" : "transparent",
                cursor: "pointer",
                margin: "0px 0px 0px 0px",
                display: 'flex', 
                width: "100%",
            }}
          >
            {
                opposingUserInfo && opposingUserInfo[PROFILE_PICTURE_URL] ?
                <Avatar 
                    name={opposingUserInfo ? opposingUserInfo[FIRST_NAME] + " " + opposingUserInfo[LAST_NAME] : ""}
                    size='sm'
                    src={opposingUserInfo[PROFILE_PICTURE_URL]} 
                /> :
                <Avatar size='sm' src={''}></Avatar>
            }
            <div style={{ 
                margin: "0px 0px 0px 10px",
                width: "calc(100% - 40px)",
             }}>
                <p style={{
                    fontSize: "1.0em", 
                    padding: "0px !important",
                }}>
                    {opposingUserInfo ? opposingUserInfo[FIRST_NAME] + " " + opposingUserInfo[LAST_NAME] : 'User'}
                </p>
                <p style={{ 
                    color: "grey",
                    fontSize: '0.85em',
                    margin: "-2.5px 0px 0px 0px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "100%",
                }}>{messagePreview}</p>
            </div>
          </div>
        );
      };
    
    const CustomChannelHeader = (props) => {

        let opposingUserId;
        if (userType === BUYER) {
            opposingUserId = currChannel["data"]["agent_id"];
        } else {
            opposingUserId = currChannel["data"]["buyer_id"];
        }

        let opposingUserInfo = userToInfoMap.get(opposingUserId);

        if (opposingUserInfo === null || opposingUserInfo === undefined) {
            return;
        }

        return (
            <div style={{
                borderBottom: "1px solid #dddddd",
                display: "flex",
                paddingBottom: "10px",
                width: "100%",
            }}>
                {
                    opposingUserInfo && opposingUserInfo[PROFILE_PICTURE_URL] ?
                    <Avatar 
                    name={opposingUserInfo[FIRST_NAME] + " " + opposingUserInfo[LAST_NAME]}
                    src={
                        opposingUserInfo[PROFILE_PICTURE_URL]
                    } size='md' /> :
                    <Avatar size='md' src={opposingUserInfo[PROFILE_PICTURE_URL]}></Avatar>
                }
                <p style={{
                    fontFamily: "'Gabarito', sans-serif !important",
                    fontSize: "1.2em",
                    fontWeight: "bold",
                    margin: "0px 0px 0px 10px",
                }}>
                    {opposingUserInfo[FIRST_NAME] + " " + opposingUserInfo[LAST_NAME]}
                </p>
            </div>
        )
    }

    const CustomMessage = (props) => {

        const {
            isReactionEnabled,
            message,
            reactionSelectorRef,
            showDetailedReactions,
        } = useMessageContext();

        const messageWrapperRef = useRef(null);

        const canReact = isReactionEnabled;
        const hasReactions = messageHasReactions(message);
        const hasAttachments = message.attachments && message.attachments.length > 0;

        return (
            <div className='message-wrapper'>
                <Avatar image={message.user?.image} size='sm' />
                <div className='message-wrapper-content'>
                    <MessageOptions messageWrapperRef={messageWrapperRef} />
                    <div className='message-header'>
                    <div className='message-header-name'>{message.user?.name}</div>
                    <div className='message-header-timestamp'>
                        <MessageTimestamp />
                    </div>
                    </div>
                    {showDetailedReactions && canReact && <ReactionSelector ref={reactionSelectorRef} />}
                    <MessageText />
                    <MessageStatus />
                    {hasAttachments && <Attachment attachments={message.attachments} />}
                    {hasReactions && <SimpleReactionsList />}
                    <MessageRepliesCountButton reply_count={message.reply_count} />
                </div>
            </div>
        );
    }

    const CustomAvatar = () => {
        return (
            <Avatar size='sm'>
            </Avatar>
        )
    }

    const CustomMessageActions = () => {
        return (
            <></>
        )
    }

    const NoChannels = () => {
        return (
            <div style={{
                display: "flex",
                width: "100%",
            }}>
                <div style={{
                    width: "calc(100% - 315px)",
                }}>
                    <NoObjectsDisplay 
                        icon={FaMessage} 
                        titleText={"No Messages"} 
                        subtitleText={"No messages yet. Once you send a connection request, it'll appear here"} 
                        buttonText={userType === BUYER ? "Find Agents" : "Find Leads"}
                        buttonCallback={() => {
                            if (userType === BUYER) {
                                window.location="/user/buyer/dashboard/agents/" + userId;
                            } else if (userType === AGENT) {
                                window.location="/user/agent/dashboard/leads/" + userId;
                            }
                        }}
                    />
                </div>
                <div style={{
                    height: "calc(100vh - 50px)",
                    right: "0px",
                    top: "50px",
                    position: "fixed",
                    padding: "20px 20px 10px 15px",
                    width: "315px",
                }}>
                    <InputGroup>
                        <InputLeftElement size='md' pointerEvents='none'>
                            <Search2Icon color='#cccccc' style={{
                                margin: "-7.5px 0px 0px 0px"
                            }}/>
                        </InputLeftElement>
                        <Input size='sm' placeholder={"Search by name"}  style={{
                            border: "1px solid #cccccc",
                            borderRadius: "25px",
                            margin: "0px 0px 15px 0px",
                        }}></Input>
                    </InputGroup>
                </div>
            </div>
        )
    }

    return (
        pageLoading ?
        <></> :
        (
            channels && channels.length > 0 ?
            <Chat client={chatClient} style={{
            }}>
                <div style={{
                    display: "flex",
                    width: "calc(100% - 315px + 35px - 25px)"
                }}>
                    <Channel channel={currChannel} >
                        <Window style={{
                        }}>
                            <CustomChannelHeader/>
                            <MessageList 
                                customMessageActions={CustomMessageActions}
                                disableDateSeparator={true}
                            />
                            <MessageInput Input={CustomMessageInput}/>
                        </Window>
                    </Channel>
                </div>
                <div style={{
                    height: "calc(100vh - 50px)",
                    right: "0px",
                    top: "50px",
                    position: "fixed",
                    padding: "20px 20px 50px 15px",
                    width: "315px",
                }}>
                    <InputGroup>
                        <InputLeftElement size='md' pointerEvents='none'>
                            <Search2Icon color='#cccccc' style={{
                                margin: "-7.5px 0px 0px 0px"
                            }}/>
                        </InputLeftElement>
                        <Input size='sm' placeholder={"Search by name"}  style={{
                            border: "1px solid #cccccc",
                            borderRadius: "25px",
                            margin: "0px 0px 15px 0px",
                        }}></Input>
                    </InputGroup>
                    <ChannelList sort={{
                        last_message_at: -1
                    }} filters={filters} Preview={CustomChannelPreview}/>
                </div>
            </Chat> :
            <NoChannels/>
        )
    );
}

export default Messages;