import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Button
} from '@chakra-ui/react'
import { FaArrowRightLong } from "react-icons/fa6";

import Footer from './Footer';
import NavigationBar from './NavigationBar';

import './TermsAndAgreementsPage.css';

function TermsAndAgreementsPage(props) {

    return (
        <div className="terms_and_agreements_page">
            <NavigationBar/>
            <div className="terms_and_agreements_page__parent_box">
                <p className="terms_and_agreements_page__title">
                    Terms & Agreements
                </p>
                <p className="terms_and_agreements_page__subtitle">
                    Please read the following carefully as it governs the terms of your usage of our service, Rezied. This page is Rezied's general Terms & Agreements. For Buyside Commission Terms & Conditions, please click on the button down below.
                </p>
                <p style={{
                    margin: "15px 0px 0px 0px",
                }}>
                    Thank you for your interest in Rezied Inc. (“we”,”us”,”our”,“Rezied”) and welcome to our website. By accessing the
                    Rezied websites or any features, services or other Rezied platforms (collectively, the “Services”) you agree to be bound
                    by these terms and conditions (“Terms”) and our Privacy Policy. If you do not agree to these Terms, please do not use
                    the Services.
                    <br></br>
                    We reserve the right to modify these Terms at any time for any reason, in our sole discretion, by posting a new version
                    on the Rezied website. Your continued use of the Services following the posting of such an amendment constitutes your
                    agreement to be bound by these new Terms. It is your responsibility to review the Terms periodically for changes.
                </p>
                <Accordion allowToggle style={{
                    margin: "15px 0px 0px 0px",
                }}>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                1. Rezied
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            Rezied is a real estate data and information ecosystem. While Rezied may present data and information on a variety of
                            real estate related topics, people and locations, this data and information is to be considered for informational purposes
                            only. Rezied is not a real estate broker, attorney, lender or financial advisor and does not provide financial, legal or real
                            estate advice nor are the Services intended to. Because of this, Rezied does not guarantee any express or implied
                            agreement made between you and other users of the Bidding Services (defined below) nor does Rezied guarantee any
                            particular outcome resulting from your access or use of the Bidding Services or the Services more broadly.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                2. Reference to Privacy Policy
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            We take your privacy seriously. Our privacy policy (“Privacy Policy”) explains how we collect, make use of, store and
                            share your personal content and information. Please review the Privacy Policy for more information and a better
                            understanding of the specific terms.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                3. Eligibility and Registration
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            3a. All Users
                            You must be of sufficient legal age with the ability to independently enter into binding contracts in order to access
                            or use the Services. Certain features or content of the Services may require you to create an account for yourself
                            with information on yourself. You are responsible for maintaining confidentiality regarding any accounts,
                            usernames or passwords used in connection with the Services. You are also responsible for any and all actions taken
                            under your account with respect to the Services. You agree that any information you provide regarding accounts
                            created or used in connection with the Services is accurate and will be kept up-to-date by you. Unless given written
                            permission by Rezied to use the Services on behalf of another person, you may only use the Services on your own
                            behalf.
                            <br></br>
                            <br></br>
                            3b. Real Estate Brokers and Agents
                            If you are accessing the Services as a real estate agent or broker then in addition to section 3(A) above, you agree to
                            follow all applicable real estate laws and maintain your active real estate license while accessing or using the
                            Services in this capacity. As Rezied is not a real estate brokerage nor does Rezied hold a real estate license, you are
                            solely responsible for all real estate services and obligations to the customers and clients you represent and will
                            indemnify, defend and hold harmless Rezied and its directors, officers, employees and contractors from any and all
                            claims and demands arising from any real estate services or obligations due from you to your customers and clients.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                4. Subscription and Other Fees
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            Your access or use of the Services may require you to pay fees which are in U.S. dollars and non-refundable. You agree
                            to pay any applicable fees associated with your access or use of the Services until your access or use of the Services is
                            terminated or suspended by Rezied or proactively by you. Rezied reserves the right to change, add or remove fees for
                            any or all parts of the Services and will provide a notice to you of any such changes, at least 1 billing cycle before the
                            new fees take effect, via (i) the contact information provided by you in your registered account with us or (ii) as a
                            notification in the Services. By providing a payment method, you authorize Rezied to charge all fees described in these
                            <br></br>
                            <br></br>
                            Terms for the Services you seek to access or use. The charge will be processed through Rezied’ third-party payment
                            processor.
                            <br></br>
                            <br></br>
                            Certain fees regarding your access or use of the Services may be subscription fees which allow for automatic renewal
                            and recurring charges. You grant Rezied authority to charge all accrued fees on the payment due date and on a go-
                            forward basis on the monthly anniversary of the date you signed up for the applicable subscription fee related Service
                            (“Renewal Date”). Unless your access or use of the subscription fee related Services is suspended or terminated by
                            Rezied, you must proactively suspend or terminate your access or use of these subscription fee related Services at
                            least one day prior to the Renewal Date in order to cancel the automatic renewal and recurring charges. The
                            subscription fees will be charged using the payment method specified by you at the time you initially signed up for the
                            subscription fee related Service or the latest payment method if updated by you. You may terminate or suspend your
                            access or use of a subscription fee related Service through your account settings.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                            5. Marketplace Bidding
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            Your use of the Services may allow you to make, receive, select, reject or otherwise manage bids for the business of
                            other users, and, if mutually desired, enter into, an agreement for the provision of real estate brokerage services
                            (“Bidding Services”). The user corresponding to a bid selected as the winning bid (“Winning Bidder”), the user that
                            selected the winning bid (“Bid Selector”) and all other users accessing or using the Bidding Services agree to deal with
                            each other in good faith and with respect and agree to have the authority to commit to and fulfill the terms of any
                            agreement made when making, receiving, selecting, rejecting or otherwise managing bids. It is understood and agreed
                            that Rezied will not be a party to any such agreement between the Winning Bidder and Bid Selector, or any third-party
                            beneficiaries. You will be responsible for conducting your own research and due diligence in determining whether to
                            offer, accept, or enter into, any agreement for the provision of such Bidding Services. You acknowledge and agree that
                            Rezied acts solely as a passive conduit to facilitate the introduction and communication between you and other users of
                            the Bidding Services, and assumes no liability for you and the other users’ communications, including, without limitation,
                            the accuracy of any information conveyed through the Bidding Services, and any promises or agreements that develop
                            therefrom. Rezied does not ensure or guarantee that the you or other users will enter into, or abide by, any agreement.
                            <br></br>
                            <br></br>
                            Since Rezied is not involved in the actual transactions between the Winning Bidder and the Bid Selector, any user who
                            accesses or uses the Marketplace agrees that, in the event of a dispute between the Winning Bidder and the Bid Selector
                            and/or between any seller, buyer, real estate agent or real estate broker (hereinafter a “Dispute”), the users shall, and
                            do hereby, release Rezied and its directors, officers, employees, shareholders, affiliates, agents and providers from all
                            causes of action, suits, claims, demands, judgments, liabilities, losses, damages (actual and consequential), awards,
                            penalties, fines, costs and expenses (including, but not limited to, reasonable attorney&#39;s fees), of every kind and nature,
                            known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of, or in any way connected
                            with, such Dispute. If a Bid Selector or Winning Bidder is deemed a California resident, such Bid Selector or Winning
                            Bidder also agrees to waive California Civil Code § 1542, as amended from time to time, and any similar statutes, to the
                            extent that such statute(s) prohibits a general release from extending to claims that a creditor does not know or suspect
                            to exist in his/her/its favor at the time of executing the release, which if known by the creditor, would materially affect
                            his/her/its settlement with the debtor.
                            <br></br>
                            <br></br>
                            Your use of the Bidding Services may require Rezied to share your name, phone number and/or e-mail address with the
                            Winning Bidder or the Bid Selector to make an introduction and facilitate communication between you and the Winning
                            Bidder or the Bid Selector. By participating in the Bidding Services, you agree to authorize Rezied to share this
                            information for this purpose and to be contacted by Rezied or the Winning Bidder or Bid Selector, as the case may be for
                            you. Any name, phone number and/or e-mail address you receive in connection with the Bidding Services must be kept
                            confidential, and you are prohibited from disclosing or using this information other than to carry out the Bidding
                            Services as they pertain to the specific individual corresponding to the name, phone number and/or e-mail address you
                            received.
                            <br></br>
                            <br></br>
                            <b>5a. Making Bids</b>
                            <br></br>
                            <b>Whenever you become the Winning Bidder, you agree to provide Rezied with electronic copies of (i) any agency
                            agreement document used to establish a working relationship (“Agency Agreement”) between you and the Bid
                            Selector and (ii) any contract, contract addendums and amendments made to purchase or sell real estate
                            (“Contract”) executed by the Bid Selector you represent and the other party to the transaction.</b> Failure to submit
                            these documents may result in the suspension or termination of your access or use of the Bidding Services. If you do
                            not agree to these terms, please cease your access and use of any Bidding Services. <b>These documents may be
                            submitted via you or the Bid Selector, at your collective discretion, through the Services.</b>
                            <br></br>
                            <br></br>
                            <b>B. Managing Bids</b>
                            <br></br>
                            <b>Whenever you become the Bid Selector, you agree to provide Rezied with electronic copies of (i) any Agency
                            Agreement used between you and the Winning Bidder and (ii) any Contract executed by you, or your assignee,
                            and the other party to the transaction.</b> Failure to submit these documents may result in the suspension or
                            termination of your access or use of the Bidding Services. If you do not agree to these terms, please cease your
                            access and use of any Bidding Services. <b>These documents may be submitted via you or the Winning Bidder at your
                            collective discretion, through the Services.</b>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                6. Commission Rebates
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            Your use of the Bidding Services may entitle you to a lower commission charge than what is provided for in a listing
                            agreement which would result in a commission rebate to you. As commission charges vary by listing agreement, the
                            commission rebate and commission rebate amount are not guaranteed. No commission rebate will be available where
                            prohibited by law as in Alabama, Alaska, Iowa, Kansas, Louisiana, Mississippi, Missouri, Oklahoma, Oregon and
                            Tennessee. In certain cases, as may be the case with short sales or receiving lender approval, commission rebates may
                            be prohibited or the commission rebate amount may be restricted. <b>Rezied strongly advises Bid Selectors to consult
                            with their lenders as early as possible regarding any actual or potential commission rebate amount.</b> The Bid Selector
                            and Winning Bidder must work out whether any commission rebate amount will be received by the Bid Selector on or
                            after the closing date.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                7. Notifications and Communications
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            By your use of the Services or contacting Rezied via e-mail, you consent to receive communications from us
                            electronically via e-mail, text messages and through notifications on the Services. If you consent to receive
                            communications from the Rezied website directly to your mobile device, you agree that you are solely responsible for
                            keeping the phone number provided to Rezied for this purpose accurate and up-to-date. Rezied will not be responsible
                            for the cost of such communications nor will Rezied be liable for the cost, communications or information sent to a
                            device corresponding to an outdated phone number associated with your account. You agree that all agreements,
                            notices, disclosures or other communications Rezied provides to you electronically satisfy any legal requirement that
                            such communications be in writing.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                8. User Generated Content
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            User generated content is an important component of the Services. Some uses of the Services allow users to upload,
                            transfer or share photos, images, data, reviews, information, text, contracts and other content (“User Content”). It is
                            your responsibility to create backup copies of any User Content you share with us.
                            <br></br>
                            <br></br>
                            <b>Superseding anything to the contrary in these Terms: unless required by law (i) Rezied will not knowingly share with
                            outside parties any personal names, phone numbers, e-mail addresses, fax numbers or mailing addresses
                            (collectively, “Contact Details”) obtained through the upload of any Agency Agreements or Contracts, and (ii) any
                            uploaded Agency Agreement or Contract shall NOT be made public record but kept privately within Rezied, although
                            information may be extracted from either document and shared as long as Rezied, in its sole discretion, determines
                            that the information does not identify a consumer, as in aggregated information or data without personal identifiers.</b>
                            Any Contact Details shared on the Services are obtained through means other than any uploaded Agency Agreement or
                            Contract and therefore not subject to the immediately preceding statement. Please review our Privacy Policy for more
                            information.
                            <br></br>
                            <br></br>
                            With the exception of Contact Details, non-aggregated information or data containing personal identifiers that were
                            obtained through the upload of any Agency Agreements and Contracts, by uploading User Content to the Services you
                            grant Rezied a transferrable, irrevocable, perpetual, non-exclusive, royalty-free, sublicensable, worldwide license to use,
                            reproduce, modify, publicly display, distribute, edit and prepare derivative works of any User Content we receive from
                            you for any purpose. You are responsible for any and all User Content created and/or uploaded through your account
                            with respect to the Services. You represent and warrant that you have the necessary licenses, rights, consents, grants or
                            other authority to upload, transfer or share the User Content and grant the licenses owed by you to Rezied with respect
                            to this User Content under these Terms. Rezied reserves the right, but has no obligation, to review, edit or remove your
                            User Content at any time.
                            <br></br>
                            <br></br>
                            Rezied is not responsible and assumes no liability for any User Content made or uploaded by you or any third party. As
                            such, you waive any right to legal or equitable action against Rezied regarding User Content. Rezied may suspend or
                            terminate any account or review, edit or remove any User Content that Rezied determines, in its sole judgment, is a
                            violation to these Terms, is illegal, defamatory, obscene, threatening, otherwise objectionable, or infringes on
                            intellectual property rights.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                9. 3 rd Party Disclaimer
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            Links, references or materials to products, services or websites belonging to or operated by third-parties not under
                            Rezied’ control (collectively, “Third-Party Content”) may be associated with certain access or use of the Services. As
                            such, Rezied is not responsible for any timeliness, accuracy or completeness of any Third-Party Content. Rezied is also
                            not responsible for any third-party technical, or otherwise, difficulties that may impair the timeliness, accuracy or
                            completeness of any Third-Party Content. You are responsible for any User Content and/or Contact Details you submit
                            in connection with any Third-Party Content as well as your use of any Third-Party Content. The Third-Party Content may
                            be subject to the related third-party’s own terms of use and privacy policy which you are responsible for complying with.
                            As such, we strongly encourage you to review the third-party’s terms of use and privacy policy.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                10. Prohibited Uses
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            You may use the Rezied website and related Services subject to your compliance with all applicable United States laws
                            and these Terms and Privacy Policy as well as any additional third-party terms related to the Third-Party Content
                            contained within or related to the Services. Unless you receive the express written consent of Rezied stating otherwise,
                            you agree to refrain from:
                            <br></br>
                            <br></br>
                            (i) accessing or using the Services in any manner or for any purpose that is unlawful or violates the rights of Rezied, any
                            third-party or any other users of the Services,
                            <br></br>
                            <br></br>
                            (ii) using any information contained in the Services as anything other than for informative purposes, as opposed to
                            advice or counsel which the information contained in the Services is not,
                            <br></br>
                            <br></br>
                            (iii) accessing or using the Services in any manner or for any purpose that Rezied determines in its sole discretion is
                            misleading, malicious, harmful, discriminatory, inappropriate or otherwise offensive,
                            <br></br>
                            <br></br>
                            (iv) scraping, mining or otherwise copying information from the Services, which includes User Content, using automated
                            processes including harvesting bots, crawlers, robots, scrapers, spiders and any other automated process used with the
                            intent to extract information from the Services,
                            <br></br>
                            <br></br>
                            (v) reproducing, duplicating, reverse engineering, decompiling, extracting or otherwise creating derivative works from
                            any portion of the Services,
                            <br></br>
                            <br></br>
                            (vi) interfering with, disabling, overburdening or otherwise impairing the proper functionality, appearance or security of
                            the Services,
                            <br></br>
                            <br></br>
                            (vii) uploading viruses, trojan horses, worms or other malicious software to the Services,
                            <br></br>
                            <br></br>
                            (viii) posting or transmitting any spam advertisements, contests, sweepstakes, multi-level marketing schemes or other
                            off-platform solicitations,
                            <br></br>
                            <br></br>
                            (ix) soliciting user account information or Contact Details outside of the Bidding Services,
                            <br></br>
                            <br></br>
                            (x) disclosing or using any name, phone number and/or e-mail address you receive in connection with the Bidding
                            Services other than to carry out the Bidding Services as they pertain to that specific individual,
                            <br></br>
                            <br></br>
                            (xi) bypassing any restrictive software used within the Services to restrict or limit access,
                            <br></br>
                            <br></br>
                            (xii) using our copyrights or trademarks without our express written consent,
                            <br></br>
                            <br></br>
                            (xiii) using the Services on behalf of or impersonating someone else or providing misleading or false information about
                            yourself,
                            <br></br>
                            <br></br>
                            (xiv) attempting, encouraging or facilitating the violation of these Terms.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                11. Termination of Usage
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            You may voluntarily terminate your account with Rezied by emailing customer.support@Rezied.com. You will be
                            responsible for paying all outstanding amounts due, if any, to Rezied at the time of your voluntary termination request.
                            Rezied, in its sole discretion and for any reason, may suspend, terminate or restrict your access and/or use of the
                            Services, in part or in their entirety, at any time and without any advance notice or liability. Access or use of the Services
                            may be discontinued because Rezied has discontinued a portion of the Services. In such a case, Rezied shall not be held
                            liable for any change to you resulting from your discontinued access or use.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                12. Intellectual Property
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            Rezied owns the Services as well as the photos, images, data, reviews, information, text, contracts, graphics, software,
                            logos, trademarks and other content contained on the Services (collectively, “Rezied Content”) except where Rezied has
                            expressly indicated the source of such content as separate from Rezied (“Other Content”). Other Content belongs to the
                            respective third-party named in the Services. The Rezied Content is protected by trademarks, copyrights, patents and
                            other laws, as applicable. Your access and/or use of the Services does not grant you the right to use the Rezied Content
                            except as expressly provided for in these Terms. Any lack of action with respect to a violation of Rezied’ rights regarding
                            the Rezied Content or Services by you or any third-party is not a waiver of our right to act on any past or present breach
                            or future similar breach.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                13. Copyright Infringement / Digital Millennium Copyright Act
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            Rezied respects and cares about the intellectual property rights of others. If you believe, in good faith, that the Services
                            infringe your copyright then pursuant to the Digital Millennium Copyright Act (“DMCA”), Title 17, United States Code,
                            Section 512(c), you must provide the following information in writing:
                            <br></br>
                            <br></br>
                            (i) A physical or electronic signature of a person authorized to act on behalf of the copyright owner;
                            <br></br>
                            <br></br>
                            (ii) Identification of the copyrighted work claimed to have been infringed;
                            <br></br>
                            <br></br>
                            (iii) Identification of the material that is claimed to be infringing and information reasonably sufficient to permit Rezied
                            to locate the material;
                            <br></br>
                            <br></br>
                            (iv) Information reasonably sufficient to permit Rezied to contact the complaining party, such as an address, telephone
                            number, and, if available, an e-mail address;
                            <br></br>
                            <br></br>
                            (v) A statement that the complaining party has a good faith belief that use of the material in the manner complained of
                            is not authorized by the copyright owner, its agent, or the law;
                            <br></br>
                            <br></br>
                            (vi) A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining
                            party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
                            The information above must be sent to:
                            <br></br>
                            <br></br>
                            ATTN: Designated Agent
                            <br></br>
                            PO Box 20187
                            <br></br>
                            New York, NY 10014
                            <br></br>
                            <br></br>
                            Please e-mail a copy of the above mailed notice which contains this information to dmca@Rezied.com.
                            <br></br>
                            <br></br>
                            IF YOU KNOWINGLY MISREPRESENT THAT ONLINE MATERIAL IS INFRINGING THEN, UNDER FEDERAL LAW, YOU MAY BE
                            SUBJECT TO CRIMINAL PROSEUCTION FOR PERJURY AND TO CIVIL PENALTIES, INCLUDING MONETARY DAMAGES, COURT
                            COSTS AND ATTORNEYS’ FEES, ARISING OUT OF ANY ACTION TAKEN BASED ON YOUR MISREPRESENTATION.
                            <br></br>
                            <br></br>
                            Rezied, in its sole discretion, reserves the right to remove, disable or suspend access to material that is claimed to be
                            infringing without prior notice and without liability to you. Information contained in this section is not and should not
                            be construed as legal advice. For further information regarding your compliance with DMCA notifications, see Title 17,
                            United States Code, Section 512(c).
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                14. Warranty Disclaimer
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            THE REZIED SERVICES, REZIED CONTENT, OTHER CONTENT AND ALL OTHER MATERIALS MADE AVAILABLE TO YOU
                            THROUGH THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE MAXIMUM EXTENT
                            PERMISSIBLE BY APPLICABLE LAW, REZIED DISCLAIMS ANY AND ALL REPRESENTATIONS OR WARRANTIES, EXPRESS OR
                            IMPLIED, INCLUDING, WITHOUT LIMITATION: (I) WARRANTIES OF ACCURACY, SECURITY, TITLE, FITNESS FOR A
                            PARTICULAR PURPOSE, NON-INFRINGEMENT AND MERCHANTABILITY; (II) WARRANTIES ARISING BY USAGE OF TRADE
                            AND COURSE OF DEALING; AND (III) WARRANTIES OF THE AVAILABILITY OF ACCESS OR USE. YOU EXPRESSLY AGREE
                            THAT YOUR USE OF THE SERVICES IS VOLUNTARY AND AT YOUR SOLE RISK.
                            <br></br>
                            <br></br>
                            FURTHER, REZIED DOES NOT GUARANTEE ANY EXPRESS OR IMPLIED AGREEMENT MADE BETWEEN YOU AND OTHER
                            USERS OF THE BIDDING SERVICES NOR DOES REZIED GUARANTEE ANY PARTICULAR OUTCOME RESULTING FROM YOUR
                            ACCESS OR USE OF THE BIDDING SERVICES OR THE SERVICES MORE BROADLY. YOU AGREE THAT ANY INFORMATION
                            OBTAINED FROM ACCESS OR USE OF THE SERVICES IS FOR INFORMATIONAL PURPOSES ONLY AND WILL NOT BE USED AS
                            ADVICE OR COUNSEL. UNLESS EXPRESSLY STATED IN THESE TERMS OF USE, NO INFORMATION OBTAINED, BY ANY
                            MEANS, BY YOU THROUGH THE SERVICES, REZIED CONTENT, OTHER CONTENT OR ANY OTHER MATERIALS MADE
                            AVAILABLE TO YOU THROUGH THE SERVICES WILL CREATE ANY WARRANTY BY REZIED.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                15. Limitation of Liability
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, REZIED AND ITS DIRECTORS, OFFICERS, EMPLOYEES,
                            SHAREHOLDERS, AFFILIATES, AGENTS AND PROVIDERS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE,
                            INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, LOSS OF PROFITS OR LOSS OF
                            SAVINGS), WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, BREACH OF WARRANTY, OR OTHERWISE, RESULTING
                            FROM (I) YOUR ACCESS OR USE, WHETHER SUCCESSFUL OR UNSUCCESSFUL, OF THE SERVICES, REZIED CONTENT, OTHER
                            CONTENT OR ANY OTHER MATERIALS MADE AVAILABLE TO YOU THROUGH THE SERVICES, (II) THESE TERMS OF USE OR
                            (III) ANY OUTCOME RESULTING FROM YOUR ACCESS OR USE OF THE BIDDING SERVICES OR THE SERVICES MORE
                            BROADLY.
                            <br></br>
                            <br></br>
                            THE AGGREGATE LIABILITY OF REZIED AND ITS DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, AFFILIATES, AGENTS
                            AND PROVIDERS TO YOU ARISING OUT OF THESE TERMS OF USE WILL NOT EXCEED THE GREATER OF ONE HUNDRED
                            DOLLARS ($100) OR THE AMOUNT YOU HAVE PAID US IN THE 12 MONTHS PRIOR TO THE CIRCUMSTANCES THAT GAVE
                            RISE TO THE CLAIMS. THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES
                            MAY NOT BE ALLOWED BY APPLICABLE LAW, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN SUCH CASES, THE
                            LIABILITY OF REZIED AND ITS DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, AFFILIATES, AGENTS AND PROVIDERS
                            WILL BE LIMITED TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                16. Indemnification
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            You agree to defend, indemnify and hold harmless Rezied and its directors, officers, employees and agents from and
                            against any and all claims, liabilities, expenses and demands resulting from, or connected in any way with: (i) your access
                            or use of the Services, (ii) your breach of these Terms or the agreements referenced as part of these Terms, (iii) your
                            breach of any applicable law, (iv) your use or access of Rezied intellectual property, and (v) any User Content shared
                            with, uploaded to or transferred to Rezied or through the Services.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                17. Arbitration
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            Section 17 deals with a dispute, controversy, or claim arising out of, or in connection with, these Terms or the Services,
                            or any breach or alleged breach thereof, except to the extent that you have violated or threatened to violate any Rezied
                            intellectual property right (“Dispute”).
                            <br></br>
                            <br></br>
                            UNLESS YOU AND REZIED AGREE OTHERWISE, BOTH YOU AND REZIED AGREE THAT ANY DISPUTE RESOLUTION
                            PROCEEDINGS ARE TO BE CONDUCTED ON AN INDIVIDUAL BASIS RATHER THAN IN A CLASS, CONSOLIDATED OR
                            REPRESENTATIVE ACTION.
                            <br></br>
                            <br></br>
                            If the Dispute cannot be settled through direct discussions, the parties agree to promptly endeavor to settle the Dispute
                            by mediation administered by the American Arbitration Association (“AAA”) under its Commercial Mediation Procedures
                            then in effect (or under any other form of mediation mutually acceptable to the parties involved) before resorting to
                            arbitration. Any unresolved Dispute shall be submitted to, and settled by, a single arbitrator in arbitration proceedings
                            administered by the AAA in accordance with its Commercial Arbitration Rules then in effect (or under any other form of
                            arbitration mutually acceptable to the parties involved), and judgment on the award rendered by the arbitrator may be
                            entered in any court having jurisdiction thereof. If all parties to the Dispute agree, the mediator involved in the parties’
                            mediation may be asked to serve as the arbitrator. The mediation and arbitration, if any, shall take place in New York,
                            New York, unless another location is mutually agreed by the parties. Any award rendered shall be final and conclusive
                            upon the parties.
                            <br></br>
                            <br></br>
                            The expenses of the mediation and arbitration shall be borne equally by the parties, provided that each party shall pay
                            for and bear the cost of its own experts, evidence, and attorneys’ fees, except that in the discretion of the arbitrator, any
                            award may include the reasonable attorneys&#39; fees of a party if the arbitrator expressly determines that the party against
                            whom such award is entered has directly, or indirectly, caused the Dispute to be submitted to arbitration as a dilatory
                            tactic or in bad faith.
                            <br></br>
                            <br></br>
                            In order to initiate an arbitration proceeding, you must follow the process outlined by the AAA on their website at
                            https://www.adr.org or via phone at 1-800-778-7879. You must also mail to Rezied headquarters a notice which: (i)
                            briefly explains the dispute, (ii) lists your name and address, (iii) lists the name and address of Rezied, (iv) specifies the
                            amount of money involved, and (v) states what you want as claimant.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                18. Assignability
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            You may not assign, transfer, sublicense or otherwise delegate your rights, duties or obligations under these Terms.
                            Rezied may freely assign its rights, duties and obligations under these Terms without such an assignment being
                            considered an amendment to the Terms and without notice under the condition that your rights under these Terms
                            remain unamended.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                19. Governing Law
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            These Terms shall be governed by and construed in accordance with the laws of the State of New York, without regard
                            to its conflict of laws provisions, in the United States of America. You agree to submit to the jurisdiction and venue of
                            the state and federal courts of New York County, New York for any and all actions, claims and disputes arising from or in
                            connection with the Services and/or these Terms. In any action, claim or dispute, regardless of the initiating party, the
                            prevailing party shall be entitled to recover all reasonable legal expenses incurred in connection with the action, claim or
                            dispute.
                            <br></br>
                            <br></br>
                            Nothing in this provision shall limit the arbitration obligations set forth in these Terms. Rezied makes no representation
                            that the Services are appropriate or available for use in other locations and those who choose to access the Services
                            from other locations do so of their own volition and are responsible for complying with local laws, where applicable.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                20. Severability
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            In the event that any term, provision, or covenant included in these Terms shall be deemed invalid or unenforceable,
                            such term, provision, or covenant will be modified in a manner that comes closest to expressing the intention of the
                            original term, provision or covenant. The validity, legality or enforceability of the remaining terms, provisions, and
                            covenants of these Terms shall not be affected by any such modification and will be enforceable as so modified.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                21. Reserved Rights
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            Any rights to the Rezied Content or Services not expressly granted in these Terms are reserved by Rezied.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                22. Construction
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            Please note that the names assigned to these Terms and the section captions used in these Terms are for convenience
                            or reference only and shall not be construed to affect the meaning, construction or effect of this document.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="terms_and_agreements_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                23. Feedback
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="terms_and_agreements_page__accordion_panel">
                            Feedback is critically important in helping Rezied provide the highest level of service to you. You are responsible for any
                            material, and the originality of that material, submitted to Rezied. Any and all rights to materials submitted to Rezied
                            becomes the exclusive property of Rezied with an unrestricted, irrevocable, perpetual, non-exclusive, fully-paid, royalty-
                            free, worldwide license to use in any manner and for any purpose. Please do not reveal trade secrets, sensitive
                            information or other confidential material in your feedback.
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "25px 0px 25px 0px",
                }}>
                    <Button
                        className="positive_button_round hover"
                        onMouseDown={() => window.location.href="/buyside-commission-terms-and-conditions"}
                        rightIcon={<FaArrowRightLong/>}
                    >
                        Buyside Commission Terms & Conditions
                    </Button>
                </div>
                <p style={{
                    color: "grey",
                    fontSize: "0.7em",
                    margin: "15px 0px 0px 0px",
                }}>
                    Last Modified <i>April 30th, 2024</i>
                </p>
            </div>
            <Footer/>
        </div>
    )
}

export default TermsAndAgreementsPage;