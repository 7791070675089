import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Radar from 'radar-sdk-js';

import {
    Box,
    Button,
    Checkbox,
    IconButton,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    Tabs,
    TabPanel,
    TabPanels,
    Tooltip,
    Stack,
    Select,
    useSteps,
    useToast,
} from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';

import {
    APARTMENT,
    CONDOMINIUM,
    DELETE,
    DUPLEX,
    DEFAULT_GET_TIMEOUT,
    GET,
    ID,
    FIRST_NAME,
    MANUFACTURED,
    MULTIPLEX,
    PATCH,
    SINGLE_FAMILY_HOME,
    TOWNHOME,
    TRAILER,
    POST,
    DEFAULT_DELETE_TIMEOUT,
    DEFAULT_PATCH_TIMEOUT,
    DEFAULT_POST_TIMEOUT,
    FIRST_TIME_BUYER,
    PRE_APPROVED,
    CREDIT_SCORE,
    MOVE_BEFORE_DATE,
    MIN_PRICE,
    MAX_PRICE,
    ASSET_INTERESTS
} from '../../../utils/constants.js';
import { axiosGetBuyer } from '../../common/methods/methods.js';
import { checkRequireBuyerSignUp } from '../../../utils/utility.js';

import { MdClose } from 'react-icons/md';

function BuyerSignUpInformationModal(props) {

    const user = props.user;
    // Set this to null to close the modal in parent component.
    const setFinishSignUpFormBuyer = props.setFinishSignUpFormBuyer;

    const [profileForm, setProfileForm] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [interestedAreasAutocompleteAddresses, setInterestedAreasAutocompleteAddresses] = useState(null);
    const [formInterestedAreas, setFormInterestedAreas] = useState([]);

    const [firstTimeError, setFirstTimeError] = useState(null);
    const [preApprovedError, setPreApprovedError] = useState(null);
    const [creditScoreError, setCreditScoreError] = useState(null);
    const [moveBeforeDateError, setMoveBeforeDateError] = useState(null);
    const [minPriceError, setMinPriceError] = useState(null);
    const [maxPriceError, setMaxPriceError] = useState(null);
    const [interestedAssetsError, setInterestedAssetsError] = useState(null);

    const [triggerFetchInterestedAreas, setTriggerFetchInterestedAreas] = useState(false);

    const toast = useToast();

    // Fetch agent's areas of Expertise.
    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/buyer/interested_areas/' + user[ID],
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                let interestedAreas = response.data;
                setFormInterestedAreas(interestedAreas);
            }
        }).catch(error => {
            // TODO
        })
    }, [user, triggerFetchInterestedAreas]);

    const steps = [
        { title: 'First', description: 'General' },
        { title: 'Second', description: 'Buyer' },
        { title: 'Third', description: 'Specialties' },
    ];

    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })

    const [tabIndex, setTabIndex] = useState(0);

    const checkAndAlertGeneralTabFormErrors = () => {

        if (!profileForm) return true;

        var hasErrors = false;

        // Check form errors.
        if (user[FIRST_TIME_BUYER] === null) {
            if (!profileForm[FIRST_TIME_BUYER] || profileForm[FIRST_TIME_BUYER].length === 0) {
                hasErrors = true;
                setFirstTimeError("Required");
            }
        }
        
        if (user[PRE_APPROVED] === null) {
            if (!profileForm[PRE_APPROVED] || profileForm[PRE_APPROVED].length === 0) {
                hasErrors = true;
                setPreApprovedError("Required");
            }
        }

        if (!user[CREDIT_SCORE] || user[CREDIT_SCORE].length === 0) {
            if (!profileForm[CREDIT_SCORE] || profileForm[CREDIT_SCORE].length === 0) {
                hasErrors = true;
                setCreditScoreError("Required");
            } else if (parseInt(profileForm[CREDIT_SCORE]) < 350) {
                hasErrors = true;
                setCreditScoreError("Minimum Credit Score is 350");
            } else if (parseInt(profileForm[CREDIT_SCORE]) > 850) {
                hasErrors = true;
                setCreditScoreError("Maximum Credit Score is 850");
            }
        }

        return hasErrors;
    }

    const checkAndAlertBuyerTabFormErrors = () => {

        if (!profileForm) return true;

        var hasErrors = false;

        // Check form errors.
        if (!user[MOVE_BEFORE_DATE] || user[MOVE_BEFORE_DATE].length === 0) {
            if (!profileForm[MOVE_BEFORE_DATE] || profileForm[MOVE_BEFORE_DATE].length === 0) {
                hasErrors = true;
                setMoveBeforeDateError("Required");
            }   
        }
        
        if (!user[MIN_PRICE] || user[MIN_PRICE].length === 0) {
            if (!profileForm[MIN_PRICE] || profileForm[MIN_PRICE].length === 0) {
                hasErrors = true;
                setMinPriceError("Required");
            } else if (parseInt(profileForm[MIN_PRICE]) < 0) {
                hasErrors = true;
                setMinPriceError("Cannot be negative");
            } else if (parseInt(profileForm[MIN_PRICE]) > 1000000000) {
                hasErrors = true;
                setMinPriceError("Too high");
            }
        }
        
        if (!user[MAX_PRICE] || user[MAX_PRICE].length === 0) {
            if (!profileForm[MAX_PRICE] || profileForm[MAX_PRICE].length === 0) {
                hasErrors = true;
                setMaxPriceError("Required");
            } else if (parseInt(profileForm[MAX_PRICE]) < 0) {
                hasErrors = true;
                setMaxPriceError("Cannot be negative");
            } else if (parseInt(profileForm[MAX_PRICE]) > 10000000000) {
                hasErrors = true;
                setMaxPriceError("Too high");
            }
        }
       
        return hasErrors;            
    }

    const checkAndAlertSpecialtyTabFormErrors = () => {

        if (!profileForm) return true;

        var hasErrors = false;

        // Check form errors.    
        if (!user[ASSET_INTERESTS] || user[ASSET_INTERESTS].length === 0) {
            let interestedAssetsCheckbox = document.getElementsByClassName("interested_assets_checkbox");
            // Check if at least one checkbox is checked.
            let atLeastOneChecked = false;
            for (let i = 0; i < interestedAssetsCheckbox.length; i++) {
                let chakraCheckboxElement = interestedAssetsCheckbox[i];
                // The actual element is the first child, the input.
                let checkboxInput = chakraCheckboxElement.children[0];
                if (checkboxInput.checked === true) {
                    atLeastOneChecked = true;
                }
            }
            if (!atLeastOneChecked) {
                setInterestedAssetsError("At least one is required")
                hasErrors = true;
            }
        }      
        
        return hasErrors; 
    }

    const handleProfileFormChange = (event) => {
        // Reset our errors.
        if (event.target.name === FIRST_TIME_BUYER) {
            setFirstTimeError(null);
        }
        if (event.target.name === PRE_APPROVED) {
            setPreApprovedError(null);
        }
        if (event.target.name === CREDIT_SCORE) {
            setCreditScoreError(null);
        }
        if (event.target.name === MOVE_BEFORE_DATE) {
            setMoveBeforeDateError(null);
        }
        if (event.target.name === MIN_PRICE) {
            setMinPriceError(null);
        } 
        if (event.target.name === MAX_PRICE) {
            setMaxPriceError(null);
        }
        if (event.target.name === ASSET_INTERESTS) {
            setMaxPriceError(null);
        }
        
        if (event.target.name === MOVE_BEFORE_DATE) {
            profileForm[event.target.name] = new Date(event.target.value);
            return;
        }

        if (event.target.name === CREDIT_SCORE || event.target.name === MIN_PRICE || event.target.name === MAX_PRICE) {
            profileForm[event.target.name] = parseInt(event.target.value);
        } else if (event.target.name === FIRST_TIME_BUYER) {
            profileForm[event.target.name] = event.target.value === "Yes" ? true : false;
        } else {        
            profileForm[event.target.name] = event.target.value;
        }
        console.log(profileForm);
    }

    const getAssetSpecialtyValues = () => {
        let assetSpecialtyValues = [];
        let assetSpecialtyCheckboxes = document.getElementsByClassName("interested_assets_checkbox");
        for (let i = 0; i < assetSpecialtyCheckboxes.length; i++) {
            let chakraCheckboxElement = assetSpecialtyCheckboxes[i];
            // The actual element is the first child, the input.
            let checkboxInput = chakraCheckboxElement.children[0];
            if (checkboxInput.checked === true) {
                assetSpecialtyValues.push(checkboxInput.value);
            }
        }
        return assetSpecialtyValues;
    }

    const handleDeleteInterestedArea = (area_id) => {
        axios({
            method: DELETE,
            url: '/api/v1/user/buyer/interested_area/' + user[ID] + '/' + area_id,
            timeout: DEFAULT_DELETE_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                setTriggerFetchInterestedAreas(!triggerFetchInterestedAreas);
            }
        }).catch(error => {
            // TODO
        })
    }

    const handleAddInterestedArea = (address) => {
        axios({
            method: POST,
            url: '/api/v1/user/buyer/interested_area/' + user[ID],
            timeout: DEFAULT_POST_TIMEOUT,
            data: {
                buyer_id: user[ID],
                coordinates: {
                    longitude: parseFloat(address["longitude"]),
                    latitude: parseFloat(address["latitude"]),
                },
                formatted_address: address["formattedAddress"],
                city: address["city"],
                state: address["state"],
                country: address["country"],
                country_code: address["countryCode"],
            },
        }).then(response => {
            if (200 === response.status) {
                setTriggerFetchInterestedAreas(!triggerFetchInterestedAreas);
            }
        }).catch(error => {
            if (409 === error.response.status) {
                toast({
                    title: 'Duplicate interested area',
                    description: "You've already marked that area",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
        })
    }

    const handleUpdateUserProfile = (data) => {
        axios({
            method: PATCH,
            url: '/api/v1/user/buyer/profile/' + user[ID],
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: data,
        }).then(response => {
            if (200 === response.status) {
                axiosGetBuyer(user[ID]).then(buyer => {
                    if (buyer) {
                        if (checkRequireBuyerSignUp(buyer) === true) {
                            toast({
                                title: 'Missing required information',
                                description: "We still need some information for your profile.",
                                status: 'info',
                                duration: 3000,
                                isClosable: true,
                                position: 'top'
                            });
                        } else {
                            setFinishSignUpFormBuyer(null);
                        };
                    }
                }).catch(error => {
                    // TODO
                });
            }
        }).catch(error => {
            // TODO
        }, []);
    }

    const handleInterestedAreasInputChange = (e) => {
        let val = e.target.value;
        setInputValue(val);
        if (val.length <= 2) {
            setInterestedAreasAutocompleteAddresses(null);
            return;
        }
        Radar.autocomplete({
            query: val,
            limit: 10,
            country: 'US',
        }).then(response => {
            // We want to let users search for the following.
            // 1. Locality's
            // 2. Zip Codes
            let addresses = response.addresses.filter(e =>
                e["formattedAddress"].includes("USA") && (e["layer"] === "locality" || 
                (e["postalCode"] && e["postalCode"].includes(val)))
            );
            setInterestedAreasAutocompleteAddresses(addresses);
        }).catch(error => {
            // TODO
        })
    }

    const renderInterestedAreasAutocompleteAddresses = () => {

        let renderedAddresses = [];

        for (let i = 0; i < interestedAreasAutocompleteAddresses.length; i++) {
            let formattedAddress = interestedAreasAutocompleteAddresses[i]["formattedAddress"];
            renderedAddresses.push(
                <div key={i + "" + formattedAddress} className="autocomplete__interested_address"
                    onMouseDown={() => {
                        handleAddInterestedArea(interestedAreasAutocompleteAddresses[i]);
                        // setFormExpertiseAreas()
                        setInterestedAreasAutocompleteAddresses(null);
                        setInputValue('');
                    }}
                style={{
                    cursor: "pointer",
                    padding: "7.5px 10px 7.5px 10px",
                }}>
                    <p style={{
                        fontSize: "0.9em"
                    }}>
                        {formattedAddress}
                    </p>
                </div>
            )
        }
        
        return (
            <div style={{
                backgroundColor: "white",
                border: "1px solid #cccccc",
                borderRadius: "4px",
                position: "absolute",
                width: "100%",
                zIndex: "2",
            }}>
                {renderedAddresses}
            </div>
        )
    }

    const renderFormInterestedAreas = () => {

        if (!formInterestedAreas || formInterestedAreas.length === 0) return;

        let areas = [];
        for (let i = 0; i < formInterestedAreas.length; i++) {
            let area = formInterestedAreas[i];
            let address;
            if (area.formattedAddress) {
                address = area.formattedAddress;
            } else if (area.formatted_address) {
                address = area.formatted_address;
            } else {
                address = "N/A";
            }
            areas.push(
                <div style={{
                    backgroundColor: "#efefef",
                    borderRadius: "8px",
                    display: "flex",
                    fontSize: "0.8em",
                    margin: "0px 10px 0px 0px",
                    padding: "7.5px 12.5px 7.5px 12.5px",
                }}>
                    <p>
                        {address}
                    </p>
                    <MdClose 
                        className="hover"
                        onMouseDown={() => {
                            handleDeleteInterestedArea(area[ID]);
                        }}
                        style={{
                            cursor: "pointer",
                            height: "15px",
                            margin: "1.5px 0px 0px 10px",
                            width: "15px",
                        }}
                    />
                </div>
            );
        }

        return (
            <div style={{
                display: "flex",
                margin: "15px 0px 0px 0px",
            }}>
                {areas}
            </div>
        );
    }

    if (!user) return;

    return (
        <Modal isOpen={true} size='6xl'>
            <ModalOverlay />
            <ModalContent>
                <ModalBody>
                    <div style={{
                        margin: "0px auto 0px auto",
                        padding: "50px 0px 50px 0px",
                        width: "500px",
                    }}>
                        <p style={{
                            fontWeight: "bold",
                            fontSize: "1.4em",
                        }}>
                            Welcome to Rezied {user[FIRST_NAME]},
                        </p>
                        <p style={{
                            fontSize: "0.9em",
                            margin: "10px 0px 0px 0px",
                        }}>
                            We're excited to have you here. We need some additional information to help target prospective agents.
                        </p>
                        <p style={{
                            color: "grey",
                            fontSize: "0.9em",
                            margin: "5px 0px 0px 0px",
                        }}>
                            (You can always change these answers later in your profile)
                        </p>
                        <Stepper colorScheme='green' index={activeStep} size='sm' style={{
                            margin: "25px 0px 15px 0px",
                        }}>
                            {steps.map((step, index) => (
                                <Step key={index}>
                                <StepIndicator>
                                    <StepStatus
                                        complete={<StepIcon />}
                                        incomplete={<StepNumber />}
                                        active={<StepNumber />}
                                    />
                                </StepIndicator>

                                <Box flexShrink='0'>
                                    <StepTitle>{step.title}</StepTitle>
                                    <StepDescription>{step.description}</StepDescription>
                                </Box>

                                <StepSeparator />
                                </Step>
                            ))}
                        </Stepper>
                        <Tabs index={tabIndex} onChange={() => {}}>
                            <TabPanels>
                                <TabPanel style={{
                                    padding: "0px",
                                }}>
                                    <div style={{
                                        margin: "15px 0px 0px 0px",
                                    }}>
                                        <p style={{
                                            fontSize: "0.9em",
                                        }}>
                                            Are you a first time buyer?
                                        </p>
                                        <Tooltip
                                            bg='red'
                                            hasArrow
                                            label={firstTimeError}
                                            isOpen={firstTimeError !== null}
                                            placement='top-end'
                                        >
                                            <Select 
                                                name={FIRST_TIME_BUYER}
                                                className="default_input"
                                                style={{
                                                    margin: "5px 0px 0px 0px",
                                                }}
                                                defaultValue={
                                                    user && user[FIRST_TIME_BUYER] !== null ? 
                                                    (user[FIRST_TIME_BUYER] === true ? "Yes" : "No")
                                                    : null
                                                }
                                                onChange={handleProfileFormChange}
                                            >
                                                <option hidden>-</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </Select>
                                        </Tooltip>
                                    </div>
                                    <div style={{
                                        margin: "20px 0px 0px 0px",
                                    }}>
                                        <p style={{
                                            fontSize: "0.9em",
                                        }}>
                                            Are you pre-approved already?
                                        </p>
                                        <Tooltip
                                            bg='red'
                                            hasArrow
                                            label={preApprovedError}
                                            isOpen={preApprovedError !== null}
                                            placement='top-end'
                                        >
                                            <Select 
                                                name={PRE_APPROVED}
                                                className="default_input"
                                                style={{
                                                    margin: "5px 0px 0px 0px",
                                                }}
                                                defaultValue={
                                                    user && user[PRE_APPROVED] !== null ? 
                                                    (user[PRE_APPROVED] === true ? "Yes" : "No")
                                                    : null
                                                }
                                                onChange={handleProfileFormChange}
                                            >
                                                <option hidden>-</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </Select>
                                        </Tooltip>
                                    </div>
                                    <div style={{
                                        margin: "20px 0px 0px 0px",
                                    }}>
                                        <p style={{
                                            fontSize: "0.9em",
                                        }}>
                                            What is your estimated Credit Score?
                                        </p>
                                        <Tooltip
                                            bg='red'
                                            hasArrow
                                            label={creditScoreError}
                                            isOpen={creditScoreError !== null}
                                            placement='top-end'
                                        >
                                            <Input
                                                name={CREDIT_SCORE}
                                                className="default_input"
                                                style={{
                                                    margin: "5px 0px 0px 0px",
                                                }}
                                                defaultValue={
                                                    user && user[CREDIT_SCORE] ? 
                                                    user[CREDIT_SCORE]
                                                    : null
                                                }
                                                type='number'
                                                onChange={handleProfileFormChange}
                                            >
                                            </Input>
                                        </Tooltip>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        margin: "20px 0px 0px 0px",
                                    }}>
                                        <IconButton className="hover" size='sm' style={{
                                            backgroundColor: "#05b96b",
                                            color: "white",
                                        }} icon={<ArrowForwardIcon/>} isRound={true} onMouseDown={() => {
                                            if (checkAndAlertGeneralTabFormErrors()) {
                                                return;
                                            }
                                            setActiveStep(1);
                                            setTabIndex(1);
                                        }}>
                                        </IconButton>
                                    </div>
                                </TabPanel>
                                <TabPanel style={{
                                    padding: "0px",
                                }}>
                                    <div style={{
                                        margin: "15px 0px 0px 0px",
                                    }}>
                                        <p style={{
                                            fontSize: "0.9em",
                                        }}>
                                            What is your desired move in date?
                                        </p>
                                        <Tooltip
                                            bg='red'
                                            hasArrow
                                            label={moveBeforeDateError}
                                            isOpen={moveBeforeDateError!== null}
                                            placement='top-end'
                                        >
                                            <Input
                                                name={MOVE_BEFORE_DATE}
                                                className="default_input"
                                                style={{
                                                    margin: "5px 0px 0px 0px",
                                                }}
                                                defaultValue={
                                                    user && user[MOVE_BEFORE_DATE] ? 
                                                    user[MOVE_BEFORE_DATE]
                                                    : null
                                                }
                                                type='date'
                                                onChange={handleProfileFormChange}
                                            >
                                            </Input>
                                        </Tooltip>
                                    </div>
                                    <div style={{
                                        margin: "20px 0px 0px 0px",
                                    }}>
                                        <p style={{
                                            fontSize: "0.9em",
                                        }}>
                                            What's your minimum price?
                                        </p>
                                        <Tooltip
                                            bg='red'
                                            hasArrow
                                            label={minPriceError}
                                            isOpen={minPriceError !== null}
                                            placement='top-end'
                                        >
                                            <Input
                                                name={MIN_PRICE}
                                                className="default_input"
                                                style={{
                                                    margin: "5px 0px 0px 0px",
                                                }}
                                                defaultValue={
                                                    user && user[MIN_PRICE] ? 
                                                    user[MIN_PRICE]
                                                    : null
                                                }
                                                type='number'
                                                onChange={handleProfileFormChange}
                                            >
                                            </Input>
                                        </Tooltip>
                                    </div>
                                    <div style={{
                                        margin: "20px 0px 0px 0px",
                                    }}>
                                        <p style={{
                                            fontSize: "0.9em",
                                        }}>
                                            What's your maximum price?
                                        </p>
                                        <Tooltip
                                            bg='red'
                                            hasArrow
                                            label={maxPriceError}
                                            isOpen={maxPriceError !== null}
                                            placement='top-end'
                                        >
                                            <Input
                                                name={MAX_PRICE}
                                                className="default_input"
                                                style={{
                                                    margin: "5px 0px 0px 0px",
                                                }}
                                                defaultValue={
                                                    user && user[MAX_PRICE] ? 
                                                    user[MAX_PRICE]
                                                    : null
                                                }
                                                type='number'
                                                onChange={handleProfileFormChange}
                                            >
                                            </Input>
                                        </Tooltip>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        margin: "20px 0px 0px 0px",
                                    }}>
                                        <IconButton className="hover" size='sm' style={{
                                            backgroundColor: "#efefef",
                                            color: "black",
                                        }} icon={<ArrowBackIcon/>} isRound={true} onMouseDown={() => {
                                            setActiveStep(0);
                                            setTabIndex(0);
                                        }}>
                                        </IconButton>
                                        <IconButton className="hover" size='sm' style={{
                                            backgroundColor: "#05b96b",
                                            color: "white",
                                        }} icon={<ArrowForwardIcon/>} isRound={true} onMouseDown={() => {
                                            if (checkAndAlertBuyerTabFormErrors()) {
                                                return;
                                            }
                                            setActiveStep(2);
                                            setTabIndex(2);
                                        }}>
                                        </IconButton>
                                    </div>
                                </TabPanel>
                                <TabPanel style={{
                                    padding: "0px",
                                }}>
                                    <div style={{
                                        margin: "20px 0px 0px 0px",
                                    }}>
                                        <p style={{
                                            fontSize: "0.9em",
                                        }}>
                                            What type of home are you looking for?
                                        </p>
                                        <Tooltip
                                            bg='red'
                                            hasArrow
                                            label={interestedAssetsError}
                                            isOpen={interestedAssetsError !== null}
                                            placement='top-end'
                                        >
                                            <Stack style={{
                                                margin: "5px 0px 0px 0px",
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    width: "100%"
                                                }}>
                                                    <div style={{
                                                        display: "block",
                                                        width: "50%",
                                                    }}>
                                                        <Checkbox
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            name={SINGLE_FAMILY_HOME}
                                                            value="SingleFamilyHome"
                                                            defaultChecked={user && user[ASSET_INTERESTS] && user[ASSET_INTERESTS].includes("SingleFamilyHome")}
                                                            className="interested_assets_checkbox"
                                                            onChange={() => {
                                                                setInterestedAssetsError(null);
                                                                profileForm[ASSET_INTERESTS] = getAssetSpecialtyValues();
                                                                setProfileForm(profileForm);
                                                            }}
                                                        >
                                                            Single Family Home
                                                        </Checkbox>
                                                        <Checkbox 
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            name={APARTMENT}
                                                            value="Apartment"
                                                            defaultChecked={user && user[ASSET_INTERESTS] && user[ASSET_INTERESTS].includes("Apartment")}
                                                            className="interested_assets_checkbox"
                                                            onChange={() => {
                                                                setInterestedAssetsError(null);
                                                                profileForm[ASSET_INTERESTS] = getAssetSpecialtyValues();
                                                                setProfileForm(profileForm);
                                                            }}
                                                        >
                                                            Apartment
                                                        </Checkbox>
                                                        <Checkbox 
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            name={TOWNHOME}
                                                            value="Townhome"
                                                            className="interested_assets_checkbox"
                                                            defaultChecked={user && user[ASSET_INTERESTS] && user[ASSET_INTERESTS].includes("Townhome")}
                                                            onChange={() => {
                                                                setInterestedAssetsError(null);
                                                                profileForm[ASSET_INTERESTS] = getAssetSpecialtyValues();
                                                                setProfileForm(profileForm);
                                                            }}
                                                        >
                                                            Townhome
                                                        </Checkbox>
                                                        <Checkbox 
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            name={DUPLEX}
                                                            value="Duplex"
                                                            defaultChecked={user && user[ASSET_INTERESTS] && user[ASSET_INTERESTS].includes("Duplex")}
                                                            className="interested_assets_checkbox"
                                                            onChange={() => {
                                                                setInterestedAssetsError(null);
                                                                profileForm[ASSET_INTERESTS] = getAssetSpecialtyValues();
                                                                setProfileForm(profileForm);
                                                            }}
                                                        >
                                                            Duplex
                                                        </Checkbox>
                                                    </div>
                                                    <div style={{
                                                        display: "block",
                                                        width: "50%",
                                                    }}>
                                                        <Checkbox 
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            name={MULTIPLEX}
                                                            value="Multiplex"
                                                            className="interested_assets_checkbox"
                                                            defaultChecked={user && user[ASSET_INTERESTS] && user[ASSET_INTERESTS].includes("Multiplex")}
                                                            onChange={() => {
                                                                setInterestedAssetsError(null);
                                                                profileForm[ASSET_INTERESTS] = getAssetSpecialtyValues();
                                                                setProfileForm(profileForm);
                                                            }}
                                                        >
                                                            Multiplex
                                                        </Checkbox>
                                                        <Checkbox 
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            name={CONDOMINIUM}
                                                            value="Condominium"
                                                            className="interested_assets_checkbox"
                                                            defaultChecked={user && user[ASSET_INTERESTS] && user[ASSET_INTERESTS].includes("Condominium")}
                                                            onChange={() => {
                                                                setInterestedAssetsError(null);
                                                                profileForm[ASSET_INTERESTS] = getAssetSpecialtyValues();
                                                                setProfileForm(profileForm);
                                                            }}
                                                        >
                                                            Condominium
                                                        </Checkbox>
                                                        <Checkbox 
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            name={TRAILER}
                                                            value="Trailer"
                                                            className="interested_assets_checkbox"
                                                            defaultChecked={user && user[ASSET_INTERESTS] && user[ASSET_INTERESTS].includes("Trailer")}
                                                            onChange={() => {
                                                                setInterestedAssetsError(null);
                                                                profileForm[ASSET_INTERESTS] = getAssetSpecialtyValues();
                                                                setProfileForm(profileForm);
                                                            }}
                                                        >
                                                            Trailer
                                                        </Checkbox>
                                                        <Checkbox 
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            name={MANUFACTURED}
                                                            value="Manufactured"
                                                            className="interested_assets_checkbox"
                                                            defaultChecked={user && user[ASSET_INTERESTS] && user[ASSET_INTERESTS].includes("Manufactured")}
                                                            onChange={() => {
                                                                setInterestedAssetsError(null);
                                                                profileForm[ASSET_INTERESTS] = getAssetSpecialtyValues();
                                                                setProfileForm(profileForm);
                                                            }}
                                                        >
                                                            Manufactured
                                                        </Checkbox>
                                                    </div>
                                                </div>
                                            </Stack>
                                        </Tooltip>
                                    </div>
                                    <div style={{
                                        margin: "20px 0px 0px 0px",
                                    }}>
                                        <p style={{
                                            fontSize: "0.9em",
                                        }}>
                                            What areas are you interested?
                                        </p>
                                        <p style={{
                                            color: "grey",
                                            fontSize: "0.9em",
                                            margin: "5px 0px 0px 0px",
                                        }}>
                                            (Add up to 3 areas. You can always add more later)
                                        </p>
                                        <Input 
                                            value={inputValue}
                                            // Disable the input if they have more than 3 areas already.
                                            isDisabled={formInterestedAreas && formInterestedAreas.length >= 3}
                                            className="default_input" 
                                            onChange={handleInterestedAreasInputChange}
                                            placeholder="Search for an area or zip code"
                                            style={{
                                                margin: "5px 0px 0px 0px",
                                            }}
                                        >
                                        </Input>
                                        <p style={{
                                            color: "red",
                                            fontSize: "0.7em",
                                        }}>
                                            {
                                                inputValue && inputValue.length > 0 && inputValue.length <= 2 ? 
                                                "Please enter at least two characters" : ""
                                            }
                                        </p>
                                        {
                                            interestedAreasAutocompleteAddresses && interestedAreasAutocompleteAddresses.length > 0 ?
                                            <div style={{
                                                position: "relative",
                                                width: "100%"
                                            }}>
                                                {renderInterestedAreasAutocompleteAddresses()}
                                            </div> : null
                                        }
                                        <div>
                                            {renderFormInterestedAreas()}
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        margin: "20px 0px 0px 0px",
                                    }}>
                                        <IconButton className="hover" size='sm' style={{
                                            backgroundColor: "#efefef",
                                            color: "black",
                                        }} icon={<ArrowBackIcon/>} isRound={true} onMouseDown={() => {
                                            setActiveStep(1);
                                            setTabIndex(1);
                                        }}>
                                        </IconButton>
                                        <Button className="positive_button hover" rightIcon={<ArrowForwardIcon/>} size='sm'
                                            onMouseDown={() => {
                                                if (checkAndAlertSpecialtyTabFormErrors()) {
                                                    return;
                                                }
                                                handleUpdateUserProfile({...profileForm});
                                            }}>
                                            Finish
                                        </Button>
                                    </div>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default BuyerSignUpInformationModal;