import React from 'react';

import {
    Link
} from '@chakra-ui/react';

import './Footer.css';

function Footer(props) {
    return (
        <div className="footer_page">
            <div className="footer__page_subbox">
                <div onMouseDown={() => window.location.href = "/"} style={{
                    cursor: "pointer",
                    display: "flex",
                }}>
                    <img src={require('../Images/logo/logo_500_500_transparent.png')} style={{
                        height: "30px",
                        width: "30px",
                    }}/>
                    <p style={{
                        color: "#05b96b",
                        fontSize: "1.5em",
                        fontWeight: "bold",
                        lineHeight: "30px",
                        margin: "0px 0px 0px 0px",
                    }}>
                        rezied
                    </p>
                </div>
                <div className="footer__block_box">
                    <div className="footer__block_parent_box">
                        <div className="footer__block">
                            <p className="footer__label">
                                Buyers
                            </p>
                            <div className="footer__underline"></div>
                            <div>
                                <p className="footer__link">
                                    Services
                                </p>
                            </div>
                        </div>
                        <div className="footer__block">
                            <p className="footer__label">
                                Agents
                            </p>
                            <div className="footer__underline"></div>
                            <div>
                                <p className="footer__link">
                                    Services
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="footer__block_parent_box">
                        <div className="footer__block">
                            <p className="footer__label">
                                Company
                            </p>
                            <div className="footer__underline"></div>
                            <div>
                                <p className="footer__link">
                                    About Us
                                </p>
                                <p className="footer__link">
                                    The Team
                                </p>
                                <p className="footer__link">
                                    Careers
                                </p>
                            </div>
                        </div>
                        <div className="footer__block">
                            <p className="footer__label">
                                Support
                            </p>
                            <div className="footer__underline"></div>
                            <div>
                                <p className="footer__link">
                                    Help Center
                                </p>
                                <p className="footer__link">
                                    Contact Us
                                </p>
                                <Link href="/terms-and-agreements" className="footer__link">
                                    Terms & Agreements
                                </Link>
                                <br></br>
                                <Link href="/privacy-policy" className="footer__link">
                                    Privacy Policy
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__bottom_box">
                <p style={{
                    color: "#a3a3a3",
                    fontSize: "0.9em",
                    margin: "0px 15px 0px 0px"
                }}>&copy; 2024 Rezied, Inc.</p>
                <p style={{
                    color: "#a3a3a3",
                    fontSize: "0.9em",
                    margin: "0px 15px 0px 0px"
                }}>Terms & Agreements</p>
                <p style={{
                    color: "#a3a3a3",
                    fontSize: "0.9em",
                    margin: "0px 15px 0px 0px"
                }}>Cookie Settings</p>
            </div>
        </div>
    );
}

export default Footer;