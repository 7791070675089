import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ID, MESSAGES, BUYER } from '../../../utils/constants.js';
import { axiosGetUser, axiosGetBuyer } from '../../common/methods/methods.js';
import { checkRequireBuyerSignUp } from '../../../utils/utility.js';

import BuyerDashboardParentPage from '../utils/BuyerDashboardParentPage.js';
import Messages from '../../common/Messages.js';
import BuyerSignUpInformationModal from '../utils/BuyerSignUpInformationModal.js';
import Loader from '../../common/Settings/Loader.js';

import './Page.css';
import '../../Generic.css';

function BuyerMessagesDashboardPage(props) {

    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);

    const [finishSignUpFormBuyer, setFinishSignUpFormBuyer] = useState(null);
    const [finishSignUpFormLoading, setFinishSignUpFormLoading] = useState(true);

    const urlParams = useParams();
    const userId = urlParams[ID];

    let chatToken = localStorage.getItem("chatToken");

    useEffect(() => {
        axiosGetUser(userId).then(user => {
            if (user) {
                setUser(user);
                setUserLoading(false);
            }
        }).catch(error => {
            // TODO
        });
    }, [userId]);

    useEffect(() => {
        axiosGetBuyer(userId).then(buyer => {
            if (buyer && checkRequireBuyerSignUp(buyer) === true) {
                setFinishSignUpFormBuyer(buyer);
            } 
            setFinishSignUpFormLoading(false);
        }).catch(error => {
            setFinishSignUpFormLoading(false);
        });
    }, [userId]);

    if (userLoading) return;

    return (
        <BuyerDashboardParentPage user={user} tab={MESSAGES}>
            {
                finishSignUpFormLoading ?
                <div style={{
                    margin: "auto",
                }}>
                    <Loader/>
                </div> :
                <>
                    {
                        finishSignUpFormBuyer ?
                        <BuyerSignUpInformationModal
                            user={finishSignUpFormBuyer}
                            setFinishSignUpFormBuyer={setFinishSignUpFormBuyer}
                        />: null
                    }
                    <Messages user={user} userType={BUYER} chatToken={chatToken}/>
                </>
            }
        </BuyerDashboardParentPage>
    );
}

export default BuyerMessagesDashboardPage;