import React, { useState } from 'react';
import axios from 'axios';

import {
    Button,
    Input,
    InputGroup,
    useToast,
} from '@chakra-ui/react'; 

import {
    EMAIL,
    POST
} from '../utils/constants';

import NavigationBar from './NavigationBar';

import './PasswordResetPage.css';

function PasswordResetPage(props) {

    const [form, setForm] = useState([]);
    const [isEmailSent, setIsEmailSent] = useState(false);

    const toast = useToast();

    const handleSubmitPasswordResetForm = () => {

        const POST_TIMEOUT = 15000;
        axios({
            method: POST,
            url: '/api/v1/auth/password/reset/' + form[EMAIL],
            timeout: POST_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                setIsEmailSent(true);
            }
        }).catch(error => {
            toast({
                title: "Internal Server Error",
                description: "We were unable to send out your password reset link. Please try again or contact us",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            })
        })
    }

    const handleInputChange = (e) => {
        form[e.target.name] = e.target.value;
    }

    const PasswordResetForm = () => {
        return (
            <div>
                <p style={{
                    fontSize: "2.2em",
                    fontWeight: "bold",
                    margin: "75px 0px 0px 0px",
                    textAlign: "center",
                }}>
                    Forgot your password?
                </p>
                <div style={{
                    margin: "20px auto 75px auto",
                    width: "400px",
                }}>
                    <div style={{
                        border: "1px solid #d0d0d0",
                        borderRadius: "4px",
                        padding: "25px 20px 25px 20px",
                    }}>
                        <label style={{
                            fontSize: "0.8em",
                        }}>
                            Email
                        </label>
                        <div>
                            <InputGroup>
                                <Input 
                                    name={EMAIL}
                                    onChange={handleInputChange}
                                    style={{
                                        border: "1px solid #d0d0d0",
                                        borderRadius: "4px",
                                        width: "100%",
                                    }}></Input>
                            </InputGroup>
                            <Button 
                                onMouseDown={handleSubmitPasswordResetForm}
                                style={{
                                    backgroundColor: "#05b96b",
                                    borderRadius: "4px",
                                    color: "white",
                                    fontSize: "0.9em",
                                    margin: "25px 0px 0px 0px",
                                    width: "100%",
                                }}
                            >
                                Send Email
                            </Button>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "end",
                        margin: "15px 0px 0px 0px",
                    }}>
                        <p style={{
                            fontSize: "0.8em",
                        }}>
                            Don't have an account?
                        </p>
                        <p 
                            onMouseDown={() => window.location.href="/auth/signup"}
                            style={{
                                color: "grey",
                                cursor: "pointer",
                                fontSize: "0.9em",
                                margin: "0px 0px 0px 5px",
                                textDecoration: "underline",
                            }}
                        >
                            Sign Up
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="password-reset-form-page">
            <NavigationBar/>
            {
                isEmailSent ?
                <></> :
                <PasswordResetForm/>
            }
        </div>
    );
}

export default PasswordResetPage;