import React, { useState } from 'react';
import {
    Button,
    IconButton,
    InputGroup,
    InputLeftElement,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    Tooltip,
} from '@chakra-ui/react'

import { CREDIT_SCORE } from '../../../utils/constants.js';

import { MdEdit } from 'react-icons/md';
import { FaCloudscale } from "react-icons/fa6";

import './CreditScorePanel.css';
import '../../Generic.css';

function CreditScorePanel(props) {

    const buyer = props.buyer;
    const handleUpdateUserProfile = props.handleUpdateUserProfile;

    const [buyerForm, setBuyerForm] = useState([]);
    const [editCreditScore, setEditCreditScore] = useState(false);

    const EditCreditScoreModal = () => {

        const [creditScoreError, setCreditScoreError] = useState(null);

        /* 
         * checkAndAlertProfileFormErrors validates the credit score the user
         * is attempting to input.
         * 
         * Validations:
         *   a. Maximum score is 850
         *   b. Minimum score is 350
         */
        const checkAndAlertProfileFormErrors = () => {
            if (!buyerForm || !buyerForm[CREDIT_SCORE]) return true;

            // Check credit score validity.
            if (parseInt(buyerForm[CREDIT_SCORE]) > 850){
                setCreditScoreError("Maximum credit score is 850");
                return true;
            } else if (parseInt(buyerForm[CREDIT_SCORE]) < 350) {
                setCreditScoreError("Minimum credit score is 350");
                return true;
            }
            return false;
        }

        return (
            <Modal size='sm' isOpen={editCreditScore} onClose={() => setEditCreditScore(false)} isCentered>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader className="modal_header__default">Edit Credit Score</ModalHeader>
                    <ModalCloseButton 
                        onClose={() => setEditCreditScore(false)} 
                        onMouseDown={() => setEditCreditScore(false)}
                    />
                    <ModalBody className="modal_body__default">
                        <p className="credit_score_panel__modal_subtext">
                            I understand this is for modeling purposes only and this action does not have an effect on my real credit score.
                        </p>
                        <Tooltip
                            bg='red'
                            hasArrow
                            label={creditScoreError}
                            isOpen={creditScoreError !== null}
                            placement='top-end'
                        >
                            <InputGroup className="credit_score_panel__input_group">
                                <InputLeftElement pointerEvents='none'>
                                    <FaCloudscale color='grey'/>
                                </InputLeftElement>
                                <Input
                                    type='number'
                                    placeholder={buyer && buyer[CREDIT_SCORE] ? buyer[CREDIT_SCORE] : "N/A"}
                                    name={CREDIT_SCORE}
                                    onChange={(e) => {
                                        // Reset our error.
                                        setCreditScoreError(null);
                                        buyerForm[CREDIT_SCORE] = parseInt(e.target.value);
                                        setBuyerForm(buyerForm);
                                    }}
                                    maxLength="3"
                                    size='md'
                                    max={850}
                                    min={350}
                                    className="default_input"
                                />
                            </InputGroup>
                        </Tooltip>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="positive_button hover" 
                            onMouseDown={() => {
                                if (Object.keys(buyerForm).length === 0) return;
                                if (checkAndAlertProfileFormErrors()) return;
                                handleUpdateUserProfile({...buyerForm}, "Saved", "Your changes were saved", () => {
                                    setEditCreditScore(false);
                                    setBuyerForm([]);
                                })
                            }}
                            size='sm'
                        >
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    }

    return (
        <div className="credit_score__panel">
            <div style={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                <p style={{
                    fontWeight: "bold"
                }}>
                    Credit Score
                </p>
                <IconButton
                    className="edit_profile_button"
                    icon={<MdEdit/>}
                    isRound
                    onMouseDown={() => setEditCreditScore(true)}
                    size='sm'
                    style={{
                        backgroundColor: "#05b96b",
                        color: "white",
                        cursor: "pointer",
                        transition: "0.3s",
                    }} 
                />
            </div>
            <div className="credit_score_panel__inner_panel">
                <div className="credit_score_panel__credit_score">
                    <p className="credit_score_panel__credit_score_title">
                        {buyer && buyer[CREDIT_SCORE] ? buyer[CREDIT_SCORE] : "N/A"}
                    </p>
                </div>
                <div style={{
                    backgroundColor: "white",
                    borderRadius: "25px",
                    flexGrow: "1",
                    float: "right",
                    margin: "12.5px 0px 0px 20px",
                    height: "17.5px",
                }}>
                    <div style={{
                        backgroundColor: "#05b96b",
                        borderRadius: "25px",
                        height: "17.5px",
                        width: buyer && buyer[CREDIT_SCORE] ? parseInt(buyer[CREDIT_SCORE]) * 100.0 / 850 + "%" : 0
                    }}>
                    </div>
                </div>
            </div>
            <EditCreditScoreModal/>
        </div>
    );
}

export default CreditScorePanel;