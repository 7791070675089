import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { DEFAULT_POST_TIMEOUT, POST } from '../utils/constants';

import ExternalNavigationBar from './ExternalNavigationBar';

import './AccountVerificationPage.css';

function AccountVerificationPage(props) {

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get("email")
    const token = searchParams.get("token");
    const [successfullyVerified, setSuccessfullyVerified] = useState(false);
    const [tokenExpired, setTokenExpired] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
 
    if (!token) {
        navigate('/');
    }

    useEffect(() => {
        if (!token) return;
        axios({
            method: POST,
            url: '/api/v1/account-verification/' + token,
            timeout: DEFAULT_POST_TIMEOUT
        }).then(response => {
            setSuccessfullyVerified(true);
            setIsLoading(false);
        }).catch(error => {
            if (error.response.status === 401) {
                if (error.response.data.includes("expired")){
                    setTokenExpired(true);
                }
            }
            setIsLoading(false);
        })
    }, [token])

    const TokenPostVerification = () => {

        let titleText = "";
        if (successfullyVerified) {
            titleText = "We've successfully verified your account. You're all set!";
        } else if (tokenExpired) {
            titleText = "Your link has expired and we were unable to verify your account. Please request a new verification email from your account.";
        } else {
            titleText = "We were unable to verify your account. Please try again by refreshing the page or request a new verification email from your account."
        }

        return (
            <div className="account_verification_page__box">
                <p className="account_verification_page__title">{titleText}</p>
                <div 
                    onMouseDown={() => navigate('/')}
                    style={{
                        cursor: "pointer",
                        margin: "15px 0px 0px 0px",
                    }}
                >
                    <p className="account_verification_page__subtitle">
                        Take me Home
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div>
            <ExternalNavigationBar/>
            {
                isLoading ?
                <></> :
                <TokenPostVerification/>
            }
        </div>
    );
}
export default AccountVerificationPage;