import React, { useState } from 'react';

import axios from 'axios';

import {
    ADDRESS_CITY,
    ADDRESS_LINE_ONE,
    ADDRESS_LINE_TWO,
    ADDRESS_STATE,
    ADDRESS_ZIP_CODE,
    DELETE,
    ID,
    PATCH,
    PURCHASE_PRICE,
    REPRESENTATION,
    TRANSACTION_DATE,
    DEFAULT_PATCH_TIMEOUT,
    DEFAULT_DELETE_TIMEOUT
} from '../../../utils/constants.js';

import {
    InputGroup,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Input,
    Select,
    ModalFooter,
    Button,
    useToast
} from '@chakra-ui/react';
import { FaTrashAlt } from 'react-icons/fa';

import { dollarfyPrice } from '../../../utils/utility.js';

function EditTransactionModal(props) {

    const transaction = props.transaction;
    const userId = props.userId;

    const closeEditTransactionModel = props.closeEditTransactionModel;
    const triggerFetchTransactions = props.triggerFetchTransactions;

    const [transactionForm, setTransasctionForm] = useState([]);
    const [editTransactionLoading, setEditTransactionLoading] = useState(false);

    const toast = useToast();

    const handleDeleteTransaction = () => {

        axios({
            method: DELETE,
            url: '/api/v1/user/agent/transaction/' + userId + '/' + transaction[ID],
            timeout: DEFAULT_DELETE_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                toast({
                    title: 'Deleted transaction',
                    description: "Transaction successfully deleted",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
                triggerFetchTransactions();
                closeEditTransactionModel();
            }
        }).catch(error => {
            toast({
                title: 'Unable to delete transaction',
                description: "We encountered an error while deleting your transaction. Please try again later",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
            closeEditTransactionModel();
        })
    }

    const handleEditTransactionFormSubmit = (data) => {

        setEditTransactionLoading(true);

        axios({
            method: PATCH,
            url: '/api/v1/user/agent/transaction/' + userId + '/' + transaction[ID],
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: data
        }).then(response => {
            // Successful response. Close the modal and load the property. Also
            // reset our form.
            if (response.status === 200) {
                toast({
                    title: 'Success',
                    description: "Transaction edited added",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
                closeEditTransactionModel();
                triggerFetchTransactions();
                setEditTransactionLoading(false);
            }
        }).catch(error => {
            setEditTransactionLoading(false);
        })
    }

    const handleAddTransactionDollarFormChange = (event) => {
        event.target.value = event.target.value.replace(/[^0-9 \,]/, '');
        event.target.value = dollarfyPrice(event.target.value.replace(/,/g, ''));
        // TODO: Does not support currencies that uses commas as decimal point.
        transactionForm[event.target.name] = parseInt(event.target.value.replace(',', ''));
        return;
    }

    const handleAddTransactionFormChange = (event) => {
        if (event.target.name === TRANSACTION_DATE) {
            transactionForm[event.target.name] = new Date(event.target.value);
        } else {
            transactionForm[event.target.name] = event.target.value;
        }
    }

    return (
        <Modal 
            isOpen={transaction !== null}
            onClose={() => closeEditTransactionModel()}
            isCentered
            size='sm'
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader style={{
                    paddingBottom: "0px"
                }}>Edit Transaction</ModalHeader>
                <ModalCloseButton
                    onClose={() => closeEditTransactionModel()}
                    onMouseDown={() => closeEditTransactionModel()}
                />
                <ModalBody style={{
                    paddingTop: "0px"
                }}>
                    <div>
                        <p style={{
                            color: "grey",
                            fontSize: "0.6em",
                            margin: "5px 0px 5px 0px",
                        }}>
                            ADDRESS
                        </p>
                        <Input 
                            size='sm'
                            className="default_input"
                            onChange={handleAddTransactionFormChange} name={ADDRESS_LINE_ONE} placeholder={transaction[ADDRESS_LINE_ONE]}>
                        </Input>
                    </div>
                    <div style={{
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                            <div style={{
                                width: "calc(100%)"
                            }}>
                                <p style={{
                                    color: "grey",
                                    fontSize: "0.6em",
                                    margin: "0px 0px 5px 0px",
                                }}>
                                    ADDRESS LINE TWO (Optional)
                                </p>
                                <InputGroup style={{
                                }}>
                                    <Input 
                                        size='sm'
                                        className="default_input"
                                        onChange={handleAddTransactionFormChange} name={ADDRESS_LINE_TWO}
                                        placeholder={transaction[ADDRESS_LINE_TWO]}>
                                    </Input>
                                </InputGroup>
                            </div>
                            
                        </div>
                    </div>
                    <div style={{
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}>
                            <div style={{
                                width: "calc(44% - 5px)"
                            }}>
                                <p style={{
                                    color: "grey",
                                    fontSize: "0.6em",
                                    margin: "0px 0px 5px 0px",
                                }}>
                                    CITY
                                </p>
                                <InputGroup>
                                    <Input 
                                        size='sm'
                                        className="default_input"
                                        onChange={handleAddTransactionFormChange}
                                        name={ADDRESS_CITY}
                                        placeholder={transaction[ADDRESS_CITY]}
                                    />
                                </InputGroup>
                            </div>
                            <div style={{
                                width: "calc((23% - 5px)",
                            }}>
                                <p style={{
                                    color: "grey",
                                    fontSize: "0.6em",
                                    margin: "0px 0px 5px 0px",
                                }}>
                                    STATE
                                </p>
                                <InputGroup>
                                    <Select 
                                        size='sm'
                                        className="default_input"
                                        placeholder={transaction[ADDRESS_STATE]}
                                        onChange={handleAddTransactionFormChange} name={ADDRESS_STATE} style={{
                                    }}>
                                        <option hidden>-</option>
                                        <option value="AL">AL</option>
                                        <option value="AK">AK</option>
                                        <option value="AR">AR</option>
                                        <option value="AZ">AZ</option>
                                        <option value="CA">CA</option>
                                        <option value="CO">CO</option>
                                        <option value="CT">CT</option>
                                        <option value="DC">DC</option>
                                        <option value="DE">DE</option>
                                        <option value="FL">FL</option>
                                        <option value="GA">GA</option>
                                        <option value="HI">HI</option>
                                        <option value="IA">IA</option>
                                        <option value="ID">ID</option>
                                        <option value="IL">IL</option>
                                        <option value="IN">IN</option>
                                        <option value="KS">KS</option>
                                        <option value="KY">KY</option>
                                        <option value="LA">LA</option>
                                        <option value="MA">MA</option>
                                        <option value="MD">MD</option>
                                        <option value="ME">ME</option>
                                        <option value="MI">MI</option>
                                        <option value="MN">MN</option>
                                        <option value="MO">MO</option>
                                        <option value="MS">MS</option>
                                        <option value="MT">MT</option>
                                        <option value="NC">NC</option>
                                        <option value="NE">NE</option>
                                        <option value="NH">NH</option>
                                        <option value="NJ">NJ</option>
                                        <option value="NM">NM</option>
                                        <option value="NV">NV</option>
                                        <option value="NY">NY</option>
                                        <option value="ND">ND</option>
                                        <option value="OH">OH</option>
                                        <option value="OK">OK</option>
                                        <option value="OR">OR</option>
                                        <option value="PA">PA</option>
                                        <option value="RI">RI</option>
                                        <option value="SC">SC</option>
                                        <option value="SD">SD</option>
                                        <option value="TN">TN</option>
                                        <option value="TX">TX</option>
                                        <option value="UT">UT</option>
                                        <option value="VT">VT</option>
                                        <option value="VA">VA</option>
                                        <option value="WA">WA</option>
                                        <option value="WI">WI</option>
                                        <option value="WV">WV</option>
                                        <option value="WY">WY</option>
                                    </Select>
                                </InputGroup>
                            </div>
                            <div style={{
                                width: "calc(33% - 5px)",
                            }}>
                                <p style={{
                                    color: "grey",
                                    fontSize: "0.6em",
                                    margin: "0px 0px 5px 0px",
                                }}>
                                    ZIP CODE
                                </p>
                                <InputGroup>
                                    <Input
                                        size='sm'
                                        className="default_input"
                                        onChange={handleAddTransactionFormChange} name={ADDRESS_ZIP_CODE}
                                        placeholder={transaction[ADDRESS_ZIP_CODE]} style={{
                                        }
                                    }/>
                                </InputGroup>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        margin: "10px 0px 0px 0px",
                    }}>
                        <p style={{
                            color: "grey",
                            fontSize: "0.6em",
                            margin: "0px 0px 5px 0px",
                        }}>
                            DATE OF CLOSING
                        </p>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                            <Input
                                max='9999-12-31'
                                size='sm'
                                type='date'
                                className="default_input"
                                defaultValue={transaction[TRANSACTION_DATE].split("T")[0]}
                                onChange={handleAddTransactionFormChange}
                                name={TRANSACTION_DATE}/>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(60% - 5px)"
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                            }}>
                                PURCHASE PRICE
                            </p>
                            <InputGroup>
                                <Input
                                    size='sm'
                                    className="default_input"
                                    onChange={handleAddTransactionDollarFormChange}
                                    name={PURCHASE_PRICE}
                                    type='text'
                                    placeholder={"$" + dollarfyPrice(transaction[PURCHASE_PRICE])}>
                                </Input>
                            </InputGroup>
                        </div>
                        <div style={{
                            width: "calc(40% - 5px)"
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.6em",
                                margin: "0px 0px 5px 0px",
                            }}>
                                REPRESENTED
                            </p>
                            <Select
                                size='sm'
                                className="default_input"
                                onChange={handleAddTransactionFormChange} name={REPRESENTATION}
                            >
                                <option selected={transaction[REPRESENTATION] === "Buyer"} value='Buyer'>Buyer</option>
                                <option selected={transaction[REPRESENTATION] === "Seller"} value='Seller'>Seller</option>
                                <option selected={transaction[REPRESENTATION] === "Both"} value='Both'>Both</option>
                            </Select>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{
                    margin: "-5px 0px 5px 0px",
                }}>
                    <Button
                        leftIcon={<FaTrashAlt/>}
                        className="negative_grey_button hover"
                        onMouseDown={() => handleDeleteTransaction()}
                        mr={3}
                        size='sm'
                    >
                        Delete
                    </Button>
                    <Button 
                        className="positive_button hover"
                        onMouseDown={() => handleEditTransactionFormSubmit({...transactionForm})}
                        size='sm'>
                        {
                            editTransactionLoading ?
                            <l-dot-pulse
                                size="25"
                                speed="1.25" 
                                color="white" 
                            ></l-dot-pulse> :
                            <>Save</>
                        }
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default EditTransactionModal;