import { useLocation, useNavigate } from "react-router-dom";
import useAuth from './useAuth';
import { BOTH, BUYER, USER_TYPE } from "../../utils/constants";

function RequireAuth({ children }) {

    const navigate = useNavigate();
    
    const { authedUser, autoLogin } = useAuth();
    const location = useLocation();

    // This logic relies on the last path being the user id.
    let locationSpl = (location.pathname).split('/');
    let locationUserId = locationSpl && locationSpl.length > 0 ? locationSpl[locationSpl.length - 1] : null;
    if (authedUser) return children;

    autoLogin().then((aUser) => {        
        // If user is authed, return the children for them.
        if (aUser) {
            if (locationUserId === aUser.id) {
                return children;
            } else {
                // Send them to their own version of the page.
                if (aUser[USER_TYPE] === BUYER || aUser[USER_TYPE] === BOTH) {
                    navigate('/user/buyer/dashboard/homes/' + aUser.id);
                    return;
                } else {
                    navigate('/user/agent/dashboard/leads/' + aUser.id);
                    return;
                }
            }
        }
        // If user is not authed, send them to the login page.
        navigate('/auth/login');
        return;
    });
}

export default RequireAuth;