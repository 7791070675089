import React from 'react';

import { tailspin } from 'ldrs'

function Loader(props) {

    tailspin.register()

    return (
        <l-tailspin
            size="60"
            stroke="7"
            speed="0.9" 
            color="#05b96b" 
        ></l-tailspin>
    );
}

export default Loader;
