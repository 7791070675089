import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Radar from 'radar-sdk-js';

import {
    Center,
    InputGroup,
    InputLeftElement,
    Input,
    useToast
} from '@chakra-ui/react'

import { FaMapLocationDot } from "react-icons/fa6";
import { MdClose } from 'react-icons/md';
import { Search2Icon } from '@chakra-ui/icons';

import {
    DELETE,
    GET,
    ID,
    POST,
    DEFAULT_DELETE_TIMEOUT,
    DEFAULT_GET_TIMEOUT,
    DEFAULT_POST_TIMEOUT,
} from '../../../utils/constants.js';

import 'radar-sdk-js/dist/radar.css';

import IntroPanel from './IntroPanel.js';
import CreditScorePanel from './CreditScorePanel.js';

function InterestedAreas(props) {

    const user = props.user;
    const triggerFetchInterestedAreasCallback = props.triggerFetchInterestedAreasCallback;
    const [inputValue, setInputValue] = useState('');
    const [interestedAreasAutocompleteAddresses, setInterestedAreasAutocompleteAddresses] = useState(null);
    const [selectedInterestAreaTab, setSelectedInterestAreaTab] = useState(null);


    const toast = useToast();

    const handleInterestedAreasInputChange = (e) => {
        let val = e.target.value;
        setInputValue(val);
        if (val.length <= 2) {
            setInterestedAreasAutocompleteAddresses(null);
            return;
        }
        Radar.autocomplete({
            query: val,
            limit: 10,
            country: 'US',
        }).then(response => {
            // We want to let users search for the following.
            // 1. USA addresses only
            // 2. Locality's
            // 3. Zip Codes
            let addresses = response.addresses.filter(e => 
                e["formattedAddress"].includes("USA") 
                // && (
                // e["layer"] === "locality" || 
                // (e["postalCode"] !== undefined && e["postalCode"] !== null && e["postalCode"].includes(val)))
            );
            setInterestedAreasAutocompleteAddresses(addresses);
        }).catch(error => {
            // TODO
        });
    }

    const handleAddInterestedArea = (address) => {
        axios({
            method: POST,
            url: '/api/v1/user/buyer/interested_area/' + user[ID],
            timeout: DEFAULT_POST_TIMEOUT,
            data: {
                buyer_id: user[ID],
                coordinates: {
                    longitude: parseFloat(address["longitude"]),
                    latitude: parseFloat(address["latitude"]),
                },
                formatted_address: address["formattedAddress"],
                city: address["city"],
                state: address["state"],
                country: address["country"],
                country_code: address["countryCode"],
            },
        }).then(response => {
            if (200 === response.status) {
                setSelectedInterestAreaTab(null);
                setInterestedAreasAutocompleteAddresses(null);
                setInputValue("");
                triggerFetchInterestedAreasCallback();
            }
        }).catch(error => {
            if (409 === error.response.status) {
                setInterestedAreasAutocompleteAddresses(null);
                setInputValue("");
                toast({
                    title: 'Duplicate area of interest',
                    description: "You've already marked that area as interested",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
        })
    }

    const handleDeleteInterestedArea = (areaId) => {

        axios({
            method: DELETE,
            url: '/api/v1/user/buyer/interested_area/' + user[ID] + '/' + areaId,
            timeout: DEFAULT_DELETE_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                setSelectedInterestAreaTab(null);
                toast({
                    title: 'Deleted area of interest',
                    description: "We've deleted your area of interest",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
                triggerFetchInterestedAreasCallback();
            }
        }).catch(error => {
            toast({
                title: 'Unable to delete your area of interest',
                description: "Please try again or contact us",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
        })
    }

    const InterestedAreaTabs = () => {

        if (!props.buyerInterestedAreas || props.buyerInterestedAreas.length === 0) {
            return(
                <div>
                    <p style={{
                        color: "grey",
                        fontSize: "0.8em",
                        margin: "15px 0px 0px 0px",
                        textAlign: "center",
                    }}>
                        No Interested Areas yet
                    </p>
                </div>
            )
        }

        let renderedTabs = [];
        for (let i = 0; i < props.buyerInterestedAreas.length; i++) {
            renderedTabs.push(
                <div key={props.buyerInterestedAreas[i][ID] + "_tab"} className="interested_area__areas_tab" 
                    onMouseDown={() => {
                        setSelectedInterestAreaTab(props.buyerInterestedAreas[i])
                    }}
                    style={{
                        backgroundColor: selectedInterestAreaTab && selectedInterestAreaTab[ID] === props.buyerInterestedAreas[i][ID] ? "#05b96b" : "#efefef",
                        color: selectedInterestAreaTab && selectedInterestAreaTab[ID] === props.buyerInterestedAreas[i][ID] ? "white" : "black",
                    }}
                >
                    <p className="interested_area__areas_text">
                        {props.buyerInterestedAreas[i]["formatted_address"]}
                    </p>
                    <MdClose className="interested_area__areas_icon" onMouseDown={(e) => {
                        e.stopPropagation();
                        handleDeleteInterestedArea(props.buyerInterestedAreas[i][ID]);
                    }}/>
                </div>
            );
        }
        return (
            <div className="rendered_tabs__container" style={{
                margin: "5px 0px 0px 0px",
                overflowY: "scroll",
            }}>
                {renderedTabs}
            </div>
        );
    }
    
    const renderInterestedAreasAutocompleteAddresses = () => {

        let renderedAddresses = [];

        for (let i = 0; i < interestedAreasAutocompleteAddresses.length; i++) {
            let formattedAddress = interestedAreasAutocompleteAddresses[i]["formattedAddress"];
            renderedAddresses.push(
                <div key={i + "" + formattedAddress} className="autocomplete__interested_address"
                    onMouseDown={() => handleAddInterestedArea(interestedAreasAutocompleteAddresses[i])}
                style={{
                    cursor: "pointer",
                    padding: "7.5px 10px 7.5px 10px",
                }}>
                    <p style={{
                        fontSize: "0.9em"
                    }}>
                        {formattedAddress}
                    </p>
                </div>
            )
        }
        
        return (
            <div style={{
                backgroundColor: "white",
                border: "1px solid #cccccc",
                borderRadius: "4px",
                position: "absolute",
                width: "100%",
            }}>
                {renderedAddresses}
            </div>
        )
    }

    useEffect(() => {

        if (!props.buyerInterestedAreas || props.buyerInterestedAreas.length === 0) return;

        const selectedExists = selectedInterestAreaTab ?
            props.buyerInterestedAreas.filter(e => e[ID] === selectedInterestAreaTab[ID]).length > 0 :
            false;

        let areaToShow;
        if (selectedInterestAreaTab && selectedExists) {
            areaToShow = selectedInterestAreaTab;
        } else if (props.buyerInterestedAreas.length > 0) {
            areaToShow = props.buyerInterestedAreas[0];
            setSelectedInterestAreaTab(areaToShow);
        } else {
            return;
        }
    
        let longitude = parseFloat(areaToShow["coordinates"]["longitude"]);
        let latitude = parseFloat(areaToShow["coordinates"]["latitude"]);

        const map = new Radar.ui.map({
            container: 'map',
            style: 'radar-default-v1',
            center: [longitude, latitude], // NYC
            zoom: 14,
        });

        // add a marker to the map
        Radar.ui.marker({ text: 'Marker' })
            .setLngLat([longitude, latitude])
            .addTo(map);
    }, [selectedInterestAreaTab, props.buyerInterestedAreas])

    return (
        <div style={{
            backgroundColor: "#f4f4f4",
            borderRadius: "12px",
            margin: "20px 0px 0px 0px",
            padding: "15px 25px 15px 25px",
        }}>
            <p style={{
                fontWeight: "bold"
            }}>
                Interested Areas
            </p>
            <div style={{
                display: "flex",
                margin: "15px 0px 0px 0px",
            }}>
                <div>
                    <div style={{
                        display: "flex",
                    }}>
                        <div style={{
                            position: "relative",
                            width: "250px",
                        }}>
                            <div style={{
                                margin: "0px 0px 0px 0px",
                                width: "100%",
                            }}>
                                <InputGroup>
                                    <InputLeftElement pointerEvents='none'>
                                        <Search2Icon color='gray' style={{
                                            margin: "-7.5px 0px 0px 0px"
                                        }}/>
                                    </InputLeftElement>
                                    <Input value={inputValue} onChange={handleInterestedAreasInputChange} placeholder="Search for an area or zip code" size='sm' style={{
                                        backgroundColor: "white",
                                        border: "none",
                                        // border: "1px solid #cccccc",
                                        borderRadius: "12px",
                                        width: "100%",
                                    }}/>
                                </InputGroup>
                            </div>
                            {
                                interestedAreasAutocompleteAddresses && interestedAreasAutocompleteAddresses.length > 0 ?
                                <div style={{
                                    position: "relative",
                                    width: "100%"
                                }}>
                                    {renderInterestedAreasAutocompleteAddresses()}
                                </div> : null
                            }
                            <div style={{
                                height: "10px",
                                margin: "5px 0px 5px 0px"
                            }}>
                                <p style={{
                                    color: "red",
                                    fontSize: "0.7em",
                                }}>
                                    {
                                        inputValue && inputValue.length > 0 && inputValue.length <= 2 ? 
                                        "Please enter at least two characters" : ""
                                    }
                                </p>
                            </div>
                            <div className="clearfix"/>
                            <label style={{
                                color: "grey",
                                fontSize: "0.7em"
                            }}>
                                INTERESTED AREAS
                            </label>
                            <InterestedAreaTabs/>
                        </div>
                    </div>
                </div>
                {
                    props.buyerInterestedAreas !== null && props.buyerInterestedAreas !== undefined && props.buyerInterestedAreas.length > 0 ?
                    <div id="map-container" style={{ 
                        borderRadius: "8px",
                        height: '350px',
                        margin: "0px 0px 0px 30px",
                        width: 'calc(100% - 30px)'
                    }}>
                        <div id="map" style={{ 
                            borderRadius: "8px",
                            height: 'inherit',
                            width: '100%'
                        }}/>
                    </div> : 
                    <div style={{
                        backgroundColor: "#f0f0f0",
                        borderRadius: "8px",
                        margin: "0px 0px 0px 30px",
                        width: 'calc(100% - 30px)'
                    }}>
                        <Center>
                            <FaMapLocationDot style={{
                                color: "grey",
                                height: "30px",
                                position: "absolute",
                                width: "30px",
                            }}/>
                            <p style={{
                                color: "grey",
                                margin: "5px 0px 0px 0px",
                                position: "absolute",
                                top: "50%",
                            }}>
                                No Interested Areas
                            </p>
                        </Center>
                    </div>
                }
            </div>
        </div>
    )
}

function AboutPanel(props) {

    const user = props.user;
    const buyer = props.buyer;
    const handleUpdateUserProfile = props.handleUpdateUserProfile;
    
    const [buyerInterestedAreas, setBuyerInterestedAreas] = useState(null);
    const [triggerFetchInterestedAreas, setTriggerFetchInterestedAreas] = useState(false);

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/buyer/interested_areas/' + user[ID],
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            let interested_areas = response.data;
            setBuyerInterestedAreas(interested_areas);
        }).catch(error => {
            // TODO
        });
    }, [user, triggerFetchInterestedAreas]);

    return (
        <div>
            <IntroPanel buyer={buyer} handleUpdateUserProfile={handleUpdateUserProfile} user={user}/>
            <div style={{
                borderRadius: "12px",
                float: "right",
                flexGrow: "1",
                height: "calc(100vh - 300px)",
                overflowY: "scroll",
                width: "calc(100% - 450px - 20px)"
            }}>
                <CreditScorePanel buyer={buyer} handleUpdateUserProfile={handleUpdateUserProfile}>
                </CreditScorePanel>
                <InterestedAreas
                    buyerInterestedAreas={buyerInterestedAreas}
                    triggerFetchInterestedAreasCallback={() => setTriggerFetchInterestedAreas(!triggerFetchInterestedAreas)}
                    user={user}
                />
            </div>
        </div>
    );
}

export default AboutPanel;