import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { axiosGetUser, axiosGetAgent } from '../../common/methods/methods.js';
import { checkRequireAgentSignUp } from '../../../utils/utility.js';
import {
    Button,
    Center,
    Input,
    InputLeftElement,
    InputGroup,
    Select,
    useToast,
} from '@chakra-ui/react';

import { Search2Icon, WarningIcon } from '@chakra-ui/icons';
import { FaBolt, FaSliders, FaUsers } from "react-icons/fa6";
import { MdArrowDropDown } from "react-icons/md";

import {
    ID,
    GET,
    LEADS,
    POST,
    NOT_INTERESTED_BY_AGENT,
    DEFAULT_POST_TIMEOUT,
    CONNECTION_REQUEST_BY_BUYER_ACCEPTED,
    CONNECTION_REQUEST_BY_AGENT_CANCELED,
    NOT_INTERESTED_BY_AGENT_REMOVED,
    DEFAULT_GET_TIMEOUT,
    IS_VERIFIED,
} from '../../../utils/constants.js';

import UserCard from './UserCard.js';
import NoObjectsDisplay from '../../common/NoObjectsDisplay.js';
import AgentDashboardParentPage from '../utils/AgentDashboardParentPage.js';
import ContactLeadModal from './ContactLeadModal.js';
import MainPanel from '../../common/MainPanel.js';
import SidePanel from './SidePanel.js';

import { hatch, dotPulse, newtonsCradle, spiral } from 'ldrs'

import './Page.css';
import '../../Generic.css';
import DefaultOfferModal from './DefaultOfferModal.js';
import AgentSignUpInformationModal from '../utils/AgentSignUpInformationModal.js';
import Loader from '../../common/Settings/Loader.js';

function AgentLeadsDashboardPage(props) {

    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);

    const [showContactLeadModal, setShowContactLeadModal] = useState(false);
    const [showDefaultOfferModal, setShowDefaultOfferModal] = useState(false);

    const [leads, setLeads] = useState([]);
    const [leadsLoading, setLeadsLoading] = useState(true);
    const [defaultOffer, setDefaultOffer] = useState(null);
    const [modalLead, setModalLead] = useState(null);

    const [connectionsPanelConnection, setConnectionsPanelConnection] = useState(null);

    const [triggerFetchLeads, setTriggerFetchLeads] = useState(false);
    const [triggerFetchConnections, setTriggerFetchConnections] = useState(false);
    const [triggerFetchDefaultOffer, setTriggerFetchDefaultOffer] = useState(false);
    const [defaultOfferForm, setDefaultOfferForm] = useState([]);

    const [finishSignUpFormAgent, setFinishSignUpFormAgent] = useState(null);
    const [finishSignUpFormLoading, setFinishSignUpFormLoading] = useState(true);

    const [defaultOffersLoading, setDefaultOffersLoading] = useState(true);

    const toast = useToast();

    dotPulse.register();
    hatch.register();
    newtonsCradle.register();
    spiral.register();

    const urlParams = useParams();
    const userId = urlParams[ID];

    useEffect(() => {
        axiosGetUser(userId).then(user => {
            if (user) {
                setUser(user);
                setUserLoading(false);
            }
        }).catch(error => {
            // TODO
        });
    }, [userId]);

    useEffect(() => {
        axiosGetAgent(userId).then(agent => {
            if (agent) {
                if (checkRequireAgentSignUp(agent) === true) {
                    setFinishSignUpFormAgent(agent);
                };
            }
            setFinishSignUpFormLoading(false);
        }).catch(error => {
            // TODO
            setFinishSignUpFormLoading(false);
        });
    }, [])
    
    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/agent/leads/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                setLeads(response.data);
                setLeadsLoading(false);
            }
        }).catch(error => {
            // TODO
            setLeadsLoading(false);
            // TODO: Handle our error codes.
        });
    }, [userId, triggerFetchLeads]);

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/agent/offer/default/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            let defaultOffer = response.data;
            setDefaultOffer(defaultOffer);
            setDefaultOfferForm(defaultOffer);
            setDefaultOffersLoading(false);
        }).catch(error => {
            setDefaultOffersLoading(false);
        })
    }, [userId, triggerFetchDefaultOffer]);

    const handleResendVerificationEmail = () => {

        axios({
            method: POST,
            url: '/api/v1/account-verification/resend/' + userId,
            timeout: DEFAULT_POST_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                toast({
                    title: "Account verification email resent",
                    description: "We've re-sent your account verification email. Do not share your link with anyone else.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
        }).catch(error => {
            if (500 === error.response.status) {
                toast({
                    title: "Internal Server Error",
                    description: "We've encountered an error sending out a new account verification email. Please try again in 1 minute",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                })
            } else if (429 === error.response.status) {
                toast({
                    title: "Too many requests",
                    description: "Please wait 1 minute between requesting a new account verification email",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                })
            }
        })
    }

    const handleAddConnection = (receiverId, status) => {

        axios({
            method: POST,
            url: '/api/v1/user/connection/' + userId + '?user_type=Agent',
            timeout: DEFAULT_POST_TIMEOUT,
            data: {
                buyer_id: receiverId,
                agent_id: userId,
                statuses: [{status: status}]
            }
        }).then(response => {
            if (200 === response.status) {
                let title;
                let description;
                switch(status) {
                    case CONNECTION_REQUEST_BY_AGENT_CANCELED:
                        title = 'Request Canceled';
                        description = 'Successfully canceled your request.';
                        break;
                    case CONNECTION_REQUEST_BY_BUYER_ACCEPTED:
                        title = 'Request Accepted';
                        description = 'You just made a new connection!';
                        break;
                    case NOT_INTERESTED_BY_AGENT:
                        title = "Marked as not interested";
                        description = "You marked this user as not interested. You won't see them again in your feed.";
                        break;
                    case NOT_INTERESTED_BY_AGENT_REMOVED:
                        title = "This user is now unhidden";
                        description = "You'll start seeing this user in your feed again.";
                        break;
                    default:
                }
                setTriggerFetchConnections(!triggerFetchConnections);
                setTriggerFetchLeads(!triggerFetchLeads);
                setConnectionsPanelConnection(null);
                toast({
                    title: title,
                    description: description,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                })
            }
        }).catch(error => {
            // TODO
        })
    }

    // const ContactLeadModal = () => {

        

    const Filters = () => {
        return (
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
            }}>
                <div style={{
                    display: "flex",
                }}>
                    <FaSliders style={{
                        height: "15px",
                        margin: "7px 12px 0px 0px",
                        width: "15px",
                    }} />
                    <div>
                        <InputGroup style={{
                            width: "350px",
                        }}>
                            <InputLeftElement size='sm' pointerEvents='none'>
                                <Search2Icon color='gray' style={{
                                    margin: "-7.5px 0px 0px 0px"
                                }}/>
                            </InputLeftElement>
                            <Input
                                className="default_input"
                                size='sm'
                                style={{
                                    margin: "0px 10px 0px 0px",
                                }}
                                placeholder='Search by name'
                            />
                        </InputGroup>
                    </div>
                    <div style={{
                        margin: "0px 10px 0px 0px",
                    }}>
                        <Select
                            className="default_input"
                            icon={<MdArrowDropDown />}
                            margin="0px 10px 0px 0px"
                            size='sm'
                        >
                            <option hidden>Pre-Approved</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </Select>
                    </div>
                    <div>
                        <Select
                            className="default_input"
                            icon={<MdArrowDropDown />}
                            margin="0px 10px 0px 0px"
                            size='sm'
                        >
                            <option hidden>First Time Buyer</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </Select>
                    </div>
                </div>
                <Center>
                    <Button
                        className="hover"
                        leftIcon={<FaBolt/>}
                        onMouseDown={() => {
                            setShowDefaultOfferModal(true);
                        }}
                        size='sm'
                        style={{
                            backgroundColor: "#05b96b",
                            color: "white",
                            borderRadius: "50px",
                        }}
                    >
                        Offer
                    </Button>
                </Center>
            </div>
        );
    }

    const renderLeadCards = () => {

        if (leadsLoading) {
            return (
                <div style={{
                    top: "50%",
                    position: "absolute",
                    right: "50%",
                }}>
                    <l-dot-pulse
                        size="40"
                        stroke="4"
                        speed="1" 
                        color="#5a5a5a" 
                    ></l-dot-pulse>
                </div>
            );
        }

        if (!leads || leads.length === 0) {
            return (
                <NoObjectsDisplay
                    icon={FaUsers} 
                    titleText={'No leads'}
                    subtitleText={"No leads found. Try expanding your search parameters or try again later."}
                    buttonText={"Find Leads"}
                    buttonCallback={() => {
                        window.location.href = "/user/agent/dashboard/leads/" + userId;
                    }}
                />
            );
        }

        let leadCards = [];
        for (let i = 0; i < leads.length; i++) {
            let lead = leads[i];
            leadCards.push(
                <UserCard
                    key={"lead_card_" + i}
                    lead={lead}
                    contactButtonCallback={() => {
                        setModalLead(lead);
                        setShowContactLeadModal(true);
                    }}
                    notInterestedButtonCallback={() => {
                        handleAddConnection(lead[ID], NOT_INTERESTED_BY_AGENT)
                    }}
                />
            );
            if (i !== leads.length - 1) {
                leadCards.push(
                    <div style={{
                        backgroundColor: "#dddddd",
                        height: "1px",
                        width: "100%",
                    }}></div>
                );
            }
        }

        return (
            <div className="lead_user__container" style={{
                backgroundColor: "#ffffff",
                borderRadius: "4px",
                margin: "5px 0px 0px 0px",
                height: "calc(100vh - 50px - 125px)",
                overflow: "scroll"
            }}>
                {leadCards}
            </div>
        );
    }

    if (userLoading) return;

    return (
        <AgentDashboardParentPage user={user} tab={LEADS}>
            {
                finishSignUpFormLoading || leadsLoading || defaultOffersLoading ? 
                    <div style={{
                        margin: "auto",
                    }}>
                        <Loader/>
                    </div> :
                    <>
                        {
                            finishSignUpFormAgent ?
                            <AgentSignUpInformationModal
                                user={finishSignUpFormAgent}
                                setFinishSignUpFormAgent={setFinishSignUpFormAgent}
                            />: null
                        }
                        <MainPanel>
                            <div style={{
                                margin: "10px 0px 0px 0px",
                            }}>
                                {
                                    user && user[IS_VERIFIED] === false ?
                                    <div className="home_page__verify_email_parent">
                                        <WarningIcon style={{
                                            height: "20px",
                                            margin: "auto 10px auto 0px",
                                            width: "20px",
                                        }}/>
                                        <p style={{
                                            lineHeight: "50px",
                                            overflow: "hidden",
                                            textOverflow: "ellipses",
                                        }}>
                                            Please verify your account. Didn't get a verification email?
                                        </p>
                                        <p 
                                            onMouseDown={() => handleResendVerificationEmail()}
                                            style={{
                                                cursor: "pointer",
                                                lineHeight: "50px",
                                                margin: "0px 0px 0px 10px",
                                                textDecoration: "underline",
                                                overflow: "hidden",
                                                textOverflow: "ellipses",
                                            }}
                                        >
                                            Re-send verification email
                                        </p>
                                    </div> :
                                    null
                                }
                                {showContactLeadModal ? 
                                    <ContactLeadModal
                                        userId={userId}
                                        lead={modalLead}
                                        defaultOfferForm={defaultOfferForm}
                                        showContactLeadModal={showContactLeadModal}
                                        setShowContactLeadModal={(show) => setShowContactLeadModal(show)}
                                        setTriggerFetchConnections={() => setTriggerFetchConnections(!triggerFetchConnections)}
                                        setTriggerFetchLeads={() => setTriggerFetchLeads(!triggerFetchLeads)}
                                    /> : 
                                    null
                                }
                                {showDefaultOfferModal ? 
                                    <DefaultOfferModal
                                        defaultOffer={defaultOffer}
                                        defaultOfferForm={defaultOfferForm}
                                        showDefaultOfferModal={showDefaultOfferModal}
                                        setShowDefaultOfferModal={setShowDefaultOfferModal}
                                        setTriggerFetchDefaultOffer={() => setTriggerFetchDefaultOffer(!triggerFetchDefaultOffer)}
                                        triggerFetchDefaultOffer={triggerFetchDefaultOffer}
                                        userId={userId}
                                    /> : null}
                            </div>
                            <Filters/>
                            {renderLeadCards()}
                        </MainPanel>
                        <SidePanel 
                            user={user}
                            triggerFetchConnections={triggerFetchConnections} 
                            triggerFetchConnectionsCallback={() => setTriggerFetchConnections(!triggerFetchConnections)}
                            triggerFetchLeadsCallback={() => setTriggerFetchLeads(!triggerFetchLeads)}
                        />
                    </>
            }
        </AgentDashboardParentPage>
    );
}

export default AgentLeadsDashboardPage;