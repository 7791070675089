import React, { useState } from 'react';
import { Link } from '@chakra-ui/react'

import { HamburgerIcon } from '@chakra-ui/icons';

import { FaRegBookmark, FaQuestion, FaArrowRightToBracket, FaRegCircleUser } from "react-icons/fa6";

import {
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from "@chakra-ui/react";

import './NavigationBar.css';

function NavigationBar(props) {

    const [isUserPage, setIsUserPage] = useState(props.isUserPage);
    const userType = props.userType;

    const UserNavigationBar = () => {
        return (
            <ul className="navigation-bar-links-list">
                <li className="navigation-bar-link">
                    About Us
                </li>
                <Link href="/auth/login" className="navigation-bar-link">
                    Log In
                </Link>
                <Link href={userType ? "/auth/signup?user_type=" + userType : "/auth/signup"} className="navigation-bar-link" style={{
                    backgroundColor: "#05b96b",
                    borderRadius: "4px",
                    color: "white",
                    height: "35px",
                    lineHeight: "35px",
                    fontWeight: "bold",
                    margin: "17.5px 0px 17.5px 25px",
                    padding: "0px 15px 0px 15px",
                    textDecoration: "none",
                }}>
                    Sign Up
                </Link>
                
            </ul>
        );
    }

    const UserHamburgerNavigationBar = () => {
        return (
            <div className="navigation_bar__hamburger_menu_box">
                <Menu className="navigation_bar__hamburger_menu">
                    <MenuButton as={IconButton} style={{
                        backgroundColor: "transparent",
                        margin: "calc((70px - 40px)/2) 0px calc((70px - 40px)/2) 0px",
                        padding: "0px",
                    }}>
                        <HamburgerIcon className="hover navigation_bar__hamburger_menu_icon"/>
                    </MenuButton>
                    <MenuList style={{
                        backgroundColor: "white",
                        border: "1px solid #cccccc",
                        borderRadius: "4px",
                        padding: "0px",
                        width: "100% !important"
                    }}>
                        <Link href="/auth/login" className="navigation-bar-link">
                            <MenuItem className="navigation_bar__hamburger_menu_item" icon={<FaRegBookmark/>}>
                                    About Us
                            </MenuItem>
                        </Link>
                        <div className="navigation_bar__hamburger_menu_divider"/>
                        <Link href="/auth/login" className="navigation-bar-link">
                            <MenuItem className="navigation_bar__hamburger_menu_item" icon={<FaQuestion/>}>
                                    FAQs
                            </MenuItem>
                        </Link>
                        <div className="navigation_bar__hamburger_menu_divider"/>
                        <Link href="/auth/login" className="navigation-bar-link">
                            <MenuItem className="navigation_bar__hamburger_menu_item" icon={<FaRegCircleUser/>}>
                                Log In
                            </MenuItem>
                        </Link>

                        <div className="navigation_bar__hamburger_menu_divider"/>
                        <Link href="/auth/signup" className="navigation-bar-link">
                            <MenuItem className="navigation_bar__hamburger_menu_item" icon={<FaArrowRightToBracket/>}>
                                Sign Up
                            </MenuItem>
                        </Link>
                    </MenuList>
                </Menu>
            </div>
        )
    }

    return (
        <div className="navigation-bar">
            <a href="/" className="navigation-bar-logo-box">
                {/* <SiHomeadvisor className="navigation-bar-logo"/> */}
                {/* <img 
                    className="navigation-bar-logo"
                    src={require('../Images/logo/logo_500_500_transparent.png')} style={{
                    height: "30px",
                    width: "30px",
                }}/> */}
                <p className="navigation-bar-logo-title">rezied</p>
            </a>
            <UserNavigationBar/>
            <UserHamburgerNavigationBar/>
        </div>
    )
}

export default NavigationBar;