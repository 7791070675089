import React from 'react';

import { Button } from '@chakra-ui/react';

import './NoObjectsDisplay.css';

function NoObjectsDisplay(props) {

    // Accepts the following props.
    // Props:
    // - icon
    // - titleText
    // - subtitleText
    // - buttonText
    // - buttonCallback

    return (
        <div className="no_objects_display__parent">
            <props.icon className="no_objects_display__icon"/>
            <p className="no_objects_display__title">
                {props.titleText}
            </p>
            <p className="no_objects_display__subtitle">
                {props.subtitleText}
            </p>
            <Button className="positive_button no_objects_display__button hover" size='sm' onMouseDown={props.buttonCallback} >
                {props.buttonText}
            </Button>
        </div>
    );
}

export default NoObjectsDisplay;