import React, { useEffect, useState } from 'react';
import axios from 'axios';

import Radar from 'radar-sdk-js';

import {
    GET,
    ID,
    DEFAULT_GET_TIMEOUT,
    LATITUDE,
    LONGITUDE,
    COORDINATES,
} from '../../../utils/constants';

import { isValidCoordinates } from '../../../utils/utility.js';
import maplibregl from 'maplibre-gl';

function MyAgentAreasOfExpertise(props) {

    const workingAgent = props.workingAgent;
    const setSelectedAreaOfExpertiseCallback = props.setSelectedAreaOfExpertiseCallback;

    const [workingAgentAreasOfExpertise, setWorkingAgentAreasOfExpertise] = useState(null);
    const [workingAgentAreasOfExpertiseLoading, setWorkingAgentAreasOfExpertiseLoading] = useState(true);

    const [selectedAreaOfExpertise, setSelectedAreaOfExpertise] = useState(null);
    const [selectedAreaOfExpertiseMap, setSelectedAreaOfExpertiseMap] = useState(null);
    const [selectedAreaOfExpertiseMarker, setSelectedAreaOfExpertiseMarker] = useState(null);

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/agent/expertise_areas/' + workingAgent[ID],
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(areasOfExpertiseResponse => {
            if (200 === areasOfExpertiseResponse.status) {
                let areasOfExpertise = areasOfExpertiseResponse.data;
                setWorkingAgentAreasOfExpertise(areasOfExpertise);
                if (areasOfExpertise !== null && areasOfExpertise !== undefined && areasOfExpertise.length > 0) {
                    let selectedAreaOfExpertise = areasOfExpertise[0];
                    let latitude = selectedAreaOfExpertise[COORDINATES][LATITUDE];
                    let longitude = selectedAreaOfExpertise[COORDINATES][LONGITUDE];
                    setSelectedAreaOfExpertise(selectedAreaOfExpertise);
                    setSelectedAreaOfExpertiseCallback(selectedAreaOfExpertise);
                    let map = new Radar.ui.map({
                        container: 'map',
                        style: 'radar-default-v1',
                        center: [longitude, latitude],
                        zoom: 10,
                    });
                    setSelectedAreaOfExpertiseMap(map);
                    const marker = Radar.ui.marker({ text: selectedAreaOfExpertise["formatted_address"] })
                        .setLngLat([longitude, latitude])
                        .addTo(map);
                    setSelectedAreaOfExpertiseMarker(marker);
                }
            }
            setWorkingAgentAreasOfExpertiseLoading(false);
        }).catch(error => {
            setWorkingAgentAreasOfExpertiseLoading(false);
        });
    }, [workingAgent]);

    let tabs = [];

    const AreasOfExpertiseTabs = () => {

        if (workingAgentAreasOfExpertise === null || workingAgentAreasOfExpertise === undefined) {
            return;
        }
        let tabs = [];
        for (let i = 0; i < workingAgentAreasOfExpertise.length; i++) {
            let area = workingAgentAreasOfExpertise[i];
            tabs.push(
                <div className="hover" key={"my_agent_areas_of_expertise__" + area[ID]} onMouseDown={() => {
                        setSelectedAreaOfExpertise(area);
                        setSelectedAreaOfExpertiseCallback(area);
                        if (isValidCoordinates(area[COORDINATES])) {
                            selectedAreaOfExpertiseMap.setCenter([area[COORDINATES][LONGITUDE], area[COORDINATES][LATITUDE]]);
                            selectedAreaOfExpertiseMarker.setLngLat([area[COORDINATES][LONGITUDE], area[COORDINATES][LATITUDE]]).setPopup(new maplibregl.Popup().setHTML(area["formatted_address"]));
                        }
                    }} style={{
                    backgroundColor: workingAgentAreasOfExpertise[i][ID] === selectedAreaOfExpertise[ID] ? "#05b96b" : "#efefef",
                    borderRadius: "8px",
                    display: "inline-block",
                    float: "none",
                    margin: "0px 7.5px 0px 0px",
                    padding: "5px 10px 5px 10px",
                    whiteSpace: "nowrap",
                }}>
                    <p style={{
                        color: workingAgentAreasOfExpertise[i][ID] === selectedAreaOfExpertise[ID] ? "white" : "black",
                        fontSize: "0.8em",
                    }}>
                        {workingAgentAreasOfExpertise[i]["formatted_address"]}
                    </p>
                </div>
            );
        }
        return tabs;
    }

    if (workingAgentAreasOfExpertiseLoading) {
        return;
    }

    return (
        <div style={{
            display: "flex",
            overflowX: "scroll",
            overflowY: "hidden",
            position: "relative",
            margin: "10px 0px 15px 0px",
            width: "100%",
        }}>
            <AreasOfExpertiseTabs/>
        </div>
    )
}

export default MyAgentAreasOfExpertise;