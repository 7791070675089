import React, { useState } from 'react';
import axios from 'axios';

import {
    Button,
    ButtonGroup,
    IconButton,
    Input,
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalOverlay,
    ModalBody,
    Select,
    useToast,
} from '@chakra-ui/react';

import {
    DELETE,
    ID,
    LOAN_TYPE,
    DOWN_PAYMENT_PERCENT,
    INTEREST_RATE,
    APR,
    POINTS,
    THIRTY_YEAR_FIXED,
    FIFTEEN_YEAR_FIXED,
    FIVE_ONE_ARM,
    SEVEN_ONE_ARM,
    TEN_ONE_ARM,
    THIRTY_YEAR_FHA,
    DEFAULT_DELETE_TIMEOUT,
} from '../../../utils/constants.js';

import { convertLoanTypeEnumToReadableText } from '../../../utils/utility.js';

import { MdClose, MdEdit } from "react-icons/md";
import { FaTrashAlt } from 'react-icons/fa';

function LoanModal(props) {

    const userId = props.userId;
    const loanModalLoan = props.loanModalLoan;
    const showLoanModal = props.showLoanModal;
    const closeLoanModal = props.closeLoanModal;
    const handleUpdateLoan = props.handleUpdateLoan;
    const setLoanModalLoan = props.setLoanModalLoan;
    const triggerFetchLoans = props.triggerFetchLoans;

    const [editLoan, setEditLoan] = useState(false);
    const [loanModalForm ] = useState([]);

    const toast = useToast();

    const handleDeleteLoan = (loan) => {
        axios({
            method: DELETE,
            url: '/api/v1/user/buyer/loans/' + userId + '/' + loan[ID],
            timeout: DEFAULT_DELETE_TIMEOUT,
        }).then(response => {
            if (response.data === 200) {
                // Successful delete, so we need to remove the loan from our state.
                triggerFetchLoans();
                closeLoanModal();
                setLoanModalLoan(null);
                toast({
                    title: 'Deleted Loan',
                    description: "Successfully deleted your loan",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                });
            }
        }).catch(error => {
            // TODO
        })
    }

    const handleLoanModalFormChange = (event) => {
        loanModalForm[event.target.name] = event.target.value;
    }

    if (null === loanModalLoan || undefined === loanModalLoan) {
        return;
    }
    return (
        <Modal size='sm' isOpen={showLoanModal} onClose={() => {
            closeLoanModal();
            setLoanModalLoan(null);
            setEditLoan(false);
        }} isCentered>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader style={{
                    paddingBottom: "10px"
                }}>Loan</ModalHeader>
                <ModalCloseButton onClose={() => {
                    closeLoanModal();
                    setLoanModalLoan(null);
                    setEditLoan(false);
                }} onMouseDown={() => {
                    closeLoanModal();
                    setLoanModalLoan(null);
                    setEditLoan(false);
                }}/>
                <ModalBody style={{
                    paddingBottom: "20px",
                    paddingTop: "0px",
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p className="loan_modal__title">
                                LOAN TYPE
                            </p>
                            {
                                editLoan ?
                                <Select
                                    name={LOAN_TYPE}
                                    onChange={handleLoanModalFormChange}
                                    variant='outline'
                                    size='sm'
                                    style={{
                                        border: "1px solid #cccccc",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <option selected={loanModalLoan[LOAN_TYPE] === THIRTY_YEAR_FIXED} value={THIRTY_YEAR_FIXED}>30 Year Fixed</option>
                                    <option selected={loanModalLoan[LOAN_TYPE] === FIFTEEN_YEAR_FIXED} value={FIFTEEN_YEAR_FIXED}>15 Year Fixed</option>
                                    <option selected={loanModalLoan[LOAN_TYPE] === FIVE_ONE_ARM} value={FIVE_ONE_ARM}>5/1 ARM</option>
                                    <option selected={loanModalLoan[LOAN_TYPE] === SEVEN_ONE_ARM} value={SEVEN_ONE_ARM}>7/1 ARM</option>
                                    <option selected={loanModalLoan[LOAN_TYPE] === TEN_ONE_ARM} value={TEN_ONE_ARM}>10/1 ARM</option>
                                    <option selected={loanModalLoan[LOAN_TYPE] === THIRTY_YEAR_FHA} value={THIRTY_YEAR_FHA}>30 Year FHA</option>
                                </Select> 
                                    :
                                <p className="loan_modal__text">
                                    {convertLoanTypeEnumToReadableText(loanModalLoan[LOAN_TYPE])}
                                </p>
                            }
                        </div>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p className="loan_modal__title">
                                DOWN PAYMENT
                            </p>
                            {
                                editLoan ? 
                                <Input 
                                    className="default_input loan_page__loan_modal__input"
                                    name={DOWN_PAYMENT_PERCENT}
                                    onChange={(e) => {
                                        loanModalForm[e.target.name] = parseFloat(e.target.value);
                                    }}
                                    placeholder={loanModalLoan[DOWN_PAYMENT_PERCENT] + "%"}
                                    size='sm'
                                ></Input> :
                                <p className="loan_modal__text">
                                    {loanModalLoan[DOWN_PAYMENT_PERCENT]}%
                                </p>
                            }
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p className="loan_modal__title">
                                INTEREST RATE
                            </p>
                            {
                                editLoan ? 
                                <Input 
                                    className="default_input loan_page__loan_modal__input"
                                    name={INTEREST_RATE}
                                    onChange={(e) => {
                                        loanModalForm[e.target.name] = parseFloat(e.target.value);
                                    }}
                                    placeholder={loanModalLoan[INTEREST_RATE] + "%"}
                                    size='sm'
                                ></Input> :
                                <p className="loan_modal__text">
                                    {loanModalLoan[INTEREST_RATE]}%
                                </p>
                            }
                        </div>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p className="loan_modal__title">
                                APR
                            </p>
                            {
                                editLoan ? 
                                <Input 
                                    className="default_input loan_page__loan_modal__input"
                                    name={APR}
                                    onChange={(e) => {
                                        loanModalForm[e.target.name] = parseFloat(e.target.value);
                                    }}
                                    placeholder={loanModalLoan[APR] + "%"}
                                    size='sm'
                                ></Input> :
                                <p className="loan_modal__text">
                                    {loanModalLoan[APR]}%
                                </p>
                            }
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            width: "calc(50% - 10px)",
                        }}>
                            <p className="loan_modal__title">
                                POINTS
                            </p>
                            {
                                editLoan ? 
                                <Input 
                                    className="default_input loan_page__loan_modal__input"
                                    name={POINTS}
                                    onChange={(e) => {
                                        loanModalForm[e.target.name] = parseInt(e.target.value);
                                    }}
                                    placeholder={loanModalLoan[POINTS] + "%"}
                                    size='sm'
                                ></Input> :
                                <p className="loan_modal__text">
                                    {loanModalLoan[POINTS]}%
                                </p>
                            }
                        </div>
                    </div>
                    <ButtonGroup style={{
                        alignItems: "right",
                        float: "right",
                        margin: "20px 0px 0px 0px",
                    }}>
                        {
                            editLoan ?
                            <>
                                <IconButton icon={<MdClose/>} 
                                    className="hover"
                                    onMouseDown={() => setEditLoan(false)}
                                    size='sm' 
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "1px solid #05b96b",
                                        borderRadius: "4px",
                                        color: "#05b96b",
                                    }}>
                                </IconButton>
                                <Button
                                    onMouseDown={() => handleUpdateLoan(loanModalLoan, {...loanModalForm}, () => {
                                        setLoanModalLoan(null);
                                        setEditLoan(false);
                                        closeLoanModal();
                                    })}
                                    className="hover"
                                    size='sm'
                                    style={{
                                        backgroundColor: "#05b96b",
                                        borderRadius: "4px",
                                        color: "white",
                                    }}
                                >
                                    Save
                                </Button>
                            </> : 
                            <>
                                <IconButton icon={<MdEdit/>} 
                                    className="hover"
                                    onMouseDown={() => setEditLoan(true)}
                                    size='sm' 
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "1px solid #05b96b",
                                        borderRadius: "4px",
                                        color: "#05b96b",
                                    }}>
                                </IconButton>
                                <Button 
                                    className="hover"
                                    leftIcon={<FaTrashAlt/>}
                                    onMouseDown={() => handleDeleteLoan(loanModalLoan)}
                                    size='sm'
                                    style={{
                                        backgroundColor: "#05b96b",
                                        borderRadius: "4px",
                                        color: "white",
                                    }}
                                >
                                    Delete
                                </Button>
                            </>
                        }
                    </ButtonGroup>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default LoanModal;