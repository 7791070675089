import React, { useState } from 'react';
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

import './SidePanelWrapper.css';

function SidePanelWrapper(props) {

    return (
        <div className="side_panel_wrapper">
            <div className="side_panel__children" id="side_">
                {props.children}
            </div>
        </div>
    )
}

export default SidePanelWrapper;