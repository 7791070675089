import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import {
    ADDRESS_CITY,
    ADDRESS_LINE_ONE,
    ADDRESS_LINE_TWO,
    ADDRESS_STATE,
    ADDRESS_ZIP_CODE,
    DEFAULT_GET_TIMEOUT,
    GET,
    ID,
    TRANSACTIONS,
} from '../../../utils/constants.js';

import {
    ButtonGroup,
    InputLeftElement,
    InputGroup,
    Input,
    Button,
} from '@chakra-ui/react';

import { Search2Icon } from '@chakra-ui/icons';

import { axiosGetUser, axiosGetAgent } from '../../common/methods/methods.js';
import { FaSliders, FaHandshake, FaPlus } from 'react-icons/fa6';

import AgentDashboardParentPage from '../utils/AgentDashboardParentPage.js';
import { dotPulse } from 'ldrs';

import './Page.css';
import NoObjectsDisplay from '../../common/NoObjectsDisplay.js';
import AddTransactionModal from './AddTransactionModal.js';
import MainPanel from '../../common/MainPanel.js';
import TransactionsTable from './TransactionsTable.js';
import EditTransactionModal from './EditTransactionModal.js';
import AgentSignUpInformationModal from '../utils/AgentSignUpInformationModal.js';
import Loader from '../../common/Settings/Loader.js';

import { checkRequireAgentSignUp } from '../../../utils/utility.js';

function AgentTransactionsDashboardPage(props) {

    const [user, setUser] = useState(null);
    const [userLoading, setUserLoading] = useState(true);

    const [transactions, setTransactions] = useState(null);
    const [transactionsLoading, setTransactionsLoading] = useState(true);
    const [allTransactions, setAllTransactions] = useState(null);
    const [showManualTransactionInputModal, setShowManualTransactionInputModal] = useState(false);
    const [triggerFetchTransactions, setTriggerFetchTransactions] = useState(false);
    const [currTransactionSearchInput, setCurrTransactionSearchInput] = useState(null);

    const [transactionToEdit, setTransactionToEdit] = useState(null);
    const [finishSignUpFormAgent, setFinishSignUpFormAgent] = useState(null);
    const [finishSignUpFormLoading, setFinishSignUpFormLoading] = useState(true);

    dotPulse.register();

    const urlParams = useParams();
    const userId = urlParams[ID];

    useEffect(() => {
        axiosGetUser(userId).then(user => {
            if (null !== user) {
                setUser(user);
                setUserLoading(false);
            }
        }).catch(error => {
            setUserLoading(false);
        });
    }, [userId]);

    useEffect(() => {
        axiosGetAgent(userId).then(agent => {
            if (agent) {
                if (checkRequireAgentSignUp(agent) === true) {
                    setFinishSignUpFormAgent(agent);
                };
            }
            setFinishSignUpFormLoading(false);
        }).catch(error => {
            setFinishSignUpFormLoading(false);
        });
    }, [userId])

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/agent/transactions/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                setTransactions(response.data);
                setAllTransactions(response.data);
                setTransactionsLoading(false);
            }
        }).catch(error => {
            setTransactionsLoading(false);
            // TODO: Handle our error codes.
        })
    }, [userId, triggerFetchTransactions]);


    const handleSearchTransactionsChange = (event) => {
        let searchInput = event.target.value;
        // Strip out our empty spaces in search input.
        searchInput = searchInput.replace(/ /g,'').toLowerCase();
        setCurrTransactionSearchInput(searchInput);

        // Search is empty, reset to all transactions.
        if ("" === searchInput) {
            setTransactions(allTransactions);
            return;
        }
        // Input is not null, let's filter our address.
        setTransactions(allTransactions.filter((transaction) => {
            let completeAddress = transaction[ADDRESS_LINE_ONE] + transaction[ADDRESS_LINE_TWO] + transaction[ADDRESS_CITY] + transaction[ADDRESS_STATE] + transaction[ADDRESS_ZIP_CODE];
            completeAddress = completeAddress.replace(/ /g,'');
            completeAddress = completeAddress.toLowerCase();
            return completeAddress.includes(searchInput);
        }));
        return;
    }

    const renderFilters = () => {
        return (
            <div style={{
                display: "flex",
            }}>
                <FaSliders style={{
                    height: "15px",
                    margin: "7px 12px 0px 0px",
                    width: "15px",
                }} />
                <InputGroup style={{
                    width: "350px",
                }}>
                    <InputLeftElement size='sm' pointerEvents='none'>
                        <Search2Icon color='gray' style={{
                            margin: "-7.5px 0px 0px 0px"
                        }}/>
                    </InputLeftElement>
                    <Input
                        onChange={handleSearchTransactionsChange}
                        size='sm'
                        style={{
                            border: "1px solid #cccccc",
                            borderRadius: "4px",
                            margin: "0px 10px 0px 0px",
                        }}
                        placeholder='Search by address'
                    />
                </InputGroup>
            </div>
        );
    }

    if (userLoading) return;

    return (
        <AgentDashboardParentPage user={user} tab={TRANSACTIONS}>
            {
                transactionsLoading || finishSignUpFormLoading ?
                <div style={{
                    margin: "auto",
                }}>
                    <Loader/>
                </div> :
                <>
                    {
                        finishSignUpFormAgent ?
                        <AgentSignUpInformationModal
                            user={finishSignUpFormAgent}
                            setFinishSignUpFormAgent={setFinishSignUpFormAgent}
                        />: null
                    }
                    <MainPanel>
                        {/* {
                            showToolTip ? 
                            (
                                <Alert status='success' variant='left-accent' style={{
                                    borderRadius: "4px",
                                    justifyContent: "space-between",
                                    margin: "15px 0px 10px 0px",
                                }}>
                                    <div style={{
                                        display: "flex",
                                    }}>
                                        <AlertIcon as={FaLightbulb}/>
                                        Tip: Adding past transaction data below will help you get higher quality leads
                                    </div>
                                    <CloseButton onMouseDown={() => {
                                        setShowToolTip(false);
                                    }}/>
                                </Alert>
                            ) : null
                        } */}
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>

                            {
                                (transactions && transactions.length > 0) || currTransactionSearchInput !== null ? 
                                renderFilters() :
                                <div></div>
                            }
                            {/* <IconButton aria-label='Search database' icon={<FaCloudUploadAlt/>} size='sm' isRound={true} style={{
                                backgroundColor: "#efefef",
                                color: "#05b96b"
                            }}/> */}
                            <ButtonGroup>
                                {/* {
                                    transactions && transactions.length > 0 ?
                                    <Button className="negative_button hover" leftIcon={<DownloadIcon/>} size='sm'>
                                        Export
                                    </Button> : null
                                } */}
                                
                                <Button className="positive_button hover" leftIcon={<FaPlus/>} size='sm' onMouseDown={() => setShowManualTransactionInputModal(true)} style={{
                                    margin: "0px 0px 0px 10px"
                                }}>
                                    Add Transaction
                                </Button>
                            </ButtonGroup>
                            {
                                showManualTransactionInputModal ?
                                <AddTransactionModal
                                    userId={userId}
                                    showManualTransactionInputModal={showManualTransactionInputModal}
                                    setShowManualTransactionInputModal={(toShow) => setShowManualTransactionInputModal(toShow)}
                                    triggerFetchTransactions={() => setTriggerFetchTransactions(!triggerFetchTransactions)}
                                /> : null
                            }
                            {
                                transactionToEdit !== null ?
                                <EditTransactionModal 
                                    userId={userId}
                                    closeEditTransactionModel={() => setTransactionToEdit(null)}
                                    triggerFetchTransactions={() => setTriggerFetchTransactions(!triggerFetchTransactions)}
                                    transaction={transactionToEdit}
                                />
                                : null
                            }
                        </div>
                        {
                            transactions && transactions.length > 0 ? 
                            <TransactionsTable 
                                transactions={transactions}
                                setTransaction={(transaction) => setTransactionToEdit(transaction)}
                                readOnlyView={false}
                            /> :
                            <NoObjectsDisplay
                                icon={FaHandshake}
                                titleText={"No Transactions Found"}
                                subtitleText={"No transactions were found. Add transactions to increase your conversion rate."}
                                buttonText={"Add Transaction"}
                                buttonCallback={() => setShowManualTransactionInputModal(true)}
                            />
                        }
                    </MainPanel>
                </>
            }
        </AgentDashboardParentPage>
    );
}

export default AgentTransactionsDashboardPage;