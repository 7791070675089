import React from 'react';

import { 
    Avatar,
    Card,
    CardBody,
} from '@chakra-ui/react'

import {
    ADDRESS_LINE_ONE,
    ADDRESS_LINE_TWO,
    COMMISSION_PERCENTAGE,
    FIRST_NAME,
    LAST_NAME,
    NUMBER_OF_MONTHS,
    PURCHASE_PRICE
} from '../../../utils/constants.js';

import { dollarfyPrice } from '../../../utils/utility.js';

import './UserCard.css';

import { FaFileLines } from "react-icons/fa6";

function UserCard(props) {

    const client = props.client;
    const defaultProperty = props.defaultProperty;
    const offer = props.offer;

    const setDocumentModalClient = props.setDocumentModalClient;

    return (
        <Card style={{
            borderRadius: "12px",
            borderRadius: "4px",
            boxShadow: "none",
            margin: "0px 0px 0px 0px",
            width: "100%",
        }}>
            <CardBody style={{
                padding: "10px 0px 10px 0px",
            }}>
                <div style={{
                    display: "flex",
                }}>
                    <Avatar
                        size='sm'
                    ></Avatar>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "0px 0px 0px 15px",
                        width: "calc(100% - 32px - 15px)",
                    }}>
                        <p className="clients__user_card__text" style={{
                            width: "27.5%",
                        }}>
                            {client[FIRST_NAME]} {client[LAST_NAME]}
                        </p>
                        <p className="clients__user_card__text" style={{
                            width: "12.5%",
                        }}>
                            {defaultProperty ? "$" + dollarfyPrice(defaultProperty[PURCHASE_PRICE]) : "N/A"}
                        </p>
                        <p className="clients__user_card__text" style={{
                            width: "35%",
                        }}>
                            {defaultProperty ? defaultProperty[ADDRESS_LINE_ONE] + " " + defaultProperty[ADDRESS_LINE_TWO] : "N/A"}
                        </p>
                        <p className="clients__user_card__text" style={{
                            width: "10%",
                        }}>
                            {offer ? offer[COMMISSION_PERCENTAGE] + "%" : "N/A"}
                        </p>
                        <p className="clients__user_card__text" style={{
                            width: "10%",
                        }}>
                            {offer ? offer[NUMBER_OF_MONTHS] + " mo." : "N/A"}
                        </p>
                        <div className="clients__user_card__document_icon_box" style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "5%",
                        }}>
                            {/* <MdUpload className="clients__user_card__document_icon_small hover"/> */}
                            <FaFileLines 
                                className="clients__user_card__document_icon_large hover"
                                onMouseDown={() => setDocumentModalClient(client)}
                            />
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default UserCard;