import {
    FIRM,
    LATITUDE,
    LICENSE_TYPE,
    LONGITUDE,
    THIRTY_YEAR_FIXED,
    FIFTEEN_YEAR_FIXED,
    FIVE_ONE_ARM,
    SEVEN_ONE_ARM,
    TEN_ONE_ARM,
    THIRTY_YEAR_FHA,
    SINGLE_FAMILY_HOME,
    MIN_PASSWORD_LENGTH,
    MAX_PASSWORD_LENGTH,
    ABOUT_DESCRIPTION,
    REPRESENTATION_STYLE,
    WORKING_STYLE,
    SALE_EXPERTISE,
    ASSET_SPECIALTY,
    NUMBER_OF_AREAS_OF_EXPERTISE,
    NUMBER_OF_INTERESTED_AREAS,
    CREDIT_SCORE,
    MOVE_BEFORE_DATE,
    MIN_PRICE,
    MAX_PRICE,
    ASSET_INTERESTS
} from './constants.js';

export const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// checkRequireAgentSignUp will check and agent and determine if we need to ask user for 
// more agent information.
export function checkRequireAgentSignUp(agent) {

    if (!agent) return false;
    if (!agent[FIRM] || agent[FIRM].length === 0) return true;
    if (!agent[ABOUT_DESCRIPTION] || agent[ABOUT_DESCRIPTION].length === 0) return true;
    if (!agent[LICENSE_TYPE] || agent[LICENSE_TYPE].length === 0) return true;
    if (!agent[REPRESENTATION_STYLE] || agent[REPRESENTATION_STYLE].length === 0) return true;
    if (!agent[WORKING_STYLE] || agent[WORKING_STYLE].length === 0) return true;
    if (!agent[SALE_EXPERTISE] || agent[SALE_EXPERTISE].length === 0) return true;
    if (!agent[ASSET_SPECIALTY] || agent[ASSET_SPECIALTY].length === 0) return true;
    if (!agent[NUMBER_OF_AREAS_OF_EXPERTISE] || agent[NUMBER_OF_AREAS_OF_EXPERTISE] === 0) return true;

    return false;
}

// Can't check for FIRST_TIME_BUYER or PRE_APPROVED as they are booleans.
export function checkRequireBuyerSignUp(buyer) {
    if (!buyer) return false;
    if (!buyer[CREDIT_SCORE] || buyer[CREDIT_SCORE].length === 0) return true;
    if (!buyer[MOVE_BEFORE_DATE] || buyer[MOVE_BEFORE_DATE].length === 0) return true;
    if (!buyer[MIN_PRICE] || buyer[MIN_PRICE] === 0) return true;
    if (!buyer[MAX_PRICE] || buyer[MAX_PRICE] === 0) return true;
    if (!buyer[ASSET_INTERESTS] || buyer[ASSET_INTERESTS].length === 0) return true;
    if (!buyer[NUMBER_OF_INTERESTED_AREAS] || buyer[NUMBER_OF_INTERESTED_AREAS] === 0) return true;
    return false;
}

/*
 * Credit to https://stackoverflow.com/questions/18299806/how-to-check-file-mime-type-with-javascript-before-upload.
 */
export function loadMime(file, callback) {
    
    //List of known mimes
    var mimes = [
        {
            mime: 'image/jpeg',
            pattern: [0xFF, 0xD8, 0xFF],
            mask: [0xFF, 0xFF, 0xFF],
        },
        {
            mime: 'image/png',
            pattern: [0x89, 0x50, 0x4E, 0x47],
            mask: [0xFF, 0xFF, 0xFF, 0xFF],
        }
        // you can expand this list @see https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
    ];

    function check(bytes, mime) {
        for (var i = 0, l = mime.mask.length; i < l; ++i) {
            if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
                return false;
            }
        }
        return true;
    }

    var blob = file.slice(0, 4); //read the first 4 bytes of the file

    var reader = new FileReader();
    reader.onloadend = function(e) {
        if (e.target.readyState === FileReader.DONE) {
            var bytes = new Uint8Array(e.target.result);

            for (var i=0, l = mimes.length; i<l; ++i) {
                if (check(bytes, mimes[i])) return callback(mimes[i].mime);
            }

            return callback(file.type);
        }
    };
    reader.readAsArrayBuffer(blob);
}

export function prettifyDate(date) {

    date = date.toLocaleString();
    // Format is 2020-01-01TXXX.XXXZ
    if (date === undefined || date === null) {
        return ["", ""];
    }
    let split = date.split("T");

    let fullDate = split[0];

    let splitFullDate = fullDate.split("-");

    let year = splitFullDate[0];
    let month = splitFullDate[1];
    let day = splitFullDate[2];

    return monthArr[month - 1] + " " + day + ", " + year;
}

export function prettifyLocaleDate(date) {
    if (date === undefined || date === null) {
        return ["", ""];
    }

    let split = date.split("/")
    
    let month = split[0];
    let day = split[1];
    let year = split[2];

    return monthArr[month - 1] + " " + day + ", " + year;
}

export function prettifyAgentRepresentation(representation) {
    switch(representation) {
        case "ClientFiduciary":
            return "Client Fiduciary";
        default:
            return representation;
    }
}

export function convertLoanTypeEnumToReadableText(loanType){
    switch(loanType) {
        case THIRTY_YEAR_FIXED:
            return "30 Year Fixed";
        case FIFTEEN_YEAR_FIXED:
            return "15 Year Fixed";
        case FIVE_ONE_ARM:
            return "5/1 ARM";
        case SEVEN_ONE_ARM:
            return "7/1 ARM";
        case TEN_ONE_ARM:
            return "10/1 ARM";
        case THIRTY_YEAR_FHA:
            return "30 Year FHA";
        default:
            return "N/A"
    }
}

export function prettifyPropertyType(propertyType) {
    switch(propertyType) {
        case SINGLE_FAMILY_HOME:
            return "Single Family Home";
        default:
            return propertyType;
    }
}

export function convertStringToPrice(price) {

    if (!price) return "";
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return USDollar.format(price);
}

export function dollarfyPrice(price) {
    if (!price) return "";
    // return parseInt(price).toLocaleString();
    return price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export function isValidEmail(email) {
    if (!email) return false;
    let VALID_EMAIL_REGEX = /^[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,4}$/;
    return VALID_EMAIL_REGEX.test(email);
}

export function isValidPhoneNumber(phoneNumber) {
    if (!phoneNumber) return false;
    return phoneNumber.length === 16;
}

export function isValidPassword(password) {
    let UPPER_CASE_REGEX = /[A-Z]/;
    if (!UPPER_CASE_REGEX.test(password)) {
        // Does not have at least 1 upper case.
        return false;
    }

    if (password.length < MIN_PASSWORD_LENGTH) {
        return false;
    }

    if (password.length > MAX_PASSWORD_LENGTH) {
        return false;
    }

    if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
        return false;
    }

    return true;
}

export function isValidCoordinates(coordinates) {
    if (!coordinates) return false;
    let latitude = coordinates[LATITUDE];
    let longitude = coordinates[LONGITUDE];
    return latitude !== 0 && longitude !== 0;
}

export function prettifyLicenseType(val) {
    switch (val) {
        case "ManagingBroker":
            return "Managing Broker";
        case "AssociateBroker":
            return "Associate Broker";
        default:
    }
    return val;
}

export function prettifySaleExpertise(val) {
    switch (val) {
        case "ShortSale":
            return "Short Sale";
        default:
            return val;
    }
}

export function renderWorkingStyle(workingStyle){
    switch(workingStyle) {
        case "PartOfTeam":
            return "Part of a team";
        case "SoleLead":
            return "Sole lead";
        default:
            return "-";
    };
}

