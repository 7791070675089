import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box
} from '@chakra-ui/react'

import Footer from './Footer';
import NavigationBar from './NavigationBar';

import './PrivacyPolicyPage.css';

function PrivacyPolicyPage(props) {

    return (
        <div className="privacy_policy_page">
            <NavigationBar/>
            <div className="privacy_policy_page__parent_box">
                <p className="privacy_policy_page__title">
                    Privacy Policy
                </p>
                <p className="privacy_policy_page__subtitle">
                    Please read the following carefully as it governs the privacy policy of your usage of our service, Rezied.
                </p>
                <p style={{
                    margin: "15px 0px 0px 0px",
                }}>
                    Rezied Inc. (“we”,”us”,”our”,“Rezied”) thrives on the contributions and interactions of its community on
                    the Rezied websites, features, services and other Rezied platforms (collectively, the “Services”). Given
                    how vital you and the community are to Rezied’ mission of making the real estate industry more
                    efficient for everyone, we strongly believe in protecting and respecting your privacy.
                    <br></br>
                    This policy (“Privacy Policy”) describes what information we collect, how it is used and how it is
                    disclosed. This policy is supplemental to the Rezied Terms of Use and, together, constitute the operative
                    agreement between you and Rezied.
                </p>
                <Accordion allowToggle style={{
                    margin: "15px 0px 0px 0px",
                }}>
                    <AccordionItem>
                        <AccordionButton className="privacy_policy_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                1. Information Collected
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="privacy_policy_page__accordion_panel">
                            When you access or use the Services, we may collect information from and regarding you, your
                            interaction with the Services, your devices and the software used to access the Services, including
                            browser and operating system. Additionally, certain use of the Services may require you to provide
                            permission for a third-party to provide information about you. Some of this information may be
                            personally identifiable information and data (“Personal Information”).
                            <br></br>
                            <br></br>
                            1a. Information from You
                            <br></br>
                            When you use the Services, you may choose to provide us with Personal Information, payment
                            information, financial information, your telephone number, email address, billing address, details
                            about real estate you are interested in, reviews, message board comments, photos, pictures or
                            contractual agreements between you and third-parties. We collect your personal information
                            directly and indirectly from you. This may occur directly, for example, when you create an account
                            on the Services, input financial data into one of our models, interact with a real estate agent on the
                            Services or complete a financial transaction with your credit or debit card. This may occur indirectly,
                            for example, through your use of the Marketplace services which may require you to provide
                            permission for your real estate agent to provide us with the contractual agreement between you
                            and your agent as well as the contractual agreement between you and a buyer or seller, whichever
                            the case may be.
                            <br></br>
                            <br></br>
                            You acknowledge that this information may be personal to you, and by, directly or indirectly,
                            providing this information to us we may store it.
                            <br></br>
                            <br></br>
                            1b. Information from Usage
                            <br></br>
                            As you access or use the Services, we collect your information. This may include your IP address,
                            browser type, operating system, hardware used to access the Services, search queries, access times,
                            pages viewed, number of actions taken, when actions were taken, cookie information or language
                            used. Additionally, we may determine your physical location using features and attributes of your
                            device, GPS, Bluetooth, WiFi signals, IP address mapping or other technologies in order to enhance
                            the Services and provide a better experience for you through personalized offerings and services.
                            <br></br>
                            <br></br>
                            1c. Information from Common Tracking Technology
                            <br></br>
                            Rezied and third-parties use technology including, but not limited to, browser cookies and web
                            beacons to collect usage and demographic information. Cookies are small electronic files stored on
                            your device that uniquely identify your browser. Web beacons are small electronic files, usually
                            single pixel GIF images, inserted into emails and web pages that collect usage and interaction data.
                            We use cookies and web beacons to track pages visited, monitor interactions with the Services,
                            connect other data stored about you, identify your unique browser, record time spent on a page
                            and understand your display settings, among other things.
                            <br></br>
                            <br></br>
                            The use of this common tracking technology enables Rezied to improve the quality of the Services
                            for you by allowing us to assess which content is most interesting and / or relevant to you,
                            determine which marketing methods are most suitable to you, save you time by storing commonly
                            used inputs and ensure the Services are operating as intended. Should you prefer to, your browser
                            may let you disable cookies which may prevent your browser from accepting new cookies.
                            <br></br>
                            <br></br>
                            By accessing the Services, you give us your free, unambiguous and informed consent to use the
                            common tracking technology in this policy.
                            <br></br>
                            <br></br>
                            1d. Information from Third-Parties
                            <br></br>
                            When you register an account or otherwise link a third-party account, such as a Facebook or Google
                            account, with the Services, you are permitting us to use and store the information related to that
                            account that you made available and use it in accordance with this privacy policy. Examples of
                            information that may be obtained from linking these types of third-party accounts include, but are
                            not limited to, demographic, employment, social media networking, user name and password
                            information. By following instructions provided by any such third-party account, you may be able to
                            manage the type of information shared to Rezied.
                            <br></br>
                            <br></br>
                            We may also use Google Analytics to collect demographic and interest data about you (such as age,
                            gender and interests), including through Google Analytics Demographics and Interest Reporting. We
                            may use the information collected about you through Google Analytics for Google services such as
                            Remarketing with Google Analytics and Google Display Network Impression Reporting. You can find
                            out more about Google Analytics and how to opt-out by going to the Google Support page.
                            <br></br>
                            <br></br>
                            1e. Information from Customer Support
                            <br></br>
                            Any interaction including, but not limited to emails, calls, text messages and mailed correspondence
                            with our customer support will be stored and monitored for quality assurance purposes.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="privacy_policy_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                2. Minors
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="privacy_policy_page__accordion_panel">
                            Rezied does not knowingly collect Personal Information from minors. If we become aware of personal
                            information submitted by a minor, we will remove this information from our records. Minors may not
                            access or use the Services.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="privacy_policy_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                3. Information Usage
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="privacy_policy_page__accordion_panel">
                            We use the information collected and described in this policy (“Collected Information”) for several
                            purposes all with the same goal of improving the Services for users. The remainder of this section
                            provides further detail on how we use this information.
                            <br></br>
                            <br></br>
                            3a. Provide the Services
                            <br></br>
                            Collected Information allows us to provide and maintain the Services including, without limitation,
                            to update software, facilitate customer support functions and validate that the Services perform to
                            expectations. Collected Information also allows us to combine information from third-parties, such
                            as information from a Facebook or Google account, that further personalize the Services for you.
                            <br></br>
                            <br></br>
                            3b. Understand Users
                            <br></br>
                            Collected Information helps us understand and anticipate the preferences of our users which allows
                            us to make the Services more efficient, personalized and convenient through, for example, pre-
                            setting inputs on our models and recommending content based on a user’s physical location.
                            <br></br>
                            <br></br>
                            3c. Monitor Interactions
                            <br></br>
                            Collected Information may be used to track and analyze how you use the Services and respond to
                            changes, notifications and new or altered offerings. This information allows us to evaluate the
                            usefulness and effectiveness of the Services.
                            <br></br>
                            <br></br>
                            3d. Communicate
                            <br></br>
                            Collected Information is used to communicate with you for reasons including, without limitation, to
                            send you account updates, usage reports, marketing materials, solicitations for reviews, newsletters,
                            notifications related to the Services, responses to inquiries and statuses of transactions made on the
                            Services.
                            Collected Information may also be used to communicate with third-parties to send information
                            including, without limitation, to send payment information to the third-party we use to process
                            transactions made on the Services.
                            <br></br>
                            <br></br>
                            3e. Display Advertisements
                            <br></br>
                            If and when Rezied begins to offer advertisements or marketing services to third-parties, we may
                            use Collected Information to improve the relevancy of the advertisements to you and analyze the
                            effectiveness of the advertisements for any such third-party advertiser.
                            <br></br>
                            <br></br>
                            3f. Safety and Security
                            <br></br>
                            Collected Information may also be used to verify accounts, monitor usage, investigate suspicious
                            activity, address actual or suspected fraud and generally promote safety and security, all as they
                            relate to the Services.
                            <br></br>
                            <br></br>
                            3g. Legal Related
                            <br></br>
                            Collected Information may also be used to manage and resolve legal disputes, comply with
                            regulatory investigations, ensure compliance with any regulatory and judicial bodies, enforce the
                            Rezied Terms of Use, enforce the Rezied Privacy Policy and protect our property. For the avoidance
                            of doubt, Collected Information may also be used to carry out our obligations and enforce our rights
                            from any contracts entered into between you and us.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="privacy_policy_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                4. Disclosure of Information
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="privacy_policy_page__accordion_panel">
                            Personal Information that you provide outside public areas of the Services may be shared by us only
                            under the circumstances described in the remainder of this section.
                            <br></br>
                            <br></br>
                            4a. Your Consent
                            <br></br>
                            When you submit your information through the Services you consent to Rezied to share the
                            Personal Information. An example includes, but is not limited to, contacting a real estate
                            professional as a consumer or contacting a consumer as a real estate professional through the
                            Services, your name, phone number, email address and any content from any related messages will
                            be shared with the recipient contacted.
                            <br></br>
                            <br></br>
                            Please note that this does not apply to Personal Information obtained through the upload of any
                            agreement between you and a third-party such as an agency agreement or a purchase and sale
                            agreement. Information obtained through these agreements may be shared if Rezied, in its sole
                            discretion, determines that the information does not identify you, as in aggregated information or
                            information without personal identifiers. Furthermore, any personal names, phone numbers, e-mail
                            addresses, fax numbers or mailing addresses shared on the Services are obtained through means
                            other than any uploaded agency agreement or purchase and sale agreement.
                            <br></br>
                            <br></br>
                            4b. Sitewide Publications
                            <br></br>
                            Information shared on site message boards, blog posts, real estate professional reviews, reviews of
                            any content on the Services and contributions to discussions shall be deemed as information that is
                            shared in public areas of the Services. As such, this information will be publicly available and shared.
                            <br></br>
                            <br></br>
                            4c. Service Providers
                            <br></br>
                            We may share your Personal Information with a service provider, vendor, agent or other entity that
                            performs functions on our behalf in operation of the Services and have agreed in writing to protect
                            and not further disclose your Personal Information. An example includes, but is not limited to,
                            information shared with the third-party payment processing entity we use to process transactions
                            made on the Services.
                            <br></br>
                            <br></br>
                            4d. Business Partners
                            <br></br>
                            We may share your Personal Information with businesses that Rezied has partnered with to offer
                            products and services. Information will be shared only as needed to provide these products and
                            services.
                            <br></br>
                            <br></br>
                            4e. Affiliates and Change of Control
                            <br></br>
                            We may share your Personal Information with affiliates and subsidiaries of Rezied consistent with
                            this Privacy Policy. Additionally, if and when Rezied, in part or entirety, is sold or otherwise changes
                            control, whether as a going concern or as part of a bankruptcy, liquidation or similar proceeding, we
                            may share your Personal Information with the resulting owner or owners, as applicable at that time.
                            <br></br>
                            <br></br>
                            4f. Legal Compliance
                            <br></br>
                            We may share Personal Information if required by law, regulation, legal process or other regulatory
                            or governmental body’s enforceable request. Personal Information may also be shared if Rezied has
                            a good faith belief that doing so would prevent, detect or prosecute a criminal act, damage to our
                            property, violations of the Rezied Terms of Use and / or violations of the Rezied Privacy Policy.
                            <br></br>
                            <br></br>
                            4g. Aggregated Information
                            <br></br>
                            We may share any information that we, in our sole discretion, determine does not identify you, as in
                            aggregated information or information without personal identifiers.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="privacy_policy_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                            5. User Preferences
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="privacy_policy_page__accordion_panel">
                            You can manage email notification and promotional settings in your account settings. Promotional
                            emails also contain an opt-out URL that allows you to manage your receipt of this content. Certain
                            emails and messaging cannot be unsubscribed from as they are critical to maintaining the Services, but
                            cancelling your account with us will effectively unsubscribe you from these.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="privacy_policy_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                6. Security Disclaimer
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="privacy_policy_page__accordion_panel">
                            We endeavor to protect the information users share and take reasonable steps to do so; however, no
                            data transmission or electronic storage measures can guarantee absolute security. We use Secure
                            Socket Layer (SSL) technology that offers protection for information sent over the internet. Our systems
                            also use data encryption and firewalls to further protect your information. Given the imperfect nature
                            of data security, we cannot and do not guarantee or warrant that such techniques will prevent
                            unauthorized access to your information.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="privacy_policy_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                7. Account Deletion
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="privacy_policy_page__accordion_panel">
                            You can request to have your account deleted by emailing customer.support@Rezied.com. When you
                            delete your account, we may retain information you have shared if Rezied, in its sole discretion,
                            determines it does not identify you, as in aggregated information or information without personal
                            identifiers. We may also retain a copy of the original Collected Information for legal and regulatory
                            compliance or other legitimate business reason.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="privacy_policy_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                8. Retention
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="privacy_policy_page__accordion_panel">
                            We will retain your information for as long as necessary to provide the Services and carry out the terms
                            of this Privacy Policy. If required for any legal or regulatory compliance, we may extend this retention
                            period as needed.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="privacy_policy_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                9. Third-Party Privacy Policies
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="privacy_policy_page__accordion_panel">
                            We may link to third-party websites throughout the Services. When you click through to any third-party
                            websites off the Services, the Rezied Privacy Policy does not apply.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="privacy_policy_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                10. Amendments
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="privacy_policy_page__accordion_panel">
                            We may modify this Privacy Policy at any time and without prior notice. We will notify you of any
                            material changes to how we use your Personal Information before we implement them. Notification
                            may be made via email or prominent notice on the Services. We also advise you to check back for the
                            most current version before relying on any provisions of this Privacy Policy.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="privacy_policy_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                11. Governing Law
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="privacy_policy_page__accordion_panel">
                            If you are located outside the United States and choose to provide information to us, we transfer that
                            information to and process that information in the United States. Furthermore, you acknowledge that
                            the information we collect is governed by United States law. Your access or use of the Services
                            represents your consent to the processing, transfer and storage of information in and to the United
                            States. United States privacy laws, rights and protections may differ from your local jurisdiction.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="privacy_policy_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                12. Contact
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="privacy_policy_page__accordion_panel">
                            For any questions about this Privacy Policy, please email us at privacy@Rezied.com or by mail at:
                            <br></br>
                            <br></br>
                            Rezied Inc.
                            <br></br>
                            ATTN: Privacy Protection
                            <br></br>
                            PO Box 20187
                            <br></br>
                            New York, NY 10014
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
                <p style={{
                    color: "grey",
                    fontSize: "0.7em",
                    margin: "15px 0px 0px 0px",
                }}>
                    Last Modified <i>April 30th, 2024</i>
                </p>
            </div>
            <Footer/>
        </div>
    )
}

export default PrivacyPolicyPage;