import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { 
    Button,
    ButtonGroup,
    Checkbox,
    IconButton,
    Input,
    InputGroup,
    Select,
    Stack
} from '@chakra-ui/react'

import { FaCircleCheck, FaMoneyBill, FaMoneyBillTrendUp,FaRegCalendarCheck, FaBuildingColumns } from "react-icons/fa6";
import { FaElementor } from 'react-icons/fa';
import { MdClose, MdEdit } from 'react-icons/md';

import {
    APARTMENT,
    ASSET_INTERESTS,
    CONDOMINIUM,
    DUPLEX,
    FIRST_TIME_BUYER,
    ID,
    MANUFACTURED,
    MAX_PRICE,
    MIN_PRICE,
    MOVE_BEFORE_DATE,
    MULTIPLEX,
    PRE_APPROVED,
    SINGLE_FAMILY_HOME,
    TOWNHOME,
    TRAILER,
} from '../../../utils/constants.js';

import { 
    prettifyDate,
    prettifyPropertyType
} from '../../../utils/utility.js';

import './IntroPanel.css';

function IntroPanel(props) {

    const navigate = useNavigate();

    const user = props.user;
    const buyer = props.buyer;
    const handleUpdateUserProfile = props.handleUpdateUserProfile;

    const [buyerForm, setBuyerForm] = useState([]);
    const [editProfile, setEditProfile] = useState(false);

    const renderIsFirstTimeBuyer = () => {
        // Display placeholder text if we cannot load the buyer.
        if (!buyer || null == buyer[FIRST_TIME_BUYER]) return "-";
        return (buyer[FIRST_TIME_BUYER] === true ? "Yes" : "No")
    }

    const renderPreapproved = () => {
        // Display placeholder text if we cannot load the buyer.
        if (!buyer || null === buyer[PRE_APPROVED]) return "-";
        return (buyer[PRE_APPROVED] === true ? "Yes" : "No")
    }

    const renderMoveBeforeDate = () => {
        // Display placeholder text if we cannot load the buyer.
        return buyer && buyer[MOVE_BEFORE_DATE] ? prettifyDate(buyer[MOVE_BEFORE_DATE]) : "-";
    }

    const renderMinimumPrice = () => {
        // Display placeholder text if we cannot load the buyer.
        return buyer && buyer[MIN_PRICE] ? "$" + buyer[MIN_PRICE] : "-";
    }

    const renderMaximumPrice = () => {
       // Display placeholder text if we cannot load the buyer.
       return buyer && buyer[MAX_PRICE] ? "$" + buyer[MAX_PRICE] : "-";
    }

    const renderAssetInterests = () => {
       if (!buyer || !buyer[ASSET_INTERESTS] || null === buyer[ASSET_INTERESTS]) return "-";

       let assetInterests = buyer[ASSET_INTERESTS];

       let result = [];
       for (var i = 0; i < assetInterests.length; i++) {
           result.push(prettifyPropertyType(assetInterests[i]));
           result.push(<br></br>);
       }
       return result;
    }

    const getAssetInterestsValues = () => {
        let assetInterestsValues = [];
        let assetInterestsCheckboxes = document.getElementsByClassName("asset_specialty__checkbox");
        for (let i = 0; i < assetInterestsCheckboxes.length; i++) {
            let chakraCheckboxElement = assetInterestsCheckboxes[i];
            // The actual element is the first child, the input.
            let checkboxInput = chakraCheckboxElement.children[0];
            if (checkboxInput.checked === true) {
                assetInterestsValues.push(checkboxInput.value);
            }
        }
        return assetInterestsValues;
    }

    return (
        <div className="intro_panel__parent_panel">
            <div className="intro_panel__panel">
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                    <p style={{
                        fontWeight: "bold"
                    }}>
                        Intro
                    </p>
                    <IconButton
                        className="edit_profile_button"
                        icon={ editProfile ? <MdClose/> : <MdEdit/>}
                        isRound
                        onMouseDown={() => {
                            editProfile ?
                            setEditProfile(false) : 
                            setEditProfile(true)
                        }}
                        size='sm'
                        style={{
                            backgroundColor: "#05b96b",
                            color: "white",
                            cursor: "pointer",
                            transition: "0.3s",
                        }} 
                    />
                </div>
                <div style={{
                    margin: "10px 0px 0px 0px"
                }}>
                    <div style={{
                        display: "flex",
                    }}>
                        <FaElementor className="intro_panel__icon"/>
                        <p className="intro_panel__title">
                            FIRST TIME BUYER
                        </p>
                    </div>
                    {
                        editProfile ?
                        <div style={{
                            margin: "5px 0px 0px 0px",
                            width: "100%",
                        }}>
                            <InputGroup>
                                <Select
                                    name={FIRST_TIME_BUYER}
                                    className="default_input__white"
                                    onChange={(e) => {
                                        buyerForm[FIRST_TIME_BUYER] = e.target.value === "true";
                                        setBuyerForm(buyerForm);
                                    }}
                                    size='md'
                                >
                                    <option selected={renderIsFirstTimeBuyer() === "Yes" ? "selected" : ""} value="true">Yes</option>
                                    <option selected={renderIsFirstTimeBuyer() === "No" ? "selected" : ""} value="false">No</option>
                                </Select>
                            </InputGroup>
                        </div> :
                        <p className="intro_panel__text">
                            {renderIsFirstTimeBuyer()}
                        </p>
                    }
                    
                </div>
                <div style={{
                    margin: "20px 0px 0px 0px"
                }}>
                    <div style={{
                        display: "flex",
                    }}>
                        <FaCircleCheck className="intro_panel__icon"/>
                        <p className="intro_panel__title">
                            PRE-APPROVED
                        </p>
                    </div>
                    {
                        editProfile ?
                        <div style={{
                            margin: "5px 0px 0px 0px",
                            width: "100%",
                        }}>
                            <InputGroup>
                                <Select
                                    className="default_input__white"
                                    onChange={(e) => {
                                        buyerForm[PRE_APPROVED] = e.target.value === "true";
                                        setBuyerForm(buyerForm);
                                    }}
                                    name={PRE_APPROVED}
                                    size='md'
                                >
                                    <option selected={renderPreapproved() === "Yes" ? "selected" : ""} value="true">Yes</option>
                                    <option selected={renderPreapproved() === "No" ? "selected" : ""} value="false">No</option>
                                </Select>
                            </InputGroup>
                        </div> :
                        <p className="intro_panel__text">
                            {renderPreapproved()}
                        </p>
                    }
                    
                </div>
                <div style={{
                    margin: "20px 0px 0px 0px"
                }}>
                    <div style={{
                        display: "flex",
                    }}>
                        <FaRegCalendarCheck className="intro_panel__icon"/>
                        <p className="intro_panel__title">
                            MOVE BEFORE DATE
                        </p>
                    </div>
                    {
                        editProfile ?
                        <div style={{
                            margin: "5px 0px 0px 0px",
                            width: "100%",
                        }}>
                            <InputGroup>
                                <Input 
                                    type="date"
                                    className="default_input__white"
                                    onChange={(e) => {
                                        buyerForm[MOVE_BEFORE_DATE] = new Date(e.target.value);
                                        setBuyerForm(buyerForm);
                                    }}
                                    max="9999-12-31"
                                    size='md'/>
                            </InputGroup>
                        </div> :
                        <p className="intro_panel__text">
                            {renderMoveBeforeDate()}
                        </p>
                    }
                </div>
                <div style={{
                    margin: "20px 0px 0px 0px"
                }}>
                    <div style={{
                        display: "flex",
                    }}>
                        <FaMoneyBill className="intro_panel__icon"/>
                        <p className="intro_panel__title">
                            MINIMUM PRICE
                        </p>
                    </div>
                    {
                        editProfile ?
                        <div style={{
                            margin: "5px 0px 0px 0px",
                            width: "100%",
                        }}>
                            <InputGroup>
                                <Input 
                                    className="default_input__white"
                                    onChange={(e) => {
                                        buyerForm[MIN_PRICE] = parseInt(e.target.value);
                                        setBuyerForm(buyerForm);
                                    }}
                                    placeholder={renderMinimumPrice()}
                                    size='m,d'/>
                            </InputGroup>
                        </div> : 
                        <p className="intro_panel__text">
                            {renderMinimumPrice()}
                        </p>
                    }
                </div>
                <div style={{
                    margin: "20px 0px 0px 0px"
                }}>
                    <div style={{
                        display: "flex",
                    }}>
                        <FaMoneyBillTrendUp className="intro_panel__icon"/>
                        <p className="intro_panel__title">
                            MAXIMUM PRICE
                        </p>
                    </div>
                    {
                        editProfile ?
                        <div style={{
                            margin: "5px 0px 0px 0px",
                            width: "100%",
                        }}>
                            <InputGroup>
                                <Input 
                                    className="default_input__white"
                                    onChange={(e) => {
                                        buyerForm[MAX_PRICE] = parseInt(e.target.value);
                                        setBuyerForm(buyerForm);
                                    }}
                                    placeholder={renderMaximumPrice()}
                                    size='md'/>
                            </InputGroup>
                        </div> :
                        <p className="intro_panel__text">
                            {renderMaximumPrice()}
                        </p>
                    }
                </div>
                <div style={{
                    margin: "20px 0px 0px 0px"
                }}>
                    <div style={{
                        display: "flex",
                    }}>
                        <FaBuildingColumns className="intro_panel__icon"/>
                        <p className="intro_panel__title">
                            LOOKING FOR
                        </p>
                    </div>
                    {
                        editProfile ?
                        <div style={{
                            margin: "5px 0px 0px 0px",
                            width: "100%",
                        }}>
                            <Stack style={{
                                backgroundColor: "white",
                                borderRadius: "12px",
                                margin: "10px 0px 0px 0px",
                                padding: "7.5px 15px 7.5px 15px",
                            }}>
                                <Checkbox 
                                    className="asset_specialty__checkbox intro_panel__checkbox"
                                    name={SINGLE_FAMILY_HOME}
                                    value="SingleFamilyHome"
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("SingleFamilyHome")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Single Family Home
                                </Checkbox>
                                <Checkbox 
                                    className="asset_specialty__checkbox"
                                    name={APARTMENT}
                                    value="Apartment"
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("Apartment")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Apartment
                                </Checkbox>
                                <Checkbox 
                                    className="asset_specialty__checkbox"
                                    name={TOWNHOME}
                                    value="Townhome"
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("Townhome")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Townhome
                                </Checkbox>
                                <Checkbox 
                                    className="asset_specialty__checkbox"
                                    name={DUPLEX}
                                    value="Duplex"
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("Duplex")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Duplex
                                </Checkbox>
                                <Checkbox 
                                    className="asset_specialty__checkbox"
                                    name={MULTIPLEX}
                                    value="Multiplex"
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("Multiplex")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Multiplex
                                </Checkbox>
                                <Checkbox 
                                    className="asset_specialty__checkbox"
                                    name={CONDOMINIUM}
                                    value="Condominium"
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("Condominium")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Condominium
                                </Checkbox>
                                <Checkbox 
                                    className="asset_specialty__checkbox"
                                    name={TRAILER}
                                    value="Trailer"
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("Trailer")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Trailer
                                </Checkbox>
                                <Checkbox 
                                    className="asset_specialty__checkbox"
                                    name={MANUFACTURED}
                                    value="Manufactured"
                                    defaultChecked={buyer[ASSET_INTERESTS] && buyer[ASSET_INTERESTS].includes("Manufactured")}
                                    onChange={() => {
                                        buyerForm[ASSET_INTERESTS] = getAssetInterestsValues();
                                        setBuyerForm(buyerForm);
                                    }}
                                >
                                    Manufactured
                                </Checkbox>
                            </Stack>
                        </div> :
                        <p className="intro_panel__text">
                            {renderAssetInterests()}
                        </p>
                    }
                </div>
                {
                    editProfile ?
                    <ButtonGroup style={{
                        display: "flex",
                        margin: "15px 0px 0px 0px",
                        justifyContent: "flex-end"
                    }}>
                        <Button 
                            className="negative_button hover" 
                            onMouseDown={() => setEditProfile(false)}
                            size='sm'
                        >
                            Cancel
                        </Button>
                        <Button 
                            className="positive_button hover" 
                            onMouseDown={() => {
                                if (Object.keys(buyerForm).length === 0) return;
                                if (!buyerForm) return;
                                handleUpdateUserProfile({...buyerForm}, "Saved", "Your changes were saved", () => {
                                setEditProfile(false);
                                setBuyerForm([]);
                            })}}
                            size='sm'
                        >Save</Button>
                    </ButtonGroup>
                    : null
                }
            </div>
            <div className="intro_panel__footer">
                <p className="intro_panel__footer_sublinks" onMouseDown={() => {navigate("/contact-us/" + user[ID])}}>
                    Contact Us
                </p>
                <p className="intro_panel__footer_dot_divider">
                •
                </p>
                <p className="intro_panel__footer_sublinks" onMouseDown={() => {navigate("/terms-and-agreements")}}>
                    Terms & Agreements
                </p>
                <p className="intro_panel__footer_dot_divider">
                •
                </p>
                <p className="intro_panel__footer_sublinks" onMouseDown={() => navigate("/")}>
                    Rezied Inc.
                </p>
            </div>
        </div>
    );
}

export default IntroPanel;