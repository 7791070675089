import React from 'react';

import './ExternalNavigationBar.css';

function ExternalNavigationBar(props) {

    return (
        <div style={{
            backgroundColor: "#05b96b",
            height: "50px",
            padding: "0px 50px 0px 50px",
            width: "100%",
        }}>
            <div 
                onMouseDown={() => window.location.href="/"}
                style={{
                    cursor: "pointer",
                    height: "50px",
                }}>
                <p className="external_navigation_bar__title">
                    Rezied
                </p>
            </div>
        </div>
    );
}

export default ExternalNavigationBar;