import React, { useState } from 'react';
import axios from 'axios';

import {
    PATCH,
    ID
} from '../../../utils/constants.js';

import {
    Button,
    IconButton,
    InputGroup,
    InputRightElement,
    Input,
    useToast
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { MdClose, MdEdit } from 'react-icons/md';

import './SecurityPage.css';

function UserSecuritySettingsPage(props) {

    const user = props.user;
    let userId = user[ID];
    const [showPasswordState, setShowPasswordState] = useState(false);
    const [passwordForm, setPasswordForm] = useState(null);
    const [confirmPasswordForm, setConfirmPasswordForm] = useState(null);

    const [hidePasswordForm, setHidePasswordForm] = useState(true);
    const [hideConfirmPasswordForm, setHideConfirmPasswordForm] = useState(true);

    const toast = useToast();
    const handleUpdatePassword = () => {

        if (!passwordForm) {
            toast({
                title: 'Error updating password',
                description: "Empty password",
                status: 'error',
                duration: 6000,
                isClosable: true,
                position: 'top'
            });
            return;
        }
        

        if (passwordForm !== confirmPasswordForm){
            toast({
                title: 'Error updating password',
                description: "Passwords do not match",
                status: 'error',
                duration: 6000,
                isClosable: true,
                position: 'top'
            });
            return;
        }

        const PATCH_TIMEOUT = 15000;
        axios({
            method: PATCH,
            url: '/api/v1/user/password/' + userId,
            timeout: PATCH_TIMEOUT,
            data: {
                password: passwordForm,
            }
        }).then(response => {
            if (200 === response.status) {
                toast({
                    title: 'Password Updated',
                    description: "We've updated your password",
                    status: 'success',
                    duration: 6000,
                    isClosable: true,
                    position: 'top'
                });
                setShowPasswordState(false);
            }
        }).catch(error => {
            // TODO
        })
    }

    return (
        <div style={{
            margin: "0px 0px 0px 10px",
            width: "calc(100% - 10px)"
        }}>
            <div>
                <div className="user_notifications_settings_page_information_tab">
                    <div style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                    }}>
                        <div>
                            <label className="user_notifications_settings_page_information_label">
                                PASSWORD
                            </label>
                            <div>
                                {
                                    showPasswordState ? 
                                    <InputGroup>
                                        <InputRightElement onMouseDown={() => setHidePasswordForm(!hidePasswordForm)}>
                                            {
                                                hidePasswordForm ?
                                                <ViewOffIcon color='grey'/> :
                                                <ViewIcon color='grey'/>
                                            }
                                        </InputRightElement>
                                        <Input
                                            type={hidePasswordForm ? "password" : "text"}
                                            onChange={(e) => {
                                                setPasswordForm(e.target.value);
                                            }}
                                            placeholder={"New password"}
                                            style={{
                                                border: "1px solid grey",
                                            }}
                                        >
                                        </Input>
                                    </InputGroup>
                                    : 
                                    <p>
                                        *********
                                    </p>
                                }
                            </div>
                            {
                                showPasswordState ?
                                <div className="user_notifications_settings_page_information_tab" style={{
                                    margin: "10px 0px 0px 0px",
                                }}>
                                    <div>
                                        <label className="user_notifications_settings_page_information_label">
                                            CONFIRM PASSWORD
                                        </label>
                                        <div>
                                            {
                                                showPasswordState ? 
                                                <InputGroup>
                                                <InputRightElement onMouseDown={() => setHideConfirmPasswordForm(!hideConfirmPasswordForm)}>
                                                    {
                                                        hideConfirmPasswordForm ?
                                                        <ViewOffIcon color='grey'/> :
                                                        <ViewIcon color='grey'/>
                                                    }
                                                </InputRightElement>
                                                    <Input
                                                        type={hideConfirmPasswordForm ? "password" : "text"}
                                                        onChange={(e) => {
                                                            setConfirmPasswordForm(e.target.value);
                                                        }}
                                                        placeholder={"Confirm new password"}
                                                        style={{
                                                            border: "1px solid grey",
                                                        }}
                                                    >
                                                    </Input>
                                                </InputGroup>
                                                : 
                                                <p>
                                                    *********
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </div> : null
                            }
                        </div>
                        {
                            showPasswordState ? 
                            <div>
                                <div style={{
                                    top: "20px",
                                    position: "absolute",
                                    right: "0"
                                }}>
                                    <IconButton size='sm' icon={<MdClose/>} onMouseDown={() => {
                                        setPasswordForm(null);
                                        setConfirmPasswordForm(null);
                                        setShowPasswordState(false)
                                    }}
                                    className="negative_button"/>
                                    <Button 
                                        className="positive_button"
                                        onMouseDown={() => {handleUpdatePassword()}}
                                        size='sm'
                                        style={{
                                            margin: "0px 0px 0px 10px",
                                        }}
                                    >
                                        Save
                                    </Button>
                                    
                                </div>
                            </div>
                                :
                            <MdEdit className="user_profile_information_settings_page_information_edit_button"
                            onMouseDown={() => {
                                setShowPasswordState(true);
                            }}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}

export default UserSecuritySettingsPage;