import React, { useState } from 'react';
import {
    USER_TYPE,
    AGENT,
    BUYER,
    ID
} from '../../../utils/constants.js';

import {
    Link,
    Switch
} from '@chakra-ui/react'

import './NotificationsPage.css';

function UserNotificationsSettingsPage(props) {

    const user = props.user;
    const updateUserNotifications = props.updateUserNotifications;
    const [form, setForm] = useState([]);

    let userId = user[ID];

    return (
        <div style={{
            margin: "0px 0px 0px 10px",
            position: "relative",
            width: "calc(100% - 10px)"
        }}>
            <div>
                <div className="user_notifications_settings_page_information_tab">
                    <div style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                    }}>
                        <div>
                            <label className="user_notifications_settings_page_information_label">
                                MARKETING
                            </label>
                            <p>
                                Marketing emails
                            </p>
                        </div>
                        <Switch 
                            id='email-alerts'
                            onChange={(e) => {
                                let currUserNotificationSettings = user.notifications_preferences;
                                currUserNotificationSettings.marketing_emails_enabled = e.target.checked;
                                form["notifications_preferences"] = currUserNotificationSettings;
                                updateUserNotifications({...form});
                            }}
                            defaultChecked={
                                user && 
                                user.notifications_preferences && 
                                user.notifications_preferences.marketing_emails_enabled === true}
                        />
                    </div>
                </div>
                <hr style={{
                    margin: "10px 0px 10px 0px",
                }}></hr>
                <div className="user_notifications_settings_page_information_tab">
                    <div style={{
                        alignItems: "center",
                        width: "100%"
                    }}>
                        <label className="user_notifications_settings_page_information_label">
                            CONNECTIONS
                        </label>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "10px 0px 0px 0px",
                        }}>
                            <p>
                                New Connections
                            </p>
                            <Switch 
                                id='email-alerts'
                                onChange={(e) => {
                                    let currUserNotificationSettings = user.notifications_preferences;
                                    currUserNotificationSettings.new_connections_emails_enabled = e.target.checked;
                                    form["notifications_preferences"] = currUserNotificationSettings;
                                    updateUserNotifications({...form});
                                }}
                                defaultChecked={
                                    user && 
                                    user.notifications_preferences && 
                                    user.notifications_preferences.new_connections_emails_enabled === true}
                            />
                        </div>
                        {
                            user[USER_TYPE] === BUYER ?
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "15px 0px 0px 0px",
                            }}>
                                <p>
                                    New Agent Offers
                                </p>
                                <Switch 
                                    id='email-alerts' 
                                    onChange={(e) => {
                                        let currUserNotificationSettings = user.notifications_preferences;
                                        currUserNotificationSettings.new_agent_offers_enabled = e.target.checked;
                                        form["notifications_preferences"] = currUserNotificationSettings;
                                        updateUserNotifications({...form});
                                    }}
                                    defaultChecked={
                                        user && 
                                        user.notifications_preferences && 
                                        user.notifications_preferences.new_agent_offers_enabled === true}
                                />
                            </div> : null
                        }
                    </div>
                </div>
                <div style={{
                    color: "grey",
                    fontSize: "0.9em",
                    margin: "50px 0px 0px 0px",
                }}>
                    We will always send you important emails regarding your account.
                </div>
            </div>
            
        </div>
    );

}

export default UserNotificationsSettingsPage;