import React, { useState } from 'react';
import axios from 'axios';

import { Button } from '@chakra-ui/react';

import {
    FIRST_NAME,
    EMAIL,
    LAST_NAME,
    USER_TYPE,
    PHONE_NUMBER,
    PATCH,
    ID
} from '../../../utils/constants.js';

import {
    IconButton,
    Input,
    InputGroup,
} from '@chakra-ui/react'
import { MdClose, MdEdit } from "react-icons/md";

import './ProfileInformationPage.css';

function UserProfileInformationSettingsPage(props) {

    const [user, setUser] = useState(props.user);
    const [userForm] = useState([]);

    const [showEditFirstName, setShowEditFirstName] = useState(false);
    const [showEditLastName, setShowEditLastName] = useState(false);
    const [showEditEmail, setShowEditEmail] = useState(false);
    const [showEditPhoneNumber, setShowEditPhoneNumber] = useState(false);

    let userId = user[ID];

    const fieldTabs = [
        FIRST_NAME,
        LAST_NAME,
        EMAIL,
        PHONE_NUMBER,
    ];

    const tabToShowStateMap = new Map([
        [FIRST_NAME, showEditFirstName],
        [LAST_NAME, showEditLastName],
        [EMAIL, showEditEmail],
        [PHONE_NUMBER, showEditPhoneNumber]
    ]);

    const tabToSetShowStateMap = new Map([
        [FIRST_NAME, setShowEditFirstName],
        [LAST_NAME, setShowEditLastName],
        [EMAIL, setShowEditEmail],
        [PHONE_NUMBER, setShowEditPhoneNumber]
    ]);

    const prettifyTabType = (tabType) => {
        switch(tabType) {
            case FIRST_NAME:
                return "FIRST NAME";
            case LAST_NAME:
                return "LAST NAME";
            case EMAIL:
                return "EMAIL";
            case PHONE_NUMBER:
                return "PHONE NUMBER";
            default:
                return "";
        }
    }

    const handleUserChange = (event) => {
        userForm[event.target.name] = event.target.value;
    }

    const handleUpdateUser = (name, onSuccessCallback) => {

        const PATCH_TIMEOUT = 10000;
        var data = {};
        data[name] = userForm[name];
        let stringifyData = JSON.stringify(data);
        axios({
            method: PATCH,
            url: '/api/v1/user/profile/' + userId,
            timeout: PATCH_TIMEOUT,
            data: stringifyData,
        }).then(response => {
            // Successful response. Close the modal and load the loan.
            if (response.status === 200) {
                userForm[name] = '';
                onSuccessCallback();
                // Update our user in localstorage.
                let updatedUser = response.data;
                setUser(updatedUser);
                localStorage.setItem("user", JSON.stringify(updatedUser));
            }
        }).catch(error => {
            // TODO
        })

    }

    const renderTabs = () => {

        let renderedTabs = [];
        for (var i = 0; i < fieldTabs.length; i++) {
            let tabType = fieldTabs[i];
            let showState = tabToShowStateMap.get(tabType);
            let setShowState = tabToSetShowStateMap.get(tabType);

            renderedTabs.push(
                <div key={tabType + "" + i}>
                    <div className="user_profile_information_settings_page_information_tab">
                        <div>
                            <label className="user_profile_information_settings_page_information_label">
                                {prettifyTabType(tabType)}
                            </label>
                            <div style={{
                                margin: "5px 0px 0px 0px",
                            }}>
                                {
                                    showState ?
                                    <InputGroup>
                                        <Input 
                                            name={tabType}
                                            onChange={handleUserChange}
                                            placeholder={user[tabType]} style={{
                                            border: "1px solid grey",
                                        }}>
                                        </Input>
                                    </InputGroup> :
                                    <p style={{
                                        padding: "8px 0px 8px 0px",
                                    }}>
                                        {user[tabType]}
                                    </p>
                                }
                            </div>
                        </div>
                        {
                            showState ? 
                            <div>
                                <div style={{
                                    bottom: "10px",
                                    position: "absolute",
                                    right: "0"
                                }}>
                                    <IconButton size='sm' icon={<MdClose/>} onMouseDown={() => {setShowState(false)}} className="negative_button"/>
                                    <Button 
                                        className="positive_button"
                                        onMouseDown={() => {
                                            handleUpdateUser(
                                                tabType,
                                                function callback() {setShowState(false);}
                                            );
                                        }}
                                        size='sm'
                                        style={{
                                            margin: "0px 0px 0px 10px",
                                        }}
                                    >
                                        Save
                                    </Button>
                                    
                                </div>
                            </div>
                                :
                            <MdEdit className="user_profile_information_settings_page_information_edit_button"
                            onMouseDown={() => {
                                setShowState(true);
                            }}/>
                        }
                    </div>
                    <hr style={{
                        margin: "10px 0px 10px 0px",
                    }}></hr>
                </div>
            );
        }

        return renderedTabs;
    }

    return (
        <div style={{
            margin: "0px 0px 0px 10px",
            width: "calc(100% - 10px)"
        }}>
            {renderTabs()}
            <div className="user_profile_information_settings_page_information_tab">
                <label>
                    User Type
                </label>
                <p>
                    {user[USER_TYPE]}
                </p>
            </div>
        </div>
    );

}

export default UserProfileInformationSettingsPage;