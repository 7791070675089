import React, { useState } from 'react';

import {
    Checkbox,
    IconButton,
    Input,
    InputGroup,
    ButtonGroup,
    Select,
    Stack,
    Textarea
} from '@chakra-ui/react';

import {
    ABOUT_DESCRIPTION,
    APARTMENT,
    TOWNHOME,
    DUPLEX,
    MULTIPLEX,
    CONDOMINIUM,
    TRAILER,
    MANUFACTURED,
    FIRM,
    LICENSE_TYPE,
    REPRESENTATION_TYPE,
    REPRESENTATION_STYLE,
    WORKING_STYLE,
    SALE_EXPERTISE,
    SHORT_SALE,
    FORECLOSURE,
    ASSET_SPECIALTY,
    SINGLE_FAMILY_HOME
} from '../../../utils/constants.js';

import { FaCheck } from 'react-icons/fa6';
import { MdClose, MdEdit } from 'react-icons/md';

import SidePanelWrapper from '../../common/SidePanelWrapper.js';

function SidePanel(props) {

    const agent = props.agent;
    const handleUpdateUserProfile = props.handleUpdateUserProfile;
    const [agentForm, setAgentForm] = useState([]);
    const [editProfile, setEditProfile] = useState(false);

    const [aboutDescriptionTextareaValue, setAboutDescriptionTextareaValue] = useState('');

    const MAX_ABOUT_DESCRIPTION_LENGTH = 500;

    const prettifyLicenseType = (val) => {
        switch (val) {
            case "ManagingBroker":
                return "Managing Broker";
            case "AssociateBroker":
                return "Associate Broker";
            default:
        }
        return val;
    }

    const renderProfileField = (field) => {
        if (!agent || null === agent[field] || undefined === agent[field] || "" === agent[field]) {
            return "-";
        }
        if (field === LICENSE_TYPE) {
            return prettifyLicenseType(agent[field]);
        }
        return agent[field];
    }

    const renderWorkingStyle = (workingStyle) => {
        if (!agent || !agent[WORKING_STYLE] || null === agent[WORKING_STYLE]) {
            return "-";
        }
        switch(agent[WORKING_STYLE]) {
            case "PartOfTeam":
                return "Part of a team";
            case "SoleLead":
                return "Sole lead";
            default:
                return "-";
        };
    }

    const getPrettifySaleExpertise = (val) => {
        switch (val) {
            case "ShortSale":
                return "Short Sale";
            default:
                return val;
        }
    }

    const renderSaleExpertise = () => {
        if (!agent || !agent[SALE_EXPERTISE] || null === agent[SALE_EXPERTISE]) {
            return "-";
        }
        let saleExpertise = agent[SALE_EXPERTISE];

        let result = [];
        for (var i = 0; i < saleExpertise.length; i++) {
            result.push(getPrettifySaleExpertise(saleExpertise[i]));
            result.push(<br></br>);
        }
        return result;
    }

    const getSaleExpertiseValues = () => {

        let saleExpertiseValues = [];
        let saleExpertiseCheckboxes = document.getElementsByClassName("sale_expertise__checkbox");
        for (let i = 0; i < saleExpertiseCheckboxes.length; i++) {
            let chakraCheckboxElement = saleExpertiseCheckboxes[i];
            // The actual element is the first child, the input.
            let checkboxInput = chakraCheckboxElement.children[0];
            if (checkboxInput.checked === true) {
                saleExpertiseValues.push(checkboxInput.value);
            }
        }
        return saleExpertiseValues;
    }

    const renderAssetSpecialty = () => {
        if (!agent || !agent[ASSET_SPECIALTY] || null === agent[ASSET_SPECIALTY]) {
            return "-";
        }
        let assetSpecialty = agent[ASSET_SPECIALTY];

        let result = [];
        for (var i = 0; i < assetSpecialty.length; i++) {
            result.push(getPrettifyAssetSpecialty(assetSpecialty[i]));
            result.push(<br></br>);
        }
        return result;
    }

    const getPrettifyAssetSpecialty = (val) => {
        switch (val) {
            case SINGLE_FAMILY_HOME:
                return "Single Family Home";
            default:
                return val;
        }
    }

    const getAssetSpecialtyValues = () => {
        let assetSpecialtyValues = [];
        let assetSpecialtyCheckboxes = document.getElementsByClassName("asset_specialty__checkbox");
        for (let i = 0; i < assetSpecialtyCheckboxes.length; i++) {
            let chakraCheckboxElement = assetSpecialtyCheckboxes[i];
            // The actual element is the first child, the input.
            let checkboxInput = chakraCheckboxElement.children[0];
            if (checkboxInput.checked === true) {
                assetSpecialtyValues.push(checkboxInput.value);
            }
        }
        return assetSpecialtyValues;
    }

    return (
        <SidePanelWrapper>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
            }}>
                <p style={{
                    color: "grey",
                    fontSize: "0.8em",
                }}>
                    PROFILE
                </p>
                <ButtonGroup style={{
                }}>
                    {
                        editProfile ?
                        <>
                            <IconButton
                                className="hover"
                                size='sm'
                                icon={<MdClose/>}
                                isRound
                                onMouseDown={() => setEditProfile(false)}
                                style={{
                                    backgroundColor: "transparent",
                                    border: "1px solid #05b96b",
                                    color: "#05b96b",
                                }}
                            />
                            <IconButton
                                className="hover"
                                icon={<FaCheck/>}
                                size='sm'
                                isRound
                                onMouseDown={() => {
                                    if (Object.keys(agentForm).length === 0) {
                                        return;
                                    }
                                    handleUpdateUserProfile({...agentForm}, "Saved", "Your changes were saved", () => {
                                        setEditProfile(false);
                                        setAgentForm([]);
                                    })}
                                }
                                style={{
                                    backgroundColor: "#05b96b",
                                    color: "white",
                                }}
                            /> 
                        </>: 
                        <IconButton
                            className="edit_profile_button"
                            icon={<MdEdit/>}
                            isRound
                            onMouseDown={() => setEditProfile(true)}
                            size='sm'
                            style={{
                                backgroundColor: "#05b96b",
                                color: "white",
                                cursor: "pointer",
                                transition: "0.3s",
                            }} 
                        />
                    }
                </ButtonGroup>
            </div>
            <div style={{
                height: "calc(100vh - 140px)",
                overflowY: "scroll",
                paddingBottom: "30px",
            }}>
                <div className="profile-tab-info-box-icon-box">
                    <p className="profile-tab-info-box-icon-title-text">
                        ABOUT
                    </p>
                    {
                        editProfile ?
                        <div style={{
                            display: "block",
                            margin: "5px 0px 0px 0px",
                            width: "100%",
                        }}>
                            <InputGroup>
                                <Textarea
                                    name={ABOUT_DESCRIPTION}
                                    className="default_input"
                                    maxLength={MAX_ABOUT_DESCRIPTION_LENGTH}
                                    placeholder={renderProfileField(ABOUT_DESCRIPTION)}
                                    onChange={(e) => {
                                        setAboutDescriptionTextareaValue(e.target.value);
                                        agentForm[ABOUT_DESCRIPTION] = e.target.value;
                                        setAgentForm(agentForm);
                                    }}
                                    resize={'none'}
                                    size='sm'
                                    style={{
                                        height: "250px",
                                        width: "100%",
                                    }}
                                >
                                </Textarea>
                                
                            </InputGroup>
                            <p style={{
                                color: "grey",
                                fontSize: "0.8em",
                                margin: "5px 0px 0px 0px",
                                textAlign: "end",
                                width: "100%",
                            }}>
                                {MAX_ABOUT_DESCRIPTION_LENGTH - parseInt(aboutDescriptionTextareaValue.length)} / {MAX_ABOUT_DESCRIPTION_LENGTH} characters left
                            </p>
                        </div> :
                        <p style={{
                            // lineHeight: "20px",
                        }}>
                            {renderProfileField(ABOUT_DESCRIPTION)}
                        </p>
                    }
                </div>
                <div className="profile-tab-info-box-icon-box">
                    <label className="profile-tab-info-box-icon-title-text">
                        FIRM
                    </label>
                    {
                        editProfile ?
                        <div style={{
                            margin: "5px 0px 0px 0px",
                            width: "100%",
                        }}>
                            <InputGroup>
                                <Input
                                    name={FIRM}
                                    className="default_input"
                                    placeholder={renderProfileField(FIRM)}
                                    onChange={(e) => {
                                        agentForm[FIRM] = e.target.value;
                                        setAgentForm(agentForm);
                                    }}
                                    size='sm'
                                >
                                </Input>
                            </InputGroup>
                        </div> :
                        <p>
                            {renderProfileField(FIRM)}
                        </p>
                    }
                </div>
                <div className="profile-tab-info-box-icon-box">
                    <label className="profile-tab-info-box-icon-title-text">
                        LICENSE
                    </label>
                    {
                        editProfile ?
                        <div style={{
                            margin: "5px 0px 0px 0px",
                            width: "100%",
                        }}>
                            <InputGroup>
                                <Select
                                    name={LICENSE_TYPE}
                                    className="default_input"
                                    onChange={(e) => {
                                        agentForm[LICENSE_TYPE] = e.target.value;
                                        setAgentForm(agentForm);
                                    }}
                                    size='sm'
                                >
                                    <option selected={renderProfileField(LICENSE_TYPE) === "-" ? "selected" : ""} hidden>-</option>
                                    <option selected={renderProfileField(LICENSE_TYPE) === "Managing Broker" ? "selected" : ""} value="ManagingBroker">Managing Broker</option>
                                    <option selected={renderProfileField(LICENSE_TYPE) === "Associate Broker" ? "selected" : ""} value="AssociateBroker">Associate Broker</option>
                                    <option selected={renderProfileField(LICENSE_TYPE) === "Salesperson" ? "selected" : ""} value="Salesperson">Salesperson</option>
                                </Select>
                            </InputGroup>
                        </div> :
                        <p>
                            {renderProfileField(LICENSE_TYPE)}
                        </p>
                    }
                </div>
                <div className="profile-tab-info-box-icon-box">
                    <label className="profile-tab-info-box-icon-title-text">
                        REPRESENTATION STYLE
                    </label>
                    {
                        editProfile ?
                        <div style={{
                            margin: "5px 0px 0px 0px",
                            width: "100%",
                        }}>
                            <InputGroup>
                                <Select
                                    name={REPRESENTATION_STYLE}
                                    className="default_input"
                                    onChange={(e) => {
                                        agentForm[REPRESENTATION_STYLE] = e.target.value;
                                        setAgentForm(agentForm);
                                    }}
                                    size='sm'
                                >
                                    <option selected={renderProfileField(REPRESENTATION_STYLE) === "-" ? "selected" : ""} hidden>-</option>
                                    <option selected={renderProfileField(REPRESENTATION_STYLE) === "Customer" ? "selected" : ""} value="Customer">Customer</option>
                                    <option selected={renderProfileField(REPRESENTATION_STYLE) === "Client" ? "selected" : ""} value="Client">Client</option>
                                </Select>
                            </InputGroup>
                        </div> :
                        <p>
                            {renderProfileField(REPRESENTATION_STYLE)}
                        </p>
                    }
                </div>
                <div className="profile-tab-info-box-icon-box">
                    <label className="profile-tab-info-box-icon-title-text">
                        WORKING STYLE
                    </label>
                    {
                        editProfile ?
                        <div style={{
                            margin: "5px 0px 0px 0px",
                            width: "100%",
                        }}>
                            <InputGroup>
                                <Select
                                    name={WORKING_STYLE}
                                    className="default_input"
                                    onChange={(e) => {
                                        agentForm[WORKING_STYLE] = e.target.value;
                                        setAgentForm(agentForm);
                                    }}
                                    size='sm'
                                >
                                    <option selected={renderWorkingStyle() === "-" ? "selected" : ""} hidden>-</option>
                                    <option selected={renderWorkingStyle() === "Part of a team" ? "selected" : ""} value="PartOfTeam">Part of a team</option>
                                    <option selected={renderWorkingStyle() === "Sole lead" ? "selected" : ""} value="SoleLead">Sole lead</option>
                                </Select>
                            </InputGroup>
                        </div> :
                        <p>
                            {renderWorkingStyle()}
                        </p>
                    }
                </div>
                <div className="profile-tab-info-box-icon-box">
                    <label className="profile-tab-info-box-icon-title-text">
                        SALE EXPERTISE
                    </label>
                    {
                        editProfile ?
                        <div style={{
                            margin: "5px 0px 0px 0px",
                            width: "100%",
                        }}>
                            <Stack>
                                <Checkbox 
                                    className="sale_expertise__checkbox"
                                    name={SHORT_SALE}
                                    value="ShortSale"
                                    defaultChecked={agent[SALE_EXPERTISE] && agent[SALE_EXPERTISE].includes("ShortSale")}
                                    onChange={() => {
                                        agentForm[SALE_EXPERTISE] = getSaleExpertiseValues();
                                        setAgentForm(agentForm);
                                    }}
                                >
                                    Short Sale
                                </Checkbox>
                                <Checkbox 
                                    className="sale_expertise__checkbox"
                                    name={FORECLOSURE}
                                    value="Foreclosure"
                                    defaultChecked={agent[SALE_EXPERTISE] && agent[SALE_EXPERTISE].includes("Foreclosure")}
                                    onChange={() => {
                                        agentForm[SALE_EXPERTISE] = getSaleExpertiseValues();
                                        setAgentForm(agentForm);
                                    }}
                                >
                                    Foreclosure
                                </Checkbox>
                            </Stack>
                        </div> :
                        <p>
                            {renderSaleExpertise()}
                        </p>
                    }
                </div>
                <div className="profile-tab-info-box-icon-box">
                    <label className="profile-tab-info-box-icon-title-text">
                        ASSET SPECIALITIES
                    </label>
                    {
                        editProfile ?
                        <div style={{
                            margin: "5px 0px 0px 0px",
                            width: "100%",
                        }}>
                            <Stack>
                                <Checkbox 
                                    className="asset_specialty__checkbox"
                                    name={SINGLE_FAMILY_HOME}
                                    value="SingleFamilyHome"
                                    defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("SingleFamilyHome")}
                                    onChange={() => {
                                        agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                        setAgentForm(agentForm);
                                    }}
                                >
                                    Single Family Home
                                </Checkbox>
                                <Checkbox 
                                    className="asset_specialty__checkbox"
                                    name={APARTMENT}
                                    value="Apartment"
                                    defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("Apartment")}
                                    onChange={() => {
                                        agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                        setAgentForm(agentForm);
                                    }}
                                >
                                    Apartment
                                </Checkbox>
                                <Checkbox 
                                    className="asset_specialty__checkbox"
                                    name={TOWNHOME}
                                    value="Townhome"
                                    defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("Townhome")}
                                    onChange={() => {
                                        agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                        setAgentForm(agentForm);
                                    }}
                                >
                                    Townhome
                                </Checkbox>
                                <Checkbox 
                                    className="asset_specialty__checkbox"
                                    name={DUPLEX}
                                    value="Duplex"
                                    defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("Duplex")}
                                    onChange={() => {
                                        agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                        setAgentForm(agentForm);
                                    }}
                                >
                                    Duplex
                                </Checkbox>
                                <Checkbox 
                                    className="asset_specialty__checkbox"
                                    name={MULTIPLEX}
                                    value="Multiplex"
                                    defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("Multiplex")}
                                    onChange={() => {
                                        agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                        setAgentForm(agentForm);
                                    }}
                                >
                                    Multiplex
                                </Checkbox>
                                <Checkbox 
                                    className="asset_specialty__checkbox"
                                    name={CONDOMINIUM}
                                    value="Condominium"
                                    defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("Condominium")}
                                    onChange={() => {
                                        agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                        setAgentForm(agentForm);
                                    }}
                                >
                                    Condominium
                                </Checkbox>
                                <Checkbox 
                                    className="asset_specialty__checkbox"
                                    name={TRAILER}
                                    value="Trailer"
                                    defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("Trailer")}
                                    onChange={() => {
                                        agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                        setAgentForm(agentForm);
                                    }}
                                >
                                    Trailer
                                </Checkbox>
                                <Checkbox 
                                    className="asset_specialty__checkbox"
                                    name={MANUFACTURED}
                                    value="Manufactured"
                                    defaultChecked={agent[ASSET_SPECIALTY] && agent[ASSET_SPECIALTY].includes("Manufactured")}
                                    onChange={() => {
                                        agentForm[ASSET_SPECIALTY] = getAssetSpecialtyValues();
                                        setAgentForm(agentForm);
                                    }}
                                >
                                    Manufactured
                                </Checkbox>
                            </Stack>
                        </div> :
                        <p>
                            {renderAssetSpecialty()}
                        </p>
                    }
                </div>
            </div>
        </SidePanelWrapper>
    )
}

export default SidePanel;