import React from 'react';

import AgentNavigationBar from './NavigationBar.js';
import ViewNavigationBar from '../../common/ViewNavigationBar';

import {
    AGENT
} from '../../../utils/constants.js';

function AgentDashboardParentPage(props) {

    const user = props.user;
    const tab = props.tab;

    return (
        <div style={{
            backgroundColor: "#ffffff",
            width: "100%",
        }}>
            <ViewNavigationBar user={user} viewType={AGENT} displaySwitchButton={true}/>
            <div style={{
                display: "flex",
            }}>
                <AgentNavigationBar user={user} currTab={tab}/>
                <div style={{
                    display: "flex",
                    padding: "15px 25px 15px 15px",
                    width: "calc(100% - 210px)",
                }}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default AgentDashboardParentPage;