import axios from 'axios';

import {
    DEFAULT_GET_TIMEOUT,
    GET
} from '../../../utils/constants.js';

export function axiosGetUser(userId) {
    return axios({
        method: GET,
        url: '/api/v1/user/' + userId,
        timeout: DEFAULT_GET_TIMEOUT,
        withCredentials: true,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true,
            'Content-Type': 'application/json'
        }
    }).then(response => {
        if (200 === response.status) {
            let user = response.data;
            return user;
        }
    }).catch(error => {
        if (303 === error.response.status) {
            // TODO
            // window.location.href = "/auth/login";
        }
        // TODO
        return error;
    });
}

export function axiosGetAgent(agentId) {
    return axios({
        method: GET,
        url: '/api/v1/user/agent/profile/' + agentId,
        timeout: DEFAULT_GET_TIMEOUT,
        withCredentials: true,
    }).then(response => {
        let agent = response.data;
        return agent;
    }).catch(error => {
        // TODO
        return error;
    })
}

export function axiosGetBuyer(buyerId) {
    return axios({
        method: GET,
        url: '/api/v1/user/buyer/profile/' + buyerId,
        timeout: DEFAULT_GET_TIMEOUT,
        withCredentials: true,
    }).then(response => {
        let buyer = response.data;
        return buyer;
    }).catch(error => {
        return error;
    })
}


export async function axiosGetUserChatToken(userId, userType) {
    return axios({
        method: GET,
        url: "/api/v1/user/chat/token/" + userId + '?user_type=' + userType,
        timeout: DEFAULT_GET_TIMEOUT,
        withCredentials: true,
    }).then(response => {
        if (200 === response.status) {
            let userChatToken = response.data;
            return userChatToken;
        }
    }).catch(error => {
        return error;
    })
}

export function getChatTokenAndRedirectPage(chatClient, userId, userName, userType, location) {
    axiosGetUserChatToken(userId, userType).then(chatToken => {
        localStorage.setItem("chatToken", chatToken);

        const chatUser = {
            id: userType.toLowerCase()+"_"+userId,
            name: userName,
            // image: `https://getstream.io/random_png/?id=${userId}&name=${user[FIRST_NAME]}`,
        };
        
        chatClient.connectUser(chatUser, chatToken);
        window.location.href = location;
        // await connectionPromise;
        // chatClient.disconnectUser();
    }).catch(error => {
    });
}