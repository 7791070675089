import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { 
    Avatar,
    Button,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useToast
} from '@chakra-ui/react';

import { MdEdit } from 'react-icons/md';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import { FaEye } from 'react-icons/fa6';

import { checkRequireAgentSignUp, loadMime } from '../../../utils/utility.js';

import {
    GET,
    EMAIL,
    FIRST_NAME,    
    LAST_NAME,
    ID,
    PHONE_NUMBER,
    PROFILE,
    PROFILE_PICTURE_URL,
    PATCH,
    PUT,
    DEFAULT_PUT_TIMEOUT,
    DEFAULT_GET_TIMEOUT,
    DEFAULT_PATCH_TIMEOUT
} from '../../../utils/constants.js';

import AgentDashboardParentPage from '../utils/AgentDashboardParentPage.js';
import AboutPanel from './AboutPanel.js';

import AgentProfilePage from '../../common/AgentProfile.js';
import SidePanel from './SidePanel.js';
import MainPanel from '../../common/MainPanel.js';

import { axiosGetAgent } from '../../common/methods/methods.js';
import { chaoticOrbit } from 'ldrs'
import AgentSignUpInformationModal from '../utils/AgentSignUpInformationModal.js';
import Loader from '../../common/Settings/Loader.js';

import './Page.css';
import '../../Generic.css';

function AgentProfileDashboardPage(props) {

    const [user, setUser] = useState(null);
    const [showEditProfilePictureButton, setShowEditProfilePictureButton] = useState(false);

    const toast = useToast();
    const [showExternalProfileModal, setShowExternalProfileModal] = useState(false);
    const [agent, setAgent] = useState(null);

    const [profilePictureUploadLoading, setProfilePictureUploadLoading] = useState(false);
    const [triggerFetchAgentProfile, setTriggerFetchAgentProfile] = useState(false);

    const [triggerFetchUser, setTriggerFetchUser] = useState(false);
    const [userLoading, setUserLoading] = useState(true);

    const [finishSignUpFormAgent, setFinishSignUpFormAgent] = useState(null);
    const [finishSignUpFormLoading, setFinishSignUpFormLoading] = useState(true);

    const urlParams = useParams();
    const userId = urlParams[ID];

    chaoticOrbit.register()

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            let user = response.data;
            setUser(user);
            setUserLoading(false);
            setTimeout(function() {
                setProfilePictureUploadLoading(false);
            }, 500);
        }).catch(error => {
            // TODO
        });
    }, [userId, triggerFetchUser]);

    useEffect(() => {
        axiosGetAgent(userId).then(agent => {
            if (agent) {
                setAgent(agent);
                if (checkRequireAgentSignUp(agent) === true) {
                    setFinishSignUpFormAgent(agent);
                };
            }
            setFinishSignUpFormLoading(false);
        }).catch(error => {
            setFinishSignUpFormLoading(false);
        });
    }, [userId, triggerFetchAgentProfile])

    const handleUpdateUserProfilePicture = (key) => {
        axios({
            method: PATCH,
            url: '/api/v1/user/profile/' + userId,
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: {
                "profile_picture_url_key": key
            },
        }).then(response => {
            setTriggerFetchUser(!triggerFetchUser);
        }).catch(error => {
            // TODO
        }, []);
    }

    const handleUpdateUserProfile = (data, toastTitle, toastDescription, callback) => {
        axios({
            method: PATCH,
            url: '/api/v1/user/agent/profile/' + userId,
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: data,
        }).then(response => {
            setTriggerFetchAgentProfile(!triggerFetchAgentProfile);
            toast({
                title: toastTitle,
                description: toastDescription,
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
            callback();
        }).catch(error => {
            // TODO
        }, []);
    }

    const handleProfilePictureInputChange = (e) => {
        
        setProfilePictureUploadLoading(true);
        let file = e.target.files[0];
        // Set our display as the new picture.
        loadMime(file, function(fileMimeType) {
            // Handle saving the file to our server in the background.
            axios({
                method: GET,
                url: '/api/v1/user/profile/picture/uploadURL?id=' + userId + "&mime_type=" + fileMimeType,
                timeout: DEFAULT_GET_TIMEOUT,
            }).then(response => {
                let uploadProfilePicture = response.data;
                let uploadSignedURL = uploadProfilePicture["upload_signed_url"];
                let storageKey = uploadProfilePicture["storage_key"];
                axios({
                    method: PUT,
                    url: uploadSignedURL,
                    timeout: DEFAULT_PUT_TIMEOUT,
                    data: file,
                    headers: { 'Content-Type': 'application/octet-stream' }
                }).then(response=> {
                    if (200 === response.status) {
                        handleUpdateUserProfilePicture(storageKey);
                    }
                }).catch(error=> {
                    // TODO
                });
                e.target.value = '';
            }).catch(error => {
                // TODO
            });
        }); 
    }

    const renderExternalProfileModal = () => {

        // Create our composite agent.
        let compositeAgent = {...agent};
        compositeAgent[FIRST_NAME] = user[FIRST_NAME];
        compositeAgent[LAST_NAME] = user[LAST_NAME];
        compositeAgent[PROFILE_PICTURE_URL] = user[PROFILE_PICTURE_URL];

        return (
            <Modal 
                isOpen={showExternalProfileModal}
                isCentered
                onClose={() => setShowExternalProfileModal(false)} 
                size='6xl' 
                maxHeight='400px !important'
                style={{
                }}
            >
                <ModalOverlay />
                <ModalContent style={{
                    backgroundColor: "white",
                    height: "calc(100vh - 100px)",
                    paddingBottom: "0px",
                    paddingTop: "0px"
                }}>
                    <ModalHeader style={{
                        paddingBottom: "0px"
                    }}>External View</ModalHeader>
                    <ModalCloseButton onClose={() => setShowExternalProfileModal(false)} onMouseDown={() => setShowExternalProfileModal(false)}/>
                    <ModalBody style={{
                        paddingBottom: "0px",
                        paddingTop: "0px",
                    }}>
                        <AgentProfilePage agent={compositeAgent} externalView={true}/>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    }

    if (userLoading) return;

    return (
        <AgentDashboardParentPage user={user} tab={PROFILE}>
            {
                finishSignUpFormLoading ?
                <div style={{
                    margin: "auto",
                }}>
                    <Loader/>
                </div> :
                <>
                    {
                        finishSignUpFormAgent ?
                        <AgentSignUpInformationModal
                            user={finishSignUpFormAgent}
                            setFinishSignUpFormAgent={setFinishSignUpFormAgent}
                        /> : null
                    }
                    <MainPanel>
                        <div style={{
                            display: "flex",
                        }}>
                            <div 
                                onMouseEnter={() => {
                                    setShowEditProfilePictureButton(true);
                                }}
                                onMouseLeave={() => {
                                    setShowEditProfilePictureButton(false);
                                }}
                                style={{
                                    borderRadius: "50%",
                                    position: "relative",
                                    pointerEvents: profilePictureUploadLoading ? "none" : "",
                                    textAlign: "center",
                                    width: "150px",
                                }}
                            >
                                <div style={{
                                    height: "150px",
                                    opacity: profilePictureUploadLoading ? "0.3" : "1.0",
                                    width: "150px",
                                }}>
                                    {
                                        profilePictureUploadLoading ?
                                        // Default values shown
                                        <l-chaotic-orbit
                                            size="35"
                                            speed="1.5" 
                                            color="black" 
                                            style={{
                                                position: "absolute",
                                                left: "calc(50% - 17.5px)",
                                                top: "calc(50% - 17.5px)",
                                            }}
                                        ></l-chaotic-orbit> : null
                                    }
                                    <div style={{
                                        backgroundColor: "#f6f6f6",
                                        borderRadius: "50%",
                                        height: "150px",
                                        width: "150px",
                                    }}>
                                        {
                                            user && user[PROFILE_PICTURE_URL] ?
                                            <Image
                                                borderRadius='full'
                                                src={user[PROFILE_PICTURE_URL]}
                                                alt={user[FIRST_NAME] + " " + user[LAST_NAME]}
                                                style={{
                                                    objectFit: "cover",
                                                    overflow: "hidden",
                                                    height: "150px",
                                                    width: "100%",
                                                }}
                                            /> :
                                            <Avatar 
                                                boxSize='150px'    
                                            />
                                        }
                                    </div>
                                </div>
                                {
                                    showEditProfilePictureButton ?
                                    <div style={{
                                        position: "relative",
                                        width: "100%",
                                    }}>
                                        <label htmlFor="upload-profile-picture">
                                            <MdEdit
                                                component="label"
                                                style={{
                                                    backgroundColor: "#dddddd",
                                                    borderRadius: "25px",
                                                    color: "black",
                                                    cursor: "pointer",
                                                    margin: "-40px 0px 0px 0px",
                                                    padding: "5px",
                                                    height: "30px",
                                                    width: "30px",
                                                    position: "absolute",
                                                    right: "10px",
                                                }}
                                            />
                                            
                                        </label>

                                    </div> : null
                                }
                                <input 
                                    accept="image/png,image/jpg,image/jpeg"
                                    id="upload-profile-picture"
                                    onChange={handleProfilePictureInputChange}
                                    style={{
                                        display: "none",
                                    }}
                                    type="file"
                                ></input>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "50px 0px 0px 20px",
                                position: "relative",
                                width: "calc(100% - 175px)",
                            }}>
                                <div style={{
                                    bottom: "0",
                                    position: "absolute",
                                }}>
                                    <p style={{
                                        fontSize: "2.0em",
                                        fontWeight: "bold",
                                    }}>
                                        {user[FIRST_NAME]} {user[LAST_NAME]}
                                    </p>
                                    <div style={{
                                        display: "flex",
                                    }}>
                                        <EmailIcon style={{
                                            color: "grey",
                                            height: "15px",
                                            width: "15px"
                                        }}/>
                                        <p style={{
                                            color: "#5A5A5A",
                                            fontSize: "0.9em",
                                            lineHeight: "15px",
                                            margin: "0px 0px 0px 7.5px"
                                        }}>
                                            {user[EMAIL]}
                                        </p>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        margin: "10px 0px 0px 0px",
                                    }}>
                                        <PhoneIcon style={{
                                            color: "grey",
                                            height: "15px",
                                            width: "15px"
                                        }}/>
                                        <p style={{
                                            color: "#5A5A5A",
                                            fontSize: "0.9em",
                                            lineHeight: "15px",
                                            margin: "0px 0px 0px 7.5px"
                                        }}>
                                            {user[PHONE_NUMBER]}
                                        </p>
                                    </div>
                                </div>
                                <div style={{
                                    bottom: "0",
                                    position: "absolute",
                                    right: "0",
                                    margin: "75px 0px 0px 0px",
                                }}>
                                    <Button className="positive_button hover" leftIcon={<FaEye/>} onMouseDown={() => setShowExternalProfileModal(true)} size='sm'>
                                        Client view
                                    </Button>
                                    {renderExternalProfileModal()}
                                </div>
                            </div>
                        </div>
                        <div style={{
                            margin: "40px 0px 0px 0px",
                        }}>
                            <AboutPanel user={user} agent={agent}/>
                        </div>
                    </MainPanel>
                    <SidePanel agent={agent} handleUpdateUserProfile={handleUpdateUserProfile}/>
                </>
            }
        </AgentDashboardParentPage>
    );
}

export default AgentProfileDashboardPage;