import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { 
    Badge,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    Image,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
 } from '@chakra-ui/react' 
import { Icon, LockIcon } from '@chakra-ui/icons'
import { FaUser, FaUserTie } from 'react-icons/fa6';

import useAuth from './auth/useAuth.js';

import { StreamChat } from 'stream-chat';

import { dotPulse } from 'ldrs';

import Footer from './Footer.js';
import NavigationBar from './NavigationBar.js';

import './LoginPage.css';
import './Generic.css';

import {
    AGENT,
    BOTH,
    BUYER,
    FIRST_NAME,
    ID,
    LAST_NAME,
    FORM_EMAIL,
    FORM_PASSWORD,
    USER_TYPE,
    PROFILE_PICTURE_URL,
    PHONE_NUMBER,
} from '../utils/constants.js';

const chatClient = StreamChat.getInstance("ufb64fqzh9zr");

function LoginPage(props) {

    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();

    // Our sign up form. We use this object to track the fields as the user
    // sets them.
    const [form] = useState([]);
    const [user, setUser] = useState(null);
    const [loginLoading, setLoginLoading] = useState(false);

    const [showRenderChooseUserTypeModal, setShowRenderChooseUserTypeModal] = useState(false);

    dotPulse.register();

    useEffect(() => {
        const handleKeyDown = (e) => {
            const key = e.key;
            if (key === "Enter") {
                handleLoginFormSubmit();
            }
        };
        document.addEventListener('keydown', handleKeyDown, true);
    
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    
    }, []);

    /* Name: handleLoginFormSubmit
     * Description: Handles the submission of our form. It uses the "form" 
     *              state object to track the values entered by our user.
     *              It makes a POST request to our signup REST API.
     * Returns: None
     */
    const handleLoginFormSubmit = () => {

        setLoginLoading(true);
        login(form[FORM_EMAIL], form[PHONE_NUMBER], form[FORM_PASSWORD]).then((aUser) => {
            setUser(aUser);
            let userId = aUser[ID];
            let userType = aUser[USER_TYPE];
            localStorage.setItem("chatToken", aUser.chat_token);
    
            const chatUser = {
                id: userType.toLowerCase()+"_"+userId,
                name: aUser[FIRST_NAME],
                image: aUser[PROFILE_PICTURE_URL],
            };
            
            const connectionPromise = chatClient.connectUser(chatUser, aUser.chat_token);

            switch(userType) {
                case AGENT:
                    navigate('/user/agent/dashboard/leads/' + userId);
                    return;
                case BUYER:
                    navigate('/user/buyer/dashboard/homes/' + userId);
                    return;
                // If they are both agent and buyer prompt to ask which
                // view they wish to visit.
                case BOTH:
                    setShowRenderChooseUserTypeModal(true);
                    return;
                // Can't be null. Maintain not null constraint on database.
                default:
            }
        }).catch(error => {
            setLoginLoading(false);
        })
    }

    const renderChooseUserTypeModal = () => {
        return (
            <Modal
                isCentered
                isOpen={showRenderChooseUserTypeModal}
                onClose={() => {
                    setShowRenderChooseUserTypeModal(false)
                    setLoginLoading(false);
                }}
                size='xs'
            >
                <ModalOverlay/>
                <ModalContent style={{
                    padding: "0px 0px 10px 0px"
                }}>
                    {/* <ModalHeader>Welcome back,</ModalHeader> */}
                    <ModalCloseButton onClose={() => {
                        setShowRenderChooseUserTypeModal(false);
                        setLoginLoading(false);
                    }} onMouseDown={() => {
                        setShowRenderChooseUserTypeModal(false);
                        setLoginLoading(false);
                    }}/>
                    <ModalBody style={{
                        paddingTop: "30px",
                    }}>
                        <div style={{
                            display: "flex",
                        }}>
                            {
                                <Image
                                    borderRadius='full'
                                    boxSize='55px'
                                    src={user ? user[PROFILE_PICTURE_URL] : ''}
                                    style={{
                                    }}
                                    alt={user ? user[FIRST_NAME] + " " + user[LAST_NAME] : 'Name'}
                                /> 
                            }
                            
                            <div style={{
                                margin: "0px 0px 0px 15px",
                            }}>
                                <p style={{
                                    fontSize: "1.4em",
                                    fontWeight: "bold",
                                }}>
                                    Welcome {user ? user[FIRST_NAME] : ''},
                                </p>
                                <Badge ml='0' fontSize='0.6em' colorScheme='green' style={{
                                    marginTop: "-2.5px",
                                    padding: "2.5px 7.5px 2.5px 7.5px",
                                }}>
                                    Buyer/Agent
                                </Badge>
                            </div>
                        </div>
                        <div style={{
                            margin: "20px 0px 0px 0px",
                        }}>
                            <p style={{
                                fontWeight: "bold",
                            }}>
                                Take me to...
                            </p>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "10px 0px 0px 0px",
                            width: "100%",
                        }}>
                            <div
                                className="login_page_select_user_type_box"
                                onMouseDown={() => {
                                    if (user && user[ID]) {
                                        navigate('/user/buyer/dashboard/homes/' + user[ID]);
                                        // getChatTokenAndRedirectPage(chatClient, user[ID], user[FIRST_NAME], BUYER, state && state.path ? state.path : '/user/buyer/dashboard/homes/' + user[ID]);
                                    }
                                }}
                                style={{
                                    backgroundColor: "#05b96b",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    height: "80px",
                                    width: "125px",
                                    padding: "15px",
                                }}
                            >
                                <FaUser style={{
                                    color: "white",
                                    height: "30px",
                                    margin: "0px calc((100% - 30px)/2) 0px calc((100% - 30px)/2)",
                                    width: "30px",
                                }}/>
                                <p style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                }}>
                                    Buyer
                                </p>
                            </div>
                            <div 
                                className="login_page_select_user_type_box"
                                onMouseDown={() => {
                                    if (user && user[ID]) {
                                        navigate('/user/agent/dashboard/leads/' + user[ID]);
                                        // getChatTokenAndRedirectPage(chatClient, user[ID], user[FIRST_NAME], AGENT, state && state.path ? state.path : '/user/agent/dashboard/leads/' + user[ID]);
                                    }
                                }}
                                style={{
                                    backgroundColor: "#05b96b",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    height: "80px",
                                    width: "125px",
                                    padding: "15px",
                                }}
                            >
                                <FaUserTie style={{
                                    color: "white",
                                    height: "30px",
                                    margin: "0px calc((100% - 30px)/2) 0px calc((100% - 30px)/2)",
                                    width: "30px",
                                }}/>
                                <p style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                }}>
                                    Agent
                                </p>
                            </div>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        );
    }

    /* Name: handleLoginFormChange
     * Description: Listener we attach to our Inputs. Allows us to track the
     *              changes made to inputs.
     * Returns: None
     */
    const handleLoginFormChange = (event) => {
        form[event.target.name] = event.target.value;
    }

    return (
        <div>
            <div className="login-page">
                <NavigationBar isUserPage={false}/>
                {   
                    showRenderChooseUserTypeModal ?
                    renderChooseUserTypeModal() :
                    null
                }
                <div style={{
                    margin: "30px calc((100% - 600px)/2) 50px calc((100% - 600px)/2)",
                    width: "600px",
                }}>
                    <p className="login_page__title">
                        Sign in to your Rezied account
                    </p>
                    <br></br>
                    <form className="login_page__form">
                        <div>
                            <label className="login-page-form-label">
                                Email
                            </label>
                            <InputGroup>
                                <InputLeftElement pointerEvents='none'>
                                <Icon as={FaUser} color='#cccccc'/>
                                </InputLeftElement>
                                <Input
                                    name={FORM_EMAIL}
                                    onChange={handleLoginFormChange}
                                    style={{
                                        border: "1px solid #cccccc",
                                        borderRadius: "4px",
                                    }}
                                />
                            </InputGroup>
                        </div>
                        <div style={{
                            margin: "15px 0px 0px 0px",
                        }}>
                            <label className="login-page-form-label">
                                Password
                            </label>
                            <InputGroup>
                                <InputLeftElement pointerEvents='none'>
                                    <LockIcon color='#cccccc'/>
                                </InputLeftElement>
                                <Input
                                    onChange={handleLoginFormChange}
                                    name={FORM_PASSWORD}
                                    style={{
                                        border: "1px solid #cccccc",
                                        borderRadius: "4px",
                                    }}
                                    type='password'
                                />
                            </InputGroup>
                        </div>
                            
                        <Button 
                            className="hover"
                            onMouseDown={() => {
                                if (loginLoading) {
                                    return;
                                }
                                handleLoginFormSubmit();
                            }}
                            style={{
                                backgroundColor: "#05b96b",
                                color: "white",
                                margin: "20px 0px 0px 0px",
                                opacity: loginLoading ? "0.7" : "1.0",
                                width: "100%",
                            }}>
                                {
                                    loginLoading ? 
                                    <l-dot-pulse
                                        size="35"
                                        speed="1.25" 
                                        color="white" 
                                    ></l-dot-pulse> :
                                    <>Login</>
                                }
                            </Button>
                    </form>
                    <div style={{
                        textAlign: "center",
                    }}>
                        <div style={{
                            color: "grey",
                            display: "inline-block",
                            margin: "15px 0px 0px 0px",
                            textAlign: "center",
                        }}>
                            <div style={{
                                display: "flex",
                            }}>
                                <Link href="/auth/signup" style={{
                                    fontSize: "0.9em",
                                }}>Don't have an account? Sign Up</Link>
                                <p style={{
                                    margin: "-2px 7.5px 0px 7.5px",
                                }}>&#x2022;</p>
                                <Link href="/auth/password/reset" style={{
                                    fontSize: "0.9em",
                                }}>Forgot your Password?</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default LoginPage;