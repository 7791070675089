import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {
    Avatar,
    Button,
    ButtonGroup,
    Center,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import { FaFileLines, FaArrowRightLong, FaMapLocationDot, FaPhone } from "react-icons/fa6";

import {
    COORDINATES,
    DEFAULT_GET_TIMEOUT,
    CONNECTION_REMOVED_BY_BUYER,
    FIRST_NAME,
    FIRM,
    LAST_NAME,
    EMAIL,
    PHONE_NUMBER,
    GET,
    ID,
    PROFILE_PICTURE_URL,
    LICENSE_TYPE,
    ABOUT_DESCRIPTION,
    REPRESENTATION_STYLE,
    WORKING_STYLE,
    SALE_EXPERTISE,
    ASSET_SPECIALTY,
    COMMISSION_PERCENTAGE,
    NUMBER_OF_MONTHS,
    RETAINER_FEE_DOLLARS,
    RETAINER_PART_OF_COMMISSION,
    REPRESENTATION,
    IS_EXCLUSIVE,
    SINGLE_FAMILY_HOME,
    ADDRESS_LINE_ONE,
    ADDRESS_LINE_TWO,
    ADDRESS_CITY,
    ADDRESS_STATE,
    ADDRESS_ZIP_CODE,
    PURCHASE_PRICE,
    TRANSACTION_DATE
} from '../../../utils/constants';
import { isValidCoordinates } from '../../../utils/utility.js';

import { dollarfyPrice, prettifyDate } from '../../../utils/utility';

import './MyAgentPage.css';
import MyAgentAreasOfExpertise from './MyAgentAreasOfExpertise.js';

function MyAgentPage(props) {
    const userId = props.userId;
    const workingAgent = props.workingAgent;
    const workingAgentId = workingAgent[ID];

    const handleAddConnection = props.handleAddConnection;
    const setTriggerFetchActiveAgent = props.setTriggerFetchActiveAgent;

    const [workingAgentOffer, setWorkingAgentOffer] = useState(null);
    const [workingAgentOfferLoading, setWorkingAgentOfferLoading] = useState(true);

    const [workingAgentTransactions, setWorkingAgentTransactions] = useState(null);
    const [workingAgentTransactionsLoading, setWorkingAgentTransactionsLoading] = useState(true);

    const [totalTransactionsAmount, setTotalTransactionsAmount] = useState(0.0);

    const [documentsLoading, setDocumentsLoading] = useState(true);

    const [selectedAreaOfExpertise, setSelectedAreaOfExpertise] = useState(null);

    const [showFindMoreAgentsModal, setShowFindMoreAgentsModal] = useState(false);

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/buyer/offer/' + userId + '?agent_id=' + workingAgentId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            setWorkingAgentOffer(response.data);
            setWorkingAgentOfferLoading(false);
        })
    }, [userId, workingAgentId])

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/buyer/active_agent/files/' + userId + '/' + workingAgentId,
        }).then(response => {
            if (200 === response.status) {
                setDocumentsLoading(false);
            }
        }).catch(error => {
            setDocumentsLoading(false);
        })
    }, [userId, workingAgentId]);

    useEffect(() => {

        axios.all([
            axios({
                method: GET,
                url: '/api/v1/user/agent/transactions/' + workingAgentId,
                timeout: DEFAULT_GET_TIMEOUT,
            })
        ]).then(axios.spread((transactionsResponse) => {
            if (200 === transactionsResponse.status) {
                let transactions = transactionsResponse.data;
                let totalTransactionsAmount = 0.0;
                for (let i = 0; i < transactions.length; i++) {
                    totalTransactionsAmount += parseFloat(transactions[i][PURCHASE_PRICE]);
                }
                setTotalTransactionsAmount(totalTransactionsAmount);
                setWorkingAgentTransactions(transactions);
            }
        })).catch(error => {
            // TODO
        });
        setWorkingAgentTransactionsLoading(false);
    }, [workingAgentId]);

    const getPrettifySaleExpertise = (val) => {
        switch (val) {
            case "ShortSale":
                return "Short Sale";
            default:
                return val;
        }
    }

    const renderSaleExpertise = (agent) => {
        if (!agent || !agent[SALE_EXPERTISE] || null === agent[SALE_EXPERTISE]) {
            return "-";
        }
        let saleExpertise = agent[SALE_EXPERTISE];

        let result = [];
        for (var i = 0; i < saleExpertise.length; i++) {
            result.push(getPrettifySaleExpertise(saleExpertise[i]));
            result.push(<br></br>);
        }
        return result;
    }

    const renderAssetSpecialty = (agent) => {
        if (!agent || !agent[ASSET_SPECIALTY] || null === agent[ASSET_SPECIALTY]) {
            return "-";
        }
        let assetSpecialty = agent[ASSET_SPECIALTY];

        let result = [];
        for (var i = 0; i < assetSpecialty.length; i++) {
            result.push(getPrettifyAssetSpecialty(assetSpecialty[i]));
            // result.push(<br></br>);
        }
        return result.join(", ");
    }

    const getPrettifyAssetSpecialty = (val) => {
        switch (val) {
            case SINGLE_FAMILY_HOME:
                return "Single Family Home";
            default:
                return val;
        }
    }

    const getAssetSpecialtyValues = () => {
        let assetSpecialtyValues = [];
        let assetSpecialtyCheckboxes = document.getElementsByClassName("asset_specialty__checkbox");
        for (let i = 0; i < assetSpecialtyCheckboxes.length; i++) {
            let chakraCheckboxElement = assetSpecialtyCheckboxes[i];
            // The actual element is the first child, the input.
            let checkboxInput = chakraCheckboxElement.children[0];
            if (checkboxInput.checked === true) {
                assetSpecialtyValues.push(checkboxInput.value);
            }
        }
        return assetSpecialtyValues;
    }

    const WorkingAgentTransactions = () => {
        
        if (null === workingAgentTransactions) {
            return;
        }

        let transactions = [];

        for (let i = 0; i < workingAgentTransactions.length; i++) {
            let transaction = workingAgentTransactions[i];
            transactions.push(
                <div key={"my_agent_page__" + transaction[ID]} style={{
                    backgroundColor: "#efefef",
                    borderRadius: "4px",
                    display: "flex",
                    padding: "10px 10px 10px 10px",
                }}>
                    <p style={{
                        fontSize: "0.9em",
                        width: "50%",
                    }}>
                        {transaction[ADDRESS_LINE_ONE]} {transaction[ADDRESS_LINE_TWO]} {transaction[ADDRESS_CITY]}, {transaction[ADDRESS_STATE]} {transaction[ADDRESS_ZIP_CODE]}
                    </p>
                    <p style={{
                        fontSize: "0.9em",
                        width: "calc(40%/2)"
                    }}>
                        {prettifyDate(transaction[TRANSACTION_DATE])}
                    </p>
                    <p style={{
                        fontSize: "0.9em",
                        width: "calc(40%/2)"
                    }}>
                        ${dollarfyPrice(transaction[PURCHASE_PRICE])}
                    </p>
                    <p style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "0.9em",
                        width: "calc(10%)"
                    }}>
                        {transaction[REPRESENTATION]}
                    </p>
                </div>
            )
        }

        return (
            <div style={{
                margin: "10px 0px 0px 0px",
            }}>
                <p style={{
                    fontSize: "1.2em",
                    fontWeight: "bold",
                }}>
                    Transactions
                </p>
                <div style={{
                    display: "flex",
                    margin: "10px 0px 0px 0px",
                    // padding: "0px 25px 0px 25px",
                }}>
                    <p style={{
                        color: "grey",
                        fontSize: "0.7em",
                        fontWeight: "bold",
                        width: "50%",
                    }}>
                        ADDRESS
                    </p>
                    <p style={{
                        color: "grey",
                        fontSize: "0.7em",
                        fontWeight: "bold",
                        width: "20%"
                    }}>
                        CLOSING DATE
                    </p>
                    <p style={{
                        color: "grey",
                        fontSize: "0.7em",
                        fontWeight: "bold",
                        width: "20%"
                    }}>
                        SALES PRICE
                    </p>
                    <p style={{
                        color: "grey",
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "0.7em",
                        fontWeight: "bold",
                        width: "10%"
                    }}>
                        REPRESENTED
                    </p>
                </div>
                <div style={{
                    // backgroundColor: "#f6f6f6",
                    borderRadius: "12px",
                    height: "calc(100vh - 406px)",
                    margin: "10px 0px 0px 0px",
                    minHeight: "250px",
                    // padding: "15px 25px 15px 25px",
                    width: "100%",
                }}>
                    {transactions}
                </div>
            </div>
        );
    }

    const FindMoreAgentsModal = () => {
        return (
            <Modal size='md' isOpen={showFindMoreAgentsModal} onClose={() => {
                setShowFindMoreAgentsModal(false);
            }} isCentered style={{
            }}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader style={{
                        paddingBottom: "0px",
                    }}>
                        Find more Agents
                    </ModalHeader>
                    <ModalCloseButton onClose={() => {
                        setShowFindMoreAgentsModal(false);
                    }} onMouseDown={() => {
                        setShowFindMoreAgentsModal(false);
                    }}/>
                    <ModalBody style={{
                        paddingBottom: "20px",
                        paddingTop: "0px" 
                    }}>
                        <div style={{
                            margin: "10px 0px 20px 0px",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.9em",
                                lineHeight: "17.5px",
                            }}>
                                In order to view other agents, you must first unmatch with your agent. This may result in legal consequences depending on agreements you've signed and they will also be notified. Are you sure you would like to proceed? 
                            </p>
                        </div>
                        <ButtonGroup style={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}>
                            <Button className="positive_button hover" onMouseDown={() => setShowFindMoreAgentsModal(false)} size='sm'>
                                Take me back to my Agent
                            </Button>
                            <Button className="negative_button hover" size='sm' onMouseDown={() => {
                                handleAddConnection(workingAgent[ID], CONNECTION_REMOVED_BY_BUYER, setTriggerFetchActiveAgent);
                            }}>
                                I'm sure, unmatch me
                            </Button>
                        </ButtonGroup>
                    </ModalBody>
                </ModalContent>
            </Modal>
        )
    }

    if (workingAgentOfferLoading || documentsLoading || workingAgentTransactionsLoading) {
        return;
    }
    
    return (
        <div>
            <p style={{
                fontSize: "1.5em",
                fontWeight: "bold",
            }}>
                My Agent
            </p>
            <div style={{
                // display: "flex",
                justifyContent: "space-between",
                margin: "5px 0px 0px 0px",
            }}>
                <div style={{
                    // display: "flex",
                }}>
                    <div style={{
                        display: "flex",
                    }}>
                        <div style={{
                            backgroundColor: "#f6f6f6",
                            borderRadius: "12px",
                            display: "flex",
                            margin: "5px 0px 0px 0px",
                            padding: "15px 20px 15px 20px",
                            width: "100%",
                        }}>
                            <Avatar src={workingAgent[PROFILE_PICTURE_URL]} size='xl' style={{
                                backgroundColor: "#dddddd",
                                border: "2px solid #f2f2f2",
                                boxShadow: "none",
                                margin: "5px 0px 0px 0px",
                            }}></Avatar>
                            <div style={{
                                margin: "5px 0px 0px 20px",
                                minWidth: "275px",
                            }}>
                                <p style={{
                                    color: "black",
                                    fontSize: "1.3em",
                                    fontWeight: "bold",
                                    margin: "0px 0px 0px 0px",
                                }}>
                                    {workingAgent[FIRST_NAME]} {workingAgent[LAST_NAME]}
                                </p>
                                <p style={{
                                    color: "grey",
                                    fontSize: "0.9em",
                                    margin: "-5px 0px 0px 0px",
                                }}>
                                    {workingAgent[FIRM] ? workingAgent[FIRM] : "No Firm Found"}
                                </p>
                                <div style={{
                                    display: "flex",
                                    margin: "10px 0px 0px 0px",
                                }}>
                                    <EmailIcon style={{
                                        color: "#555555",
                                        height: "17.5px",
                                        width: "17.5px",
                                    }}/>
                                    <p style={{
                                        color: "#555555",
                                        fontSize: "0.9em",
                                        lineHeight: "15px",
                                        margin: "0px 0px 0px 10px",
                                    }}>
                                        {workingAgent ? workingAgent[EMAIL] : "-"}
                                    </p>
                                </div>
                                <div style={{
                                    display: "flex",
                                    margin: "7.5px 0px 0px 0px",
                                }}>
                                    <FaPhone style={{
                                        color: "#555555",
                                        height: "17.5px",
                                        width: "17.5px",
                                    }}/>
                                    <p style={{
                                        color: "#555555",
                                        fontSize: "0.9em",
                                        lineHeight: "15px",
                                        margin: "0px 0px 0px 10px",
                                    }}>
                                        {workingAgent ? workingAgent[PHONE_NUMBER] : "-"}
                                    </p>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                margin: "5px 0px 0px 50px",
                                overflow: "scroll",
                            }}>
                                <div style={{
                                    minWidth: "275px",
                                }}>
                                    <div className="my_agent_page__information_subbox">
                                        <p className="my_agent_page__information_box_title">
                                            DESCRIPTION
                                        </p>
                                        <p className="my_agent_page__information_box_text">
                                            {workingAgent && workingAgent[ABOUT_DESCRIPTION] ? workingAgent[ABOUT_DESCRIPTION] : "-"}
                                        </p>
                                    </div>
                                    <div className="my_agent_page__information_subbox">
                                        <p className="my_agent_page__information_box_title">
                                            LICENSE
                                        </p>
                                        <p className="my_agent_page__information_box_text">
                                            {workingAgent && workingAgent[LICENSE_TYPE] ? workingAgent[LICENSE_TYPE] : "-"}
                                        </p>
                                    </div>
                                    <div className="my_agent_page__information_subbox">
                                        <p className="my_agent_page__information_box_title">
                                            REPRESENTATION STYLE
                                        </p>
                                        <p className="my_agent_page__information_box_text">
                                            {workingAgent && workingAgent[REPRESENTATION_STYLE] ? workingAgent[REPRESENTATION_STYLE] : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div style={{
                                    margin: "0px 0px 0px 15px",
                                    minWidth: "200px",
                                }}>
                                    <div className="my_agent_page__information_subbox">
                                        <p className="my_agent_page__information_box_title">
                                            WORKING STYLE
                                        </p>
                                        <p className="my_agent_page__information_box_text">
                                            {workingAgent && workingAgent[WORKING_STYLE] ? workingAgent[WORKING_STYLE] : "-"}
                                        </p>
                                    </div>
                                    <div className="my_agent_page__information_subbox">
                                        <p className="my_agent_page__information_box_title">
                                            SALE EXPERTISE
                                        </p>
                                        <p className="my_agent_page__information_box_text">
                                            {workingAgent && workingAgent[SALE_EXPERTISE] ? renderSaleExpertise(workingAgent) : "-"}
                                        </p>
                                    </div>
                                </div>
                                <div style={{
                                    margin: "0px 0px 0px 15px",
                                }}>
                                    <div className="my_agent_page__information_subbox">
                                        <p className="my_agent_page__information_box_title">
                                            ASSET SPECIALTIES
                                        </p>
                                        <p className="my_agent_page__information_box_text">
                                            {workingAgent && workingAgent[ASSET_SPECIALTY] ? renderAssetSpecialty(workingAgent) : "-"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div style={{
                        backgroundColor: "#cccccc",
                        height: "1px",
                        margin: "15px 0px 15px 0px",
                        width: "100%",
                    }}></div> */}
                    <div style={{
                        margin: "20px 0px 0px 0px",
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                            <div style={{
                                width: "225px",
                            }}>
                                <p style={{
                                    fontSize: "1.2em",
                                    fontWeight: "bold",
                                    margin: "10px 0px 0px 0px",
                                }}>
                                    My Offer
                                </p>
                                <div style={{
                                    // backgroundColor: "#f6f6f6",
                                    borderRadius: "12px",
                                    justifyContent: "space-between",
                                    margin: "15px 0px 0px 0px",
                                    // padding: "15px 20px 15px 20px",
                                }}>
                                    <div className="my_agent_page__offer_subbox">
                                        <p className="my_agent_page__information_box_title">
                                            COMMISSION %
                                        </p>
                                        <p className="my_agent_page__information_box_text">
                                            {workingAgentOffer && workingAgentOffer[COMMISSION_PERCENTAGE] ? workingAgentOffer[COMMISSION_PERCENTAGE] : "-"}%
                                        </p>
                                    </div>
                                    <div className="my_agent_page__offer_subbox">
                                        <p className="my_agent_page__information_box_title">
                                            TERM (NO. OF MONTHS)
                                        </p>
                                        <p className="my_agent_page__information_box_text">
                                            {workingAgentOffer && workingAgentOffer[NUMBER_OF_MONTHS] ? workingAgentOffer[NUMBER_OF_MONTHS] : "-"} Months
                                        </p>
                                    </div>
                                    <div className="my_agent_page__offer_subbox">
                                        <p className="my_agent_page__information_box_title">
                                            RETAINER FEE ($)
                                        </p>
                                        <p className="my_agent_page__information_box_text">
                                            ${workingAgentOffer && workingAgentOffer[RETAINER_FEE_DOLLARS] ? workingAgentOffer[RETAINER_FEE_DOLLARS] : "-"}
                                        </p>
                                    </div>
                                    <div className="my_agent_page__offer_subbox">
                                        <p className="my_agent_page__information_box_title">
                                            RETAINER PART OF COMMISSION
                                        </p>
                                        <p className="my_agent_page__information_box_text">
                                            {workingAgentOffer && workingAgentOffer[RETAINER_PART_OF_COMMISSION] !== null ? (workingAgentOffer[RETAINER_PART_OF_COMMISSION] === true ? "Yes" : "No") : "-"}
                                        </p>
                                    </div>
                                    <div className="my_agent_page__offer_subbox">
                                        <p className="my_agent_page__information_box_title">
                                            REPRESENTATION
                                        </p>
                                        <p className="my_agent_page__information_box_text">
                                            {workingAgentOffer && workingAgentOffer[REPRESENTATION] ? workingAgentOffer[REPRESENTATION] : "-"}
                                        </p>
                                    </div>
                                    <div className="my_agent_page__offer_subbox">
                                        <p className="my_agent_page__information_box_title">
                                            EXCLUSIVITY
                                        </p>
                                        <p className="my_agent_page__information_box_text">
                                            {workingAgentOffer && workingAgentOffer[IS_EXCLUSIVE] !== null ? (workingAgentOffer[IS_EXCLUSIVE] === true ? "Exclusive" : "Non-Exclusive") : "-"}
                                        </p>
                                    </div>
                                </div>
                                <p style={{
                                    fontSize: "1.2em",
                                    fontWeight: "bold",
                                    margin: "15px 0px 5px 0px",
                                }}>
                                    Documents
                                </p>
                                <div style={{
                                    margin: "10px 0px 0px 0px",
                                }}>
                                    <div className="my_agent_page__information_subbox">
                                        <div className="my_agent_page__document_box">
                                            <FaFileLines className="my_agent_page__document_box_icon"/>
                                            <p className="my_agent_page__information_box_document_text">
                                                Agency Agreement
                                            </p>
                                        </div>
                                        <div className="my_agent_page__document_box">
                                            <FaFileLines className="my_agent_page__document_box_icon"/>
                                            <p className="my_agent_page__information_box_document_text">
                                                Purchase & Sale Agreement
                                            </p>
                                        </div>
                                        <div className="my_agent_page__document_box">
                                            <FaFileLines className="my_agent_page__document_box_icon"/>
                                            <p className="my_agent_page__information_box_document_text">
                                                Closing Disclosure
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                width: "calc(100% - 300px - 225px - 20px - 20px)",
                            }}>
                                <WorkingAgentTransactions/>
                            </div>
                            <div style={{
                                width: "300px"
                            }}>
                                <p style={{
                                    fontSize: "1.2em",
                                    fontWeight: "bold",
                                    margin: "10px 0px 5px 0px",
                                }}>
                                    Areas of Expertise
                                </p>
                                <MyAgentAreasOfExpertise workingAgent={workingAgent} setSelectedAreaOfExpertiseCallback={(area) => setSelectedAreaOfExpertise(area)}/>
                                <div id="map-container" style={{ 
                                    display: selectedAreaOfExpertise !== null && isValidCoordinates(selectedAreaOfExpertise[COORDINATES]) ? "block" : "none",
                                    borderRadius: "12px",
                                    height: 'calc(100vh - 250px - 170px)',
                                    maxHeight: "400px",
                                    width: "100%",
                                }}>
                                    <div id="map" style={{ 
                                        borderRadius: "8px",
                                        maxHeight: "400px",
                                        height: 'inherit',
                                        maxHeight: 'inherit',
                                        width: '100%'
                                    }}/>
                                </div> 
                                <div style={{
                                    display: selectedAreaOfExpertise !== null && isValidCoordinates(selectedAreaOfExpertise[COORDINATES]) ? "none" : "block",
                                    backgroundColor: "#f6f6f6",
                                    borderRadius: "12px",
                                    height: 'calc(100vh - 250px - 240px)',
                                    maxHeight: "400px",
                                    position: "relative",
                                    width: "100%",
                                }}>
                                    <Center id="map">
                                        <FaMapLocationDot style={{
                                            color: "grey",
                                            height: "25px",
                                            top: "calc(50% - 25px)",
                                            position: "absolute",
                                            width: "25px",
                                        }}/>
                                        <p style={{
                                            color: "grey",
                                            fontSize: "0.8em",
                                            margin: "5px 0px 0px 0px",
                                            position: "absolute",
                                            top: "50%",
                                        }}>
                                            Invalid address
                                        </p>
                                    </Center>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    margin: "15px 0px 0px 0px", 
                                }}>
                                    {showFindMoreAgentsModal ? <FindMoreAgentsModal/> : null}
                                    <div className="hover" size='sm'style={{
                                        display: "flex",
                                    }} onMouseDown={() => setShowFindMoreAgentsModal(true)}>
                                        <p style={{
                                            fontSize: "0.8em",
                                            lineHeight: "12.5px",
                                        }}>
                                            Find more Agents
                                        </p>
                                        <FaArrowRightLong style={{
                                            height: "12.5px",
                                            margin: "0px 0px 0px 5px",
                                            width: "12.5px",
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyAgentPage;