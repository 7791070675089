import React, {useState} from 'react';

import { ID } from '../../../utils/constants.js';
import { Switch } from '@chakra-ui/react';

import './PrivacyPage.css';

function UserPrivacySettingsPage(props) {

    const user = props.user;
    const updateUserNotifications = props.updateUserNotifications;

    let userId = user[ID];
    const [form, setForm] = useState([]);

    return (
        <div>
            <div style={{
                margin: "0px 0px 0px 10px",
                width: "calc(100% - 10px)"
            }}>
                <div className="user_notifications_settings_page_information_tab">
                    <div style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                    }}>
                        <div>
                            <label className="user_notifications_settings_page_information_label">
                                HIDE MY ACCOUNT
                            </label>
                            <p>
                                Hide my account from prospective agents
                            </p>
                        </div>
                        <Switch 
                            id='email-alerts'
                            onChange={(e) => {
                                let currUserNotificationSettings = user.notifications_preferences;
                                currUserNotificationSettings.hide_account_from_prospective_agents = e.target.checked;
                                form["notifications_preferences"] = currUserNotificationSettings;
                                updateUserNotifications({...form});
                            }}
                            defaultChecked={
                                user && 
                                user.notifications_preferences && 
                                user.notifications_preferences.hide_account_from_prospective_agents === true}
                        />
                    </div>
                </div>
                {/* <hr style={{
                    margin: "10px 0px 10px 0px",
                }}></hr>
                <div className="user_notifications_settings_page_information_tab">
                    <div style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                    }}>
                        <div>
                            Delete my account
                        </div>
                        <Switch id='email-alerts'/>
                    </div>
                </div> */}
            </div>
        </div>
    );

}

export default UserPrivacySettingsPage;