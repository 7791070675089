import React from 'react';
import axios from 'axios';
import { createContext, useContext, useState } from "react";

import {
  DEFAULT_DELETE_TIMEOUT,
  DEFAULT_GET_TIMEOUT,
  DEFAULT_POST_TIMEOUT,
  DELETE,
  GET,
  POST
} from '../../utils/constants';

const authContext = createContext();

export const useAuth = () => {
  return useContext(authContext);
}

function useProvideAuth() {

  const [authedUser, setAuthedUser] = useState(null);

  function signup(firstName, lastName, email, password, phoneNumber, userType) {
    return new Promise((res, rej) => {
      axios({
        method: POST,
        url: '/api/v1/user/signup',
        timeout: DEFAULT_POST_TIMEOUT,
        data: {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password: password,
            phone_number: phoneNumber,
            user_type: userType
        }
      }).then(response => {
          let user = response.data;
          if (response.status === 200) {
            setAuthedUser(user);
          }
          return user;
      }).then((aUser) => {
        return res(aUser);
      }).catch(error => {
        return rej(error);
      })
    })
  }

  function login(email, phoneNumber, password) {
    return new Promise((res, rej) => {
      axios({
        // We need 'withCredentials' to automatically send the session cookie to the server.
        withCredentials: true,
        // credentials: 'include',  
        method: POST,
        url: '/api/v1/user/login',
        timeout: DEFAULT_POST_TIMEOUT,
        data: {
            email: email.toLowerCase(),
            password: password,
            phone_number: phoneNumber
        },
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true,
            'Content-Type': 'application/json'
        }
      }).then(response => {
          let user = response.data;
          // Successful login.
          if (response.status === 200) {
              setAuthedUser(user);
          }
          return user;
      }).then((aUser) => {
        return res(aUser);
      }).catch(error => {
        return rej(error);
      });
    })
  };

  function logout() {
    return new Promise((res, rej) => {
      axios({
        withCredentials: true,
        method: DELETE,
        timeout: DEFAULT_DELETE_TIMEOUT,
        url: '/api/v1/user/logout'
      }).then(response => {
        if (200 === response.status) {
          setAuthedUser(null);
          return null;
        }
      }).then(() => {
        return res();
      }).catch(error => {
        // set authed to null anyways. We may not have been able to delete
        // session on server, but we can delete it on frontend anyways
        // and redirect them.
        setAuthedUser(null);
        return rej(error);
      })
    })
  }

  function autoLogin() {
    return new Promise((res, rej) => {
      axios({
        withCredentials: true,
        method: GET,
        timeout: DEFAULT_GET_TIMEOUT,
        url: '/api/v1/user/me',
      }).then(response => {
        // Valid user - log them in.
        if (200 === response.status) {
          let user = response.data;
          setAuthedUser(user);
          return user;
        }
      }).then((aUser) => {
        return res(aUser);
      }).catch(error => {
        return rej(error);
      });
    });
  }

  return {
    signup,
    login,
    logout,
    autoLogin,
    authedUser
  };
}

export function AuthProvider({ children }) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return useContext(authContext);
}