import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import axios from 'axios';

import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { FaAddressCard, FaBullhorn, FaEye, FaShieldHalved } from 'react-icons/fa6';

import {
    AGENT,
    GET,
    DEFAULT_GET_TIMEOUT,
    ID,
    SETTINGS,
    PATCH,
    DEFAULT_PATCH_TIMEOUT
} from '../../../utils/constants.js';

import BuyerDashboardParentPage from '../../buyer/utils/BuyerDashboardParentPage.js';

import './SettingsPage.css';
import '../../Generic.css';

import AgentDashboardParentPage from '../../agent/utils/AgentDashboardParentPage.js';
import UserProfileInformationSettingsPage from './ProfileInformationPage.js';
import UserSecuritySettingsPage from './SecurityPage.js';
import UserNotificationsSettingsPage from './NotificationsPage.js';
import UserPrivacySettingsPage from './PrivacyPage.js';

function UserSettingsPage(props) {

    const [user, setUser] = useState(null);
    const [searchParams] = useSearchParams();
    const userType = searchParams.get("user_type");

    const [triggerFetchUser] = useState(false);

    const [tabIndex, setTabIndex] = useState(0);
    const [userLoading, setUserLoading] = useState(true);

    const urlParams = useParams();
    const userId = urlParams[ID];

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            let user = response.data;
            setUser(user);
            setUserLoading(false);
        }).catch(error => {
            // TODO
        });
    }, [userId, triggerFetchUser]);

    const updateUserNotifications = (data) => {
        axios({
            method: PATCH,
            url: '/api/v1/user/profile/' + userId,
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: data,
        }).then(response => {
            // TODO
        }).catch(error => {
            // TODO
        });
    }


    const SettingsPanel = () => {
        return (
            <Tabs align="start" orientation="vertical" variant="unstyled" index={tabIndex} style={{
                margin: "10px 0px 0px 0px",
                padding: "0px !important",
                position: "absolute",
                width: "calc(100% - 210px - 20px)",
            }}>
                    <TabPanels style={{
                        width: "calc(100% - 250px - 20px)"
                    }}>
                        <TabPanel className="tabs__tab_panel" index={0}>
                            <UserProfileInformationSettingsPage user={user}/>
                        </TabPanel>
                        <TabPanel className="tabs__tab_panel" index={1}>
                            <UserSecuritySettingsPage user={user}/>
                        </TabPanel>
                        <TabPanel className="tabs__tab_panel" index={2}>
                            <UserNotificationsSettingsPage user={user} updateUserNotifications={updateUserNotifications}/>
                        </TabPanel>
                        <TabPanel className="tabs__tab_panel" index={3}>
                            <UserPrivacySettingsPage user={user} updateUserNotifications={updateUserNotifications}/>
                        </TabPanel>
                    </TabPanels>
                    <TabList style={{
                        backgroundColor: "transparent",
                        position: "absolute",
                        height: "calc(100vh - 50px)",
                        right: "0px",
                        padding: "0px 20px 10px 15px",
                        width: "250px",
                    }}>
                        <Tab className="user_settings__tab_box" onMouseDown={() => setTabIndex(0)} style={{
                            backgroundColor: tabIndex === 0 ? "#efefef" : "",
                        }}>
                            <FaAddressCard className="user_settings__tab_icon"/>
                            <p className="user_settings__tab_text">
                                Personal Information
                            </p>
                        </Tab>
                        <Tab className="user_settings__tab_box" onMouseDown={() => setTabIndex(1)} style={{
                            backgroundColor: tabIndex === 1 ? "#efefef" : "",
                        }}>
                            <FaShieldHalved className="user_settings__tab_icon"/>
                            <p className="user_settings__tab_text">
                                Security
                            </p>
                        </Tab>
                        <Tab className="user_settings__tab_box" onMouseDown={() => setTabIndex(2)} style={{
                            backgroundColor: tabIndex === 2 ? "#efefef" : "",
                        }}>
                            <FaBullhorn className="user_settings__tab_icon"/>
                            <p className="user_settings__tab_text">
                                Notifications
                            </p>
                        </Tab>
                        <Tab className="user_settings__tab_box" onMouseDown={() => setTabIndex(3)} style={{
                            backgroundColor: tabIndex === 3 ? "#efefef" : "",
                        }}>
                            <FaEye className="user_settings__tab_icon"/>
                            <p className="user_settings__tab_text">
                                Privacy
                            </p>
                        </Tab>
                    </TabList>
            </Tabs>
        );
    }

    if (userLoading) return;

    return (
        <>
        {
            userType === AGENT ? 
            <AgentDashboardParentPage user={user} tab={SETTINGS}>
                <SettingsPanel/>
            </AgentDashboardParentPage> :
            <BuyerDashboardParentPage user={user} tab={SETTINGS}>
                <SettingsPanel/>
                {/* <SidePanel>
                    <SettingsSidePanel/>
                </SidePanel> */}
            </BuyerDashboardParentPage>
        }
        </>
    );
}

export default UserSettingsPage;