import React from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Button,
} from '@chakra-ui/react'
import { FaArrowRightLong } from "react-icons/fa6";

import Footer from './Footer';
import NavigationBar from './NavigationBar';

import './BuysideCommissionTermsAndConditionsPage.css';

function BuysideCommissionTermsAndConditionsPage(props) {
    return (
        <div className="buyside_commission_terms_and_conditions_page">
            <NavigationBar/>
            <div className="buyside_commission_terms_and_conditions_page__parent_box">
                <p className="buyside_commission_terms_and_conditions_page__title">
                    Buyside Commission Terms & Conditions Page
                </p>
                <p className="buyside_commission_terms_and_conditions_page__subtitle">
                    Please read the following carefully as it governs the buy side commission terms and conditiony of your usage of our service, Rezied.
                </p>
                <Accordion allowToggle style={{
                    margin: "15px 0px 0px 0px",
                }}>
                    <AccordionItem>
                        <AccordionButton className="buyside_commission_terms_and_conditions_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                1. Parties to This Agreement
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="buyside_commission_terms_and_conditions_page__accordion_panel">
                            Users who access or use the Marketplace application on the Rezied website (“Marketplace”) with the potential to
                            receive or pay, directly or through an employing broker, a Commission Rebate or Commission Shortfall (both defined
                            below) agree to be subject to these Buyside Commission Terms and Conditions, including without limitation, the user
                            who entered into the Marketplace as a buyer (“Buyer”) and the licensed real estate representative who submitted the
                            final offer selected by the Buy qer (“Agent”)(collectively, the “Parties”), both as identified on the Marketplace. <b>Rezied Inc.
                            is not a party to these Buyside Commission Terms and Conditions.</b>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="buyside_commission_terms_and_conditions_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                2. Commission Amount Defined
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="buyside_commission_terms_and_conditions_page__accordion_panel">
                            Commission Amount, as used herein, means the final commission amount agreed upon by the Agent and the Buyer in,
                            and as identified on, the Marketplace. This is calculated as the total commission received by the Agent’s employing
                            broker and/or the Agent, if the Agent is the employing broker (collectively “Employing Broker”), for representing the
                            Buyer and is prior to any split of commission with the Agent, the Agent’s employing broker or any other agents or
                            brokers on the Buyer’s side of the transaction.`
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="buyside_commission_terms_and_conditions_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                3. Commission Rebate
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="buyside_commission_terms_and_conditions_page__accordion_panel">
                            Notwithstanding anything to the contrary contained herein, if the seller, or seller’s licensed real estate representative
                            (“Seller’s Agent”), pays or otherwise remits to the Employing Broker an amount exceeding the Commission Amount
                            (“Commission Rebate”), then that Commission Rebate will be paid to Buyer through the Employing Broker or Agent, as
                            the case may be, in a manner mutually agreed upon by Buyer and Agent.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="buyside_commission_terms_and_conditions_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                4. Commission Shortfall
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="buyside_commission_terms_and_conditions_page__accordion_panel">
                            Notwithstanding anything to the contrary contained herein, if the seller, or Seller’s Agent, pays or otherwise remits to
                            the Employing Broker an amount less than the Commission Amount (“Commission Shortfall”), then that Commission
                            Shortfall will be considered sufficient to satisfy the full Commission Amount, and no payment shall be owed by the Buyer
                            to the Agent or Employing Broker.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="buyside_commission_terms_and_conditions_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                            5. No Guarantee of Commission Rebate
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="buyside_commission_terms_and_conditions_page__accordion_panel">
                            <b>Neither the Agent, nor any other person or entity, guarantees any Commission Rebate amount.</b> The Commission
                            Rebate is subject to many factors including, but not limited to, the compensation provided for in the seller’s listing
                            agreement, the approval of the Buyer’s lender, and local, state and federal law. Commission Rebates may be limited to
                            the individuals or entities listed on any recorded deed that transfers ownership of the subject real property.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="buyside_commission_terms_and_conditions_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                6. Satisfaction of Terms
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="buyside_commission_terms_and_conditions_page__accordion_panel">
                            These Buyside Commission Terms and Conditions shall be deemed satisfied only if the Parties abide by same in
                            connection with any proposed or completed transaction, or if the Parties agree, in writing, to discontinue their
                            relationship without any further obligation owed to each other.
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionButton className="buyside_commission_terms_and_conditions_page__accordion_button">
                            <Box as='span' flex='1' textAlign='left'>
                                7. Compliance
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pb={4} className="buyside_commission_terms_and_conditions_page__accordion_panel">
                            Since Rezied Inc. is not a party to these Buyside Commission Terms and Conditions, the Parties understand and agree
                            that only the Parties shall be responsible for ensuring each other’s compliance with these Buyside Commission Terms
                            and Conditions. In the event of any conflict or inconsistency between these Buyside Commission Terms and Conditions
                            and any terms or conditions set forth in any agreement or other document relating to the subject matter contained
                            herein, including, but not limited to, any buyer agency agreement entered into by the Parties, the terms and conditions
                            set forth herein shall prevail.
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    margin: "25px 0px 25px 0px",
                }}>
                    <Button
                        className="positive_button_round hover"
                        onMouseDown={() => window.location.href="/terms-and-agreements"}
                        rightIcon={<FaArrowRightLong/>}
                    >
                        General Terms & Agreements
                    </Button>
                </div>
                <p style={{
                    color: "grey",
                    fontSize: "0.7em",
                    margin: "15px 0px 0px 0px",
                }}>
                    Last Modified <i>April 30th, 2024</i>
                </p>
            </div>
            <Footer/>
        </div>
    )
}

export default BuysideCommissionTermsAndConditionsPage;