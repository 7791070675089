import React, { useEffect, useState } from 'react';
import axios from 'axios'
import {
    Avatar,
    Center,
    ButtonGroup,
    Button,
    IconButton,
    Tooltip
} from '@chakra-ui/react';

import {
    ADDRESS_LINE_ONE,
    COORDINATES,
    GET,
    RESIROOTS_DEFAULT_OFFER,
    ID,
    FIRM,
    FIRST_NAME,
    LAST_NAME,
    PROFILE_PICTURE_URL,
    ABOUT_DESCRIPTION,
    LATITUDE,
    LONGITUDE,
    LICENSE_TYPE,
    REPRESENTATION_STYLE,
    WORKING_STYLE,
    ASSET_SPECIALTY,
    SALE_EXPERTISE,
    LICENSE_NUMBER,
    DEFAULT_GET_TIMEOUT
} from '../../../utils/constants.js';

import {
    FaCircleInfo,
    FaUserPlus, 
    FaElementor, 
    FaUserTie, 
    FaWrench, 
    FaHouseUser, 
    FaEyeSlash 
} from 'react-icons/fa6';

import {
    InfoIcon
} from '@chakra-ui/icons';

import { 
    prettifyLicenseType,
    prettifyPropertyType,
    prettifySaleExpertise,
    renderWorkingStyle
} from '../../../utils/utility.js';

import './AgentCard.css';
import '../../Generic.css';
import TransactionsTable from '../../agent/Transactions/TransactionsTable.js';

import Radar from 'radar-sdk-js';

function AgentCard(props) {

    const agent = props.agent;

    const [agentCardLoading, setAgentCardLoading] = useState(true);
    const [agentTransactions, setAgentTransactions] = useState(null);
    const [agentAreasOfExpertise, setAgentAreasOfExpertise] = useState(null);

    useEffect(() => {
        axios.all([
            axios({
                method: GET,
                url: '/api/v1/user/agent/transactions/' + agent[ID],
                timeout: DEFAULT_GET_TIMEOUT,
            }),
            axios({
                method: GET,
                url: '/api/v1/user/agent/expertise_areas/' + agent[ID],
                timeout: DEFAULT_GET_TIMEOUT,
            })
        ]).then(axios.spread((transactionsResponse, expertiseAreasResponse) => {
            if (200 === transactionsResponse.status) {
                let transactions = transactionsResponse.data;
                setAgentTransactions(transactions);
            }
            if (200 === expertiseAreasResponse.status) {
                let expertiseAreas = expertiseAreasResponse.data;
                setAgentAreasOfExpertise(expertiseAreas);
            }
            setAgentCardLoading(false);
        })).catch(error => {
            setAgentCardLoading(false);
        }) 
    }, [agent]);

    // const hasWorkingAgent = props.hasWorkingAgent;
    const [defaultOffer, setDefaultOffer] = useState(props.defaultOffer);
    
    const [setAndRenderContactAgentModal] = useState(props.setAndRenderContactAgentModal);

    useEffect(() => {
        if (defaultOffer) setDefaultOffer(RESIROOTS_DEFAULT_OFFER);
    }, [defaultOffer]);

    /*
     * Name: AgentInformation
     * Description: renders the offer portion of an agent card.
     * Parameters: None
     */
    const AgentInformation = (props) => {

        const agentAreasOfExpertise = props.agentAreasOfExpertise;

        const [selectedArea, setSelectedArea] = useState(null);

        useEffect(() => {
            if (agentAreasOfExpertise && agentAreasOfExpertise.length > 0) {
                let selectedArea = agentAreasOfExpertise[0];
                setSelectedArea(selectedArea);
            }
        }, [agentAreasOfExpertise]);

        if (selectedArea) {
            let latitude = selectedArea[COORDINATES][LATITUDE];
            let longitude = selectedArea[COORDINATES][LONGITUDE];
            let map = new Radar.ui.map({
                container: 'map-container',
                style: 'radar-default-v1',
                center: [longitude, latitude],
                zoom: 14,
            });
            Radar.ui.marker({ text: selectedArea[ADDRESS_LINE_ONE] })
                .setLngLat([longitude, latitude])
                .addTo(map);
        }
        
        const renderAgentIntro = () => {
            return (
                <div style={{
                    backgroundColor: "#f4f4f4",
                    borderRadius: "12px",
                    float: "left",
                    padding: "15px 25px 15px 25px",
                    width: "450px",
                }}>
                    <div>
                        <p style={{
                            fontWeight: "bold",
                            fontSize: "1.1em"
                        }}>
                            Intro
                        </p>
                        <p style={{
                            color: agent && agent[ABOUT_DESCRIPTION] ? "black" : "grey",
                            fontSize: "0.9em",
                            margin: "15px 0px 0px 0px",
                            textAlign: "center",
                        }}>
                            {agent && agent[ABOUT_DESCRIPTION] ? agent[ABOUT_DESCRIPTION] : "This agent does not have an intro yet"}
                        </p>
                    </div>
                    <div style={{
                        backgroundColor: "#cccccc",
                        height: "1px",
                        margin: "20px 0px 20px 0px",
                        width: "100%",
                    }}></div>
                    <div>
                        <div style={{
                            display: "flex",
                        }}>
                            <FaElementor style={{
                                color: "grey",
                                height: "15px",
                                width: "15px",
                            }}/>
                            <p style={{
                                color: "grey",
                                fontSize: "0.7em",
                                lineHeight: "15px",
                                padding: "0px",
                                margin: "0px 0px 0px 5px",
                            }}>
                                LICENSE TYPE
                            </p>
                        </div>
                        <p style={{
                            color: "black",
                            fontSize: "0.9em",
                            margin: "5px 0px 0px 0px"
                        }}>
                            {agent && agent[LICENSE_TYPE] ? prettifyLicenseType(agent[LICENSE_TYPE]) : "N/A"}
                        </p>
                    </div>
                    <div style={{
                        margin: "20px 0px 0px 0px",
                    }}>
                        <div style={{
                            display: "flex",
                        }}>
                            <FaUserTie style={{
                                color: "grey",
                                height: "15px",
                                width: "15px",
                            }}/>
                            <p style={{
                                color: "grey",
                                fontSize: "0.7em",
                                lineHeight: "15px",
                                padding: "0px",
                                margin: "0px 0px 0px 5px",
                            }}>
                                REPRESENTATION STYLE
                            </p>
                        </div>
                        <p style={{
                            color: "black",
                            fontSize: "0.9em",
                            margin: "5px 0px 0px 0px"
                        }}>
                            {agent && agent[REPRESENTATION_STYLE] ? agent[REPRESENTATION_STYLE] : "N/A"}
                        </p>
                    </div>
                    <div style={{
                        margin: "20px 0px 0px 0px",
                    }}>
                        <div style={{
                            display: "flex",
                        }}>
                            <FaWrench style={{
                                color: "grey",
                                height: "15px",
                                width: "15px",
                            }}/>
                            <p style={{
                                color: "grey",
                                fontSize: "0.7em",
                                lineHeight: "15px",
                                padding: "0px",
                                margin: "0px 0px 0px 5px",
                            }}>
                                WORKING STYLE
                            </p>
                        </div>
                        <p style={{
                            color: "black",
                            fontSize: "0.9em",
                            margin: "5px 0px 0px 0px"
                        }}>
                            {agent && agent[WORKING_STYLE] ? renderWorkingStyle(agent[WORKING_STYLE]) : "N/A"}
                        </p>
                    </div>
                    <div style={{
                        margin: "20px 0px 0px 0px",
                    }}>
                        <div style={{
                            display: "flex",
                        }}>
                            <FaHouseUser style={{
                                color: "grey",
                                height: "15px",
                                width: "15px",
                            }}/>
                            <p style={{
                                color: "grey",
                                fontSize: "0.7em",
                                lineHeight: "15px",
                                padding: "0px",
                                margin: "0px 0px 0px 5px",
                            }}>
                                SALE EXPERTISE
                            </p>
                        </div>
                        <p style={{
                            fontSize: "0.9em",
                            margin: "5px 0px 0px 0px"
                        }}>
                            {agent && agent[SALE_EXPERTISE] ? prettifySaleExpertise(agent[SALE_EXPERTISE]) : "N/A"}
                        </p>
                    </div>
                </div>
            );
        }

        const renderAgentAssetSpecialties = () => {
            if (!agent || !agent[ASSET_SPECIALTY]) {
                return null;
            }

            let specialties = [];

            for (let i = 0; i < agent[ASSET_SPECIALTY].length; i++) {
                let specialty = agent[ASSET_SPECIALTY][i];
                specialties.push(
                    <div style={{
                        backgroundColor: "#05b96b",
                        borderRadius: "25px",
                        color: "white",
                        margin: "0px 10px 0px 0px",
                        padding: "5px 10px 5px 10px",
                    }}>
                        <p style={{
                            fontSize: "0.8em",
                            fontWeight: "bold"
                        }}>
                            {prettifyPropertyType(specialty)}
                        </p>
                    </div>
                );
            }

            return (
                <div style={{
                    display: "flex"
                }}>
                    {specialties}
                    <Tooltip label='Assets this agent specializes in'>
                        <InfoIcon boxSize='0.9em' color="#777777" style={{
                            margin: "7.5px 0px 7.5px 0px",
                        }}/>
                    </Tooltip>
                </div>
            );
        }

        const renderAgentAreasOfExpertise = () => {

            let formattedAreas = [];
            if (agentAreasOfExpertise) {
                for (let i = 0; i < agentAreasOfExpertise.length; i++) {
                    let area = agentAreasOfExpertise[i];
                    formattedAreas.push(
                        <div className="hover" 
                            onMouseDown={() => setSelectedArea(area)}
                            style={{
                                backgroundColor: selectedArea && selectedArea.id === area.id ? "#05b96b" : "#dadada",
                                borderRadius: "12px",
                                cursor: "pointer",
                                margin: "0px 0px 10px 0px",
                                padding: "7.5px 12.5px 7.5px 12.5px",
                        }}>
                            <p style={{
                                color: selectedArea && selectedArea.id === area.id ? "white" : "black",
                                fontSize: "0.9em"
                            }}>
                                {agentAreasOfExpertise[i].formatted_address}
                            </p>
                        </div>
                    );
                }
            }

            return (
                <div style={{
                    backgroundColor: "#f4f4f4",
                    borderRadius: "12px",
                    height: agentAreasOfExpertise && agentAreasOfExpertise.length > 0 ? '' : '200px',
                    margin: "20px 0px 0px 0px",
                    padding: "15px 25px 15px 25px",
                    width: "100%"
                }}>
                    <p style={{
                        fontWeight: "bold",
                        fontSize: "1.1em"
                    }}>
                        Areas of Expertise
                    </p>
                    {
                        agentAreasOfExpertise && agentAreasOfExpertise.length > 0 ?
                        <div style={{   
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "15px 0px 0px 0px",
                            width: "100%"
                        }}>
                            <div>
                                {formattedAreas}
                            </div>
                            <div id="map-container" style={{ 
                                borderRadius: "12px",
                                // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                display: "block",
                                flexGrow: "1",
                                marginLeft: "25px"
                            }}/>
                        </div>
                        : 
                        <Center style={{
                            margin: "50px 0px 0px 0px",
                        }}>
                            <FaCircleInfo style={{
                                color: "grey",
                                height: "12.5px",
                                width: "12.5px",
                            }}/>
                            <p style={{
                                color: "grey",
                                fontSize: "0.9em",
                                margin: "0px 0px 0px 7.5px",
                                textAlign: "center",
                            }}>
                                This agent does not have any areas of expertise yet
                            </p>
                        </Center>
                    }
                </div>
            );
        }

        return (
            <div style={{
                borderRadius: "12px",
                height: "calc(100vh - 200px)",
                position: "relative",
                width: "100%",
            }}>
                <div>
                    <div style={{
                        display: "flex",
                        margin: "10px 0px 0px 0px",
                    }}>
                        <div style={{
                            display: "flex",
                            margin: "0px 0px 0px 50px",
                            position: "relative",
                            width: "100%",
                        }}>
                            <Avatar src={agent && agent[PROFILE_PICTURE_URL] && agent[PROFILE_PICTURE_URL] !== "" ?
                                agent[PROFILE_PICTURE_URL] : null} size='2xl' className="agent_card__avatar_large" style={{
                                    // border: "4px solid #efefef",
                                }}
                            />
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "50px 0px 0px 15px",
                                width: "100%",
                            }}> 
                                <div className="agent_card__title_box">
                                    <p className="agent_card__name_title">
                                        {agent ? agent[FIRST_NAME] + " " + agent[LAST_NAME] : ""}
                                    </p>
                                    <div style={{
                                        display: "flex",
                                    }}>
                                        <p style={{
                                            color: "grey",
                                            fontSize: "1.0em",
                                        }}>
                                            {agent && agent[FIRM] ? agent[FIRM] : "No Brokerage"}
                                        </p>
                                        <p style={{
                                            color: "grey",
                                            fontSize: "1.0em",
                                            margin: "0px 5px 0px 5px"
                                        }}>
                                        •
                                        </p>
                                        <p style={{
                                            color: "grey",
                                            fontSize: "1.0em",
                                        }}>
                                            {agent && agent[LICENSE_NUMBER] ? agent[LICENSE_NUMBER] : "No License Number"}
                                        </p>
                                    </div>
                                </div>
                                <ButtonGroup style={{
                                    margin: "40px 0px 0px 30px",
                                }}>
                                    <Button
                                        leftIcon={<FaUserPlus/>}
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                            if (e.target.target === this) {
                                                // If has a working agent, don't let them connect.
                                                // if (hasWorkingAgent) {
                                                //     toast({
                                                //         title: "You can only work with one agent at a time",
                                                //         description: "Unmatch from your current agent to work with another agent",
                                                //         status: 'info',
                                                //         duration: 3000,
                                                //         isClosable: true,
                                                //         position: 'top'
                                                //     });
                                                //     return; 
                                                // }
                                                setAndRenderContactAgentModal(props.agent)
                                            }
                                        }}
                                        size='sm'
                                        className="agent_card__connect_button positive_button hover"
                                        style={{
                                            zIndex: "2",
                                        }}
                                    >
                                        Connect
                                    </Button>
                                    <Button
                                        leftIcon={<FaEyeSlash/>}
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                            if (e.target.target === this) {
                                                // If has a working agent, don't let them connect.
                                                // if (hasWorkingAgent) {
                                                //     toast({
                                                //         title: "You can only work with one agent at a time",
                                                //         description: "Unmatch from your current agent to work with another agent",
                                                //         status: 'info',
                                                //         duration: 3000,
                                                //         isClosable: true,
                                                //         position: 'top'
                                                //     });
                                                //     return; 
                                                // }
                                                setAndRenderContactAgentModal(props.agent)
                                            }
                                        }}
                                        size='sm'
                                        className="agent_card__connect_button negative_light_grey_full_button hover"
                                        style={{
                                            zIndex: "2",
                                        }}
                                    >
                                        Not Interested
                                    </Button>
                                    <IconButton 
                                        icon={<FaUserPlus/>}
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                            if (e.target.target === this) {
                                                // If has a working agent, don't let them connect.
                                                // if (hasWorkingAgent) {
                                                //     toast({
                                                //         title: "You can only work with one agent at a time",
                                                //         description: "Unmatch from your current agent to work with another agent",
                                                //         status: 'info',
                                                //         duration: 3000,
                                                //         isClosable: true,
                                                //         position: 'top'
                                                //     });
                                                //     return; 
                                                // }
                                                setAndRenderContactAgentModal(props.agent)
                                            }
                                        }} 
                                        size='sm'
                                        className="agent_card__connect_icon_button positive_button hover"
                                        style={{
                                            zIndex: "2",
                                        }}
                                    />
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        backgroundColor: "#cccccc",
                        height: "1px",
                        margin: "25px 0px 25px 0px",
                        width: "100%"
                    }}>
                    </div>
                    <div style={{
                        justifyContent: "space-between",
                        margin: "25px 0px 0px 0px",
                        width: "100%",
                    }}>
                        {renderAgentIntro()}
                        <div style={{
                            float: "right",
                            overflowY: "scroll",
                            width: "calc(100% - 450px - 25px)"
                        }}>
                            {renderAgentAssetSpecialties()}
                            <TransactionsTable transactions={agentTransactions} readOnlyView={true}/>
                            {renderAgentAreasOfExpertise()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (agentCardLoading) return;

    return <AgentInformation agentAreasOfExpertise={agentAreasOfExpertise}/>;
}

export default AgentCard;