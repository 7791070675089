import React, { useEffect,  useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import {
    Avatar,
    Image,
    useToast
} from '@chakra-ui/react'

import { axiosGetBuyer } from '../../common/methods/methods.js';
import { checkRequireBuyerSignUp } from '../../../utils/utility.js';

import { MdEdit } from 'react-icons/md';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';

import {
    FIRST_NAME,
    EMAIL,
    LAST_NAME,
    GET,
    ID,
    PATCH,
    PHONE_NUMBER,
    PUT,
    PROFILE,
    DEFAULT_GET_TIMEOUT,
    DEFAULT_PATCH_TIMEOUT,
    DEFAULT_PUT_TIMEOUT,
} from '../../../utils/constants.js';
import { loadMime } from '../../../utils/utility.js';

import BuyerDashboardParentPage from '../utils/BuyerDashboardParentPage.js';
import AboutPanel from './AboutPanel.js';
import MainPanel from '../../common/MainPanel.js';
import { chaoticOrbit } from 'ldrs'

import BuyerSignUpInformationModal from '../utils/BuyerSignUpInformationModal.js';
import Loader from '../../common/Settings/Loader.js';

import './Page.css';
import '../../Generic.css';

function BuyerProfileDashboardPage(props) {

    const [user, setUser] = useState(null);
    const [showEditProfilePictureButton, setShowEditProfilePictureButton] = useState(false);
    const [profilePictureUploadLoading, setProfilePictureUploadLoading] = useState(false);
    const [buyer, setBuyer] = useState(null);

    const [triggerFetchUser, setTriggerFetchUser] = useState(false);
    const [triggerFetchBuyerProfile, setTriggerFetchBuyerProfile] = useState(false);

    const [userLoading, setUserLoading] = useState(true);
    const [buyerLoading, setBuyerLoading] = useState(true);

    const [finishSignUpFormBuyer, setFinishSignUpFormBuyer] = useState(null);
    const [finishSignUpFormLoading, setFinishSignUpFormLoading] = useState(true);

    const urlParams = useParams();
    const userId = urlParams[ID];

    const toast = useToast();
    
    chaoticOrbit.register()

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            let user = response.data;
            setUser(user);
            setUserLoading(false);
            setTimeout(function() {
                setProfilePictureUploadLoading(false);
            }, 500);
        }).catch(error => {
            // TODO
        });
    }, [userId, triggerFetchUser]);

    useEffect(() => {
        axiosGetBuyer(userId).then(buyer => {
            if (buyer && checkRequireBuyerSignUp(buyer) === true) {
                setFinishSignUpFormBuyer(buyer);
            } 
            setFinishSignUpFormLoading(false);
        }).catch(error => {
            setFinishSignUpFormLoading(false);
        });
    }, [userId]);

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/buyer/profile/' + userId,
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            let buyer = response.data;
            setBuyer(buyer);
        }).catch(error => {
            // TODO
        });
        setBuyerLoading(false);
    }, [userId, triggerFetchBuyerProfile])


    const handleUpdateBuyerProfile = (data, toastTitle, toastDescription, callback) => {
        axios({
            method: PATCH,
            url: '/api/v1/user/buyer/profile/' + userId,
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: data,
        }).then(response => {
            setTriggerFetchBuyerProfile(!triggerFetchBuyerProfile);
            toast({
                title: toastTitle,
                description: toastDescription,
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: 'top'
            });
            callback();
        }).catch(error => {
            // TODO
        }, []);
    }

    const handleUpdateUserProfilePicture = (key) => {
        axios({
            method: PATCH,
            url: '/api/v1/user/profile/' + userId,
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: {
                "profile_picture_url_key": key
            },
        }).then(response => {
            setTriggerFetchUser(!triggerFetchUser);
        }).catch(error => {
            // TODO
        }, []);
    }
    /*
     * Name: handleProfilePictureEdit
     * Description: This function triggers everytime we detect an input change
     *              on our <input type="file"> element for our profile picture.
     *              It first sets the image src to display the new picture,
     *              then it attempts to upload the image to our server. It
     *              does this by first determining the MIME type, then sending
     *              a request to the server for a GCS PUT signed URL. Once it
     *              has the URL, it uploads the file to that URL.
     */
    const handleProfilePictureInputChange = (e) => {
        
        setProfilePictureUploadLoading(true);
        let file = e.target.files[0];
        // Set our display as the new picture.
        loadMime(file, function(fileMimeType) {
            // Handle saving the file to our server in the background.
            axios({
                method: GET,
                url: '/api/v1/user/profile/picture/uploadURL?id=' + userId + "&mime_type=" + fileMimeType,
                timeout: DEFAULT_GET_TIMEOUT,
            }).then(response => {
                let uploadProfilePicture = response.data;
                let uploadSignedURL = uploadProfilePicture["upload_signed_url"];
                let storageKey = uploadProfilePicture["storage_key"];
                axios({
                    method: PUT,
                    url: uploadSignedURL,
                    timeout: DEFAULT_PUT_TIMEOUT,
                    data: file,
                    headers: {
                        'Content-Type': fileMimeType
                    }
                }).then(response=> {
                    handleUpdateUserProfilePicture(storageKey);
                }).catch(error=> {
                    // TODO
                });
                e.target.value = '';
            }).catch(error => {
                // TODO
            });
        }); 
    }

    if (userLoading || buyerLoading) return;

    return (
        <BuyerDashboardParentPage key={user} user={user} tab={PROFILE}>
            {
                finishSignUpFormLoading ?
                <div style={{
                    margin: "auto",
                }}>
                    <Loader/>
                </div> :
                <>
                    {
                        finishSignUpFormBuyer ?
                        <BuyerSignUpInformationModal
                            user={finishSignUpFormBuyer}
                            setFinishSignUpFormBuyer={setFinishSignUpFormBuyer}
                        />: null
                    }
                    <MainPanel>
                        <div style={{
                            margin: "10px 0px 0px 0px",
                        }}>
                            <div style={{
                                display: "flex",
                            }}>
                                <div 
                                    onMouseEnter={() => {
                                        setShowEditProfilePictureButton(true);
                                    }}
                                    onMouseLeave={() => {
                                        setShowEditProfilePictureButton(false);
                                    }}
                                    style={{
                                        borderRadius: "50%",
                                        position: "relative",
                                        pointerEvents: profilePictureUploadLoading ? "none" : "",
                                        textAlign: "center",
                                        width: "150px",
                                    }}
                                >
                                    <div style={{
                                        height: "150px",
                                        opacity: profilePictureUploadLoading ? "0.3" : "1.0",
                                        width: "150px",
                                    }}>
                                        {
                                            profilePictureUploadLoading ?
                                            // Default values shown
                                            <l-chaotic-orbit
                                                size="35"
                                                speed="1.5" 
                                                color="black" 
                                                style={{
                                                    position: "absolute",
                                                    left: "calc(50% - 17.5px)",
                                                    top: "calc(50% - 17.5px)",
                                                }}
                                            ></l-chaotic-orbit> : null
                                        }
                                        <div style={{
                                            backgroundColor: "#efefef",
                                            borderRadius: "50%",
                                            height: "150px",
                                            margin: "0px 0px 0px 50px",
                                            width: "150px",
                                        }}>
                                            {
                                                user && user["profile_picture_url"] ?
                                                <Image
                                                    borderRadius='full'
                                                    src={user["profile_picture_url"]}
                                                    alt={user[FIRST_NAME] + " " + user[LAST_NAME]}
                                                    style={{
                                                        border: "3px solid white",
                                                        objectFit: "cover",
                                                        overflow: "hidden",
                                                        height: "150px",
                                                        width: "100%",
                                                    }}
                                                /> :
                                                <Avatar 
                                                    boxSize='150px'    
                                                />
                                            }
                                        </div>
                                    </div>
                                    {
                                        showEditProfilePictureButton ?
                                        <div style={{
                                            position: "relative",
                                            width: "100%",
                                        }}>
                                            <label htmlFor="upload-profile-picture">
                                                <MdEdit
                                                    component="label"
                                                    style={{
                                                        backgroundColor: "#dddddd",
                                                        borderRadius: "25px",
                                                        color: "black",
                                                        cursor: "pointer",
                                                        left: "125px",
                                                        margin: "-40px 0px 0px 40px",
                                                        padding: "5px",
                                                        height: "30px",
                                                        width: "30px",
                                                        position: "absolute",
                                                    }}
                                                />
                                                
                                            </label>

                                        </div> : null
                                    }
                                    <input 
                                        accept="image/png,image/jpg,image/jpeg"
                                        id="upload-profile-picture"
                                        onChange={handleProfilePictureInputChange}
                                        style={{
                                            display: "none",
                                        }}
                                        type="file"
                                    ></input>
                                </div>
                                <div style={{
                                    margin: "60px 0px 0px 65px",
                                    position: "relative",
                                    width: "100%",
                                }}>
                                    <div style={{
                                        bottom: "0",
                                    }}>
                                        <p style={{
                                            fontSize: "1.7em",
                                            fontWeight: "bold",
                                            width: "100%",
                                        }}>
                                            {user[FIRST_NAME]} {user[LAST_NAME]}
                                        </p>
                                        <div style={{
                                            display: "flex",
                                            margin: "5px 0px 0px 0px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                            }}>
                                                <EmailIcon style={{
                                                    color: "grey",
                                                    height: "15px",
                                                    width: "15px"
                                                }}/>
                                                <p style={{
                                                    color: "#5A5A5A",
                                                    fontSize: "1.0em",
                                                    lineHeight: "15px",
                                                    margin: "0px 0px 0px 5px"
                                                }}>
                                                    {user[EMAIL]}
                                                </p>
                                            </div>
                                            <p style={{
                                                color: "grey",
                                                fontSize: "1.0em",
                                                lineHeight: "15px",
                                                margin: "0px 5px 0px 5px",
                                                padding: "0px"
                                            }}>
                                            •
                                            </p>
                                            <div style={{
                                                display: "flex",
                                            }}>
                                                <PhoneIcon style={{
                                                    color: "grey",
                                                    height: "15px",
                                                    width: "15px"
                                                }}/>
                                                <p style={{
                                                    color: "#5A5A5A",
                                                    fontSize: "1.0em",
                                                    lineHeight: "15px",
                                                    margin: "0px 0px 0px 5px"
                                                }}>
                                                    {user[PHONE_NUMBER]}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            backgroundColor: "#cccccc",
                            height: "1px",
                            margin: "25px 0px 25px 0px",
                            width: "100%"
                        }}>
                        </div>
                        <AboutPanel buyer={buyer} handleUpdateUserProfile={handleUpdateBuyerProfile} user={user}/>
                    </MainPanel>
                </>
            }
        </BuyerDashboardParentPage>
    );
}

export default BuyerProfileDashboardPage;