import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import SidePanelWrapper from '../../common/SidePanelWrapper';

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Avatar,
    Button,
    ButtonGroup,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useToast,
    Tooltip,
} from '@chakra-ui/react';

import { InfoIcon, NotAllowedIcon } from '@chakra-ui/icons';
import { FaPaperPlane, FaUsers } from "react-icons/fa6";
import { IoMdTime } from "react-icons/io";

import {
    DEFAULT_POST_TIMEOUT,
    FIRST_NAME,
    LAST_NAME,
    ID,
    GET,
    CONNECTION,
    POST,
    NOT_INTERESTED_BY_AGENT,
    CONNECTION_REQUEST_BY_BUYER_PENDING,
    CONNECTION_REQUEST_BY_AGENT_PENDING,
    CONNECTION_REQUEST_BY_BUYER_ACCEPTED,
    CONNECTION_REQUEST_BY_AGENT_ACCEPTED,
    CONNECTION_REQUEST_BY_AGENT_CANCELED,
    CONNECTION_REMOVED_BY_AGENT,
    STATUS,
    STATUSES,
    CONNECTIONS_CONNECTED,
    CONNECTIONS_PENDING,
    CONNECTIONS_CONTACTED,
    CONNECTIONS_NOT_INTERESTED,
    PRE_APPROVED,
    FIRST_TIME_BUYER,
    CREDIT_SCORE,
    NOT_INTERESTED_BY_AGENT_REMOVED,
    DEFAULT_GET_TIMEOUT,
    PROFILE_PICTURE_URL
} from '../../../utils/constants.js';

import { getPriceRange } from './UserCard.js';

function SidePanel(props) {

    const user = props.user;

    const [triggerFetchConnections, setTriggerFetchConnections] = useState(props.triggerFetchConnections);
    const triggerFetchConnectionsCallback = props.triggerFetchConnectionsCallback;
    const triggerFetchLeadsCallback = props.triggerFetchLeadsCallback;

    const [connectedConnections, setConnectedConnections] = useState(null);
    const [requestedByBuyerConnections, setRequestedByBuyerConnections] = useState(null);
    const [contactedByAgentConnections, setContactedByAgentConnections] = useState(null);
    const [notInterestedConnections, setNotInterestedConnections] = useState(null);
    const [connectionsPanelConnection, setConnectionsPanelConnection] = useState(null);

    const toast = useToast();

    const urlParams = useParams();
    const userId = urlParams[ID];

    useEffect(() => {
        axios({
            method: GET,
            url: '/api/v1/user/connections/' + userId + '?user_type=Agent&verbose=true',
            timeout: DEFAULT_GET_TIMEOUT,
        }).then(response => {
            if (200 === response.status) {
                let connections = response.data;
                let connectedConnections = [];
                let requestedByBuyerConnections = [];
                let contactedByAgentConnections = [];
                let notInterestedConnections = [];

                for (let i = 0; i < connections.length; i++) {
                    let connection = connections[i][CONNECTION];
                    let statuses = connection[STATUSES];
                    let lastStatus = statuses[statuses.length - 1];
                    let status = lastStatus[STATUS];
                    switch (status) {
                        case CONNECTION_REQUEST_BY_BUYER_ACCEPTED:
                        case CONNECTION_REQUEST_BY_AGENT_ACCEPTED:
                            connectedConnections.push(connection);
                            break;
                        case CONNECTION_REQUEST_BY_AGENT_PENDING:
                            contactedByAgentConnections.push(connection);
                            break;
                        case CONNECTION_REQUEST_BY_BUYER_PENDING:
                            requestedByBuyerConnections.push(connection);
                            break;
                        case NOT_INTERESTED_BY_AGENT:
                            notInterestedConnections.push(connection);
                            break;
                        default:
                    }
                }
                setConnectedConnections(connectedConnections);
                setRequestedByBuyerConnections(requestedByBuyerConnections);
                setContactedByAgentConnections(contactedByAgentConnections);
                setNotInterestedConnections(notInterestedConnections);
            }
        }).catch(error => {
            // TODO: Handle our error codes.
        })
    }, [userId, props.triggerFetchConnections]);

    const handleAddConnection = (receiverId, status) => {

        axios({
            method: POST,
            url: '/api/v1/user/connection/' + userId + '?user_type=Agent',
            timeout: DEFAULT_POST_TIMEOUT,
            data: {
                buyer_id: receiverId,
                agent_id: userId,
                statuses: [{status: status}]
            }
        }).then(response => {
            if (200 === response.status) {
                let title;
                let description;
                switch(status) {
                    case CONNECTION_REQUEST_BY_AGENT_CANCELED:
                        title = 'Request Canceled';
                        description = 'Successfully canceled your request.';
                        break;
                    case CONNECTION_REQUEST_BY_BUYER_ACCEPTED:
                        title = 'Request Accepted';
                        description = 'You just made a new connection!';
                        break;
                    case NOT_INTERESTED_BY_AGENT:
                        title = "Marked as not interested";
                        description = "You marked this user as not interested. You won't see them again in your feed.";
                        break;
                    case NOT_INTERESTED_BY_AGENT_REMOVED:
                        title = "This user is now unhidden";
                        description = "You'll start seeing this user in your feed again.";
                        break;
                    case CONNECTION_REMOVED_BY_AGENT:
                        title = "Removed user as a connection";
                        description = "You've removed this user as a connection.";
                        break;
                    default:
                }
                setTriggerFetchConnections(!props.triggerFetchConnections);
                triggerFetchConnectionsCallback();
                triggerFetchLeadsCallback();
                setConnectionsPanelConnection(null);
                toast({
                    title: title,
                    description: description,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'top'
                })
            }
        }).catch(error => {
            // TODO
        })
    }
    const ConnectionsPanelConnection = () => {
        
        const [connectionProfile, setConnectionProfile] = useState(null);
        const [connectionProfileLoading, setConnectionProfileLoading] = useState(true);

        let connection = connectionsPanelConnection[0];
        let connectionType = connectionsPanelConnection[1];

        useEffect(() => {
            axios({
                method: GET,
                url: '/api/v1/user/agent/lead/' + user[ID] + '/' + connection[ID],
                timeout: DEFAULT_GET_TIMEOUT,
            }).then(response => {
                if (200 === response.status) {
                    let connectionProfile = response.data;
                    setConnectionProfile(connectionProfile);
                    setConnectionProfileLoading(false);
                }
            }).catch(error => {
                // TODO
            })
        }, [connection[ID]])

        let footer = [];
        switch (connectionType) {
            case CONNECTIONS_PENDING:
                footer.push(
                    <div>
                        <Button
                            onMouseDown={() => {
                                handleAddConnection(connection[ID], NOT_INTERESTED_BY_AGENT)
                            }}
                            size="sm"
                            className="negative_button"
                        >
                            Not Interested
                        </Button>
                        <Button
                            onMouseDown={() => {
                                handleAddConnection(connection[ID], CONNECTION_REQUEST_BY_BUYER_ACCEPTED)
                            }}
                            size="sm"
                            className="positive_button"
                            style={{
                                margin: "0px 0px 0px 15px",
                            }}
                        >
                            Accept
                        </Button>
                    </div>
                );
                break;
            case CONNECTIONS_CONNECTED:
                footer.push(
                    <div>
                        <Button
                            onMouseDown={() => {
                                handleAddConnection(connection[ID], CONNECTION_REMOVED_BY_AGENT)
                            }}
                            size="sm"
                            className="negative_button"
                        >
                            Remove
                        </Button>
                        {/* <Button
                            onMouseDown={() => {
                                window.location.href = "/user/agent/dashboard/clients/" + userId;
                            }}
                            size="sm"
                            className="positive_button"
                            style={{
                                margin: "0px 0px 0px 15px",
                            }}
                        >
                            View Profile
                        </Button> */}
                    </div>
                );
                break;
            case CONNECTIONS_CONTACTED:
                footer.push(
                    <Button
                        className="negative_button"
                        onMouseDown={() => {
                            handleAddConnection(connection[ID], CONNECTION_REQUEST_BY_AGENT_CANCELED)
                        }}
                        size="sm"
                    >
                        Cancel Request
                    </Button>
                );
                break;
            case CONNECTIONS_NOT_INTERESTED:
                footer.push(
                    <ButtonGroup>
                        <Button
                            onMouseDown={() => {
                                handleAddConnection(connection[ID], NOT_INTERESTED_BY_AGENT_REMOVED)
                            }}
                            size="sm"
                            className="negative_button"
                        >
                            Unhide
                        </Button>
                        <Button
                            onMouseDown={() => {
                                setConnectionsPanelConnection(null);
                            }}
                            size="sm"
                            className="positive_button"
                        >
                            Connect
                        </Button>
                    </ButtonGroup>
                );
                break;
            default:
        }

        if (connectionProfileLoading) return;

        return (
            <Modal size='sm' isOpen={null !== connectionsPanelConnection} onClose={() => setConnectionsPanelConnection(null)} isCentered>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader style={{
                        display: "flex",
                        paddingBottom: "0px",
                    }}>
                        <Avatar src={connectionProfile ? connectionProfile[PROFILE_PICTURE_URL] : ""} size='sm'/>
                        <p style={{
                            fontSize: "1.0em",
                            fontWeight: "bold",
                            margin: "2.5px 0px 0px 7.5px",
                        }}>
                            {connectionProfile ? connectionProfile[FIRST_NAME] : "a"} {connectionProfile ? connectionProfile[LAST_NAME] : ""}
                        </p>
                    </ModalHeader>
                    <ModalCloseButton onClose={() => setConnectionsPanelConnection(null)} onMouseDown={() => setConnectionsPanelConnection(null)}/>
                    <ModalBody style={{
                        paddingBottom: "0",
                        paddingTop: "0",
                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "10px 0px 0px 0px",
                        }}>
                            <div style={{
                                width: "calc(50% - 10px)",
                            }}>
                                <label style={{
                                    color: "grey",
                                    fontSize: "0.7em",
                                }}>
                                    PRE-APPROVED
                                </label>
                                <p style={{
                                    fontSize: "0.9em",
                                }}>
                                    {connectionProfile && connectionProfile[PRE_APPROVED] !== null ? (
                                        connectionProfile[PRE_APPROVED] === true ? "Yes" : "No" ) : "-"}
                                </p>
                            </div>
                            <div style={{
                                width: "calc(50% - 10px)",
                            }}>
                                <label style={{
                                    color: "grey",
                                    fontSize: "0.7em",
                                }}>
                                    FIRST TIME BUYER
                                </label>
                                <p style={{
                                    fontSize: "0.9em",
                                }}>
                                    {connectionProfile ? (
                                        connectionProfile[FIRST_TIME_BUYER] === true ? "Yes" : "No") : "-"}
                                </p>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "10px 0px 0px 0px",
                        }}>
                            <div style={{
                                width: "calc(50% - 10px)",
                            }}>
                                <label style={{
                                    color: "grey",
                                    fontSize: "0.7em",
                                }}>
                                    PRICE RANGE
                                </label>
                                <p style={{
                                    fontSize: "0.9em",
                                }}>
                                    {connectionProfile ? getPriceRange(connectionProfile) : "No range found"}
                                </p>
                            </div>
                            <div style={{
                                width: "calc(50% - 10px)",
                            }}>
                                <div>
                                    <label style={{
                                        color: "grey",
                                        fontSize: "0.7em",
                                        margin: "0px 5px 0px 0px",
                                    }}>
                                        CREDIT SCORE
                                    </label>
                                    {
                                        connectionProfile && connectionProfile[CREDIT_SCORE] === 0 ?
                                        <Tooltip label={`A Credit Score of 0 most likely means the user hasn't filled out their profile yet`}fontSize='sm' size='sm' style={{
                                            lineHeight: "10px",
                                            margin: "0px 0px 0px 10px",
                                        }}>
                                            <InfoIcon/>
                                        </Tooltip> : <></>
                                    }
                                </div>
                                <p style={{
                                    fontSize: "0.9em",
                                }}>
                                    {connectionProfile && connectionProfile[CREDIT_SCORE] !== null ? connectionProfile[CREDIT_SCORE] : "-"}
                                </p>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {footer}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        );
    }

    const getConnectionPanelDefaultIndex = () => {
        let defaultIndexes = [];

        if (requestedByBuyerConnections && requestedByBuyerConnections.length > 0){
            defaultIndexes.push(0);
        }
        if (connectedConnections && connectedConnections.length > 0) {
            defaultIndexes.push(1);
        }
        if (contactedByAgentConnections && contactedByAgentConnections.length > 0) {
            defaultIndexes.push(2);
        }
        return defaultIndexes;
    }

    const renderConnectionInPanel = (connections, connectionType, displayIfNoneText, bgColor) => {

        if (!connections || null === connections || undefined === connections || connections.length === 0) {
            if (displayIfNoneText !== "") {
                return (
                    <div style={{
                        borderRadius: "25px",
                        padding: "10px 12.5px 10px 12.5px",
                    }}>
                        <p style={{
                            color: "grey",
                            fontSize: "0.8em",
                            padding: "0px 10px 0px 10px",
                        }}>
                            {displayIfNoneText}
                        </p>
                    </div>
                );
            }
            return;
        }
        let renderedConnections = [];
        for (let i = 0; i < connections.length; i++) {
            let connection = connections[i];
            renderedConnections.push(
                <div 
                    className="agent_leads_dashboard_page_panel_connection"
                    onMouseDown={() => {
                        setConnectionsPanelConnection([connection, connectionType]);
                    }}
                    style={{
                        backgroundColor: bgColor,
                        borderRadius: "0px 0px 0px 0px",
                        cursor: "pointer",
                        display: "flex",
                        padding: "10px 12.5px 10px 12.5px",
                        width: "100%",
                    }}
                >
                    <Avatar
                        src={connection[PROFILE_PICTURE_URL]}
                        size='xs'
                    />
                    <p style={{
                        fontSize: "0.9em",
                        lineHeight: "22.5px",
                        margin: "0px 0px 0px 10px",
                    }}>
                        {connection[FIRST_NAME]} {connection[LAST_NAME]}
                    </p>
                </div>
            );
        }

        return (
            <div style={{
                margin: "5px 0px 0px 0px",
            }}>
                {renderedConnections}
            </div>
        );
    }

    return (
        <SidePanelWrapper>
            <div>
                {connectionsPanelConnection !== null ? <ConnectionsPanelConnection/> : <></>}
                <label style={{
                    color: "grey",
                    fontSize: "0.8em",
                    padding: "10px",
                    width: "100%",
                }}>
                    LEADS
                </label>
                <Accordion allowMultiple defaultIndex={getConnectionPanelDefaultIndex()} style={{
                    margin: "0px 0px 0px 0px",
                }}>
                    <AccordionItem style={{
                        border: "0px",
                        margin: "5px 0px 0px 0px",
                    }}>
                        <AccordionButton className="agent_leads_dashboard_page_connections_panel_title">
                            <FaUsers style={{
                                margin: "0px 7.5px 0px 0px",
                            }}/>
                            Clients
                        </AccordionButton>
                        <AccordionPanel style={{
                            padding: "0px"
                        }}>
                            {renderConnectionInPanel(connectedConnections, CONNECTIONS_CONNECTED, "No Connections", "#efefef")}
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem style={{
                        border: "0px",
                        margin: "5px 0px 0px 0px",
                    }}>
                        <AccordionButton className="agent_leads_dashboard_page_connections_panel_title">
                            <IoMdTime style={{
                                color: "#5a5a5a",
                                margin: "0px 7.5px 0px 0px",
                            }}/>
                            Pending Requests
                        </AccordionButton>
                        <AccordionPanel style={{
                            padding: "0px"
                        }}>
                            {renderConnectionInPanel(requestedByBuyerConnections, CONNECTIONS_PENDING, "No Pending Requests", "#efefef")}
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem style={{
                        border: "0px",
                        margin: "5px 0px 0px 0px",
                    }}>
                        <AccordionButton className="agent_leads_dashboard_page_connections_panel_title">
                            <FaPaperPlane style={{
                                margin: "0px 7.5px 0px 0px",
                            }}/>
                            Contacted Clients
                        </AccordionButton>
                        <AccordionPanel style={{
                            padding: "0px"
                        }}>
                            {renderConnectionInPanel(contactedByAgentConnections, CONNECTIONS_CONTACTED, "None Contacted", "#efefef")}
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem style={{
                        border: "0px",
                        margin: "5px 0px 0px 0px",
                    }}>
                        <AccordionButton className="agent_leads_dashboard_page_connections_panel_title">
                            <NotAllowedIcon style={{
                                margin: "0px 7.5px 0px 0px"
                            }}/>
                            Not Interested
                        </AccordionButton>
                        <AccordionPanel style={{
                            padding: "0px"
                        }}>
                            {renderConnectionInPanel(notInterestedConnections, CONNECTIONS_NOT_INTERESTED, "None", "#efefef")}
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </SidePanelWrapper>
    )

}

export default SidePanel;