import React, {useEffect, useState} from 'react';
import axios from 'axios';

import {
    ADDRESS_LINE_ONE,
    ID,
    DEFAULT_GET_TIMEOUT,
    GET,
    COORDINATES,
    LATITUDE,
    LONGITUDE
} from '../../../utils/constants';
import { isValidCoordinates } from '../../../utils/utility.js';

import PropertyCard from './PropertyCard';
import Radar from 'radar-sdk-js';
import maplibregl from 'maplibre-gl';

function PropertyCards(props) {
 
    const user = props.user;
    const properties = props.properties;
    const setSelectedHomeCallback = props.setSelectedHomeCallback;

    const userId = user[ID];

    const [selectedHome, setSelectedHome] = useState(null);
    const [selectedHomeMap, setSelectedHomeMap] = useState(null);
    const [selectedHomeMarker, setSelectedHomeMarker] = useState(null);

    const [triggerFetchProperties, setTriggerFetchProperties] = useState(false);

    const [selectedHomeLoading, setSelectedHomeLoading] = useState(true);

    useEffect(() => {
        if (properties.length > 0) {
            let selectedHome = properties[0];
            if (selectedHome && selectedHome[COORDINATES]) {
                setSelectedHome(selectedHome);
                let latitude = selectedHome[COORDINATES][LATITUDE];
                let longitude = selectedHome[COORDINATES][LONGITUDE];
                let map = new Radar.ui.map({
                    container: 'map',
                    style: 'radar-default-v1',
                    center: [longitude, latitude],
                    zoom: 14,
                });
                setSelectedHomeMap(map);
                const marker = Radar.ui.marker({ text: selectedHome[ADDRESS_LINE_ONE] })
                    .setLngLat([longitude, latitude])
                    .addTo(map);
                setSelectedHomeMarker(marker);
                setSelectedHomeLoading(false);
            }
        }
    }, [])

    const renderPropertyCards = () => {
        
        var propertyCards = [];

        for (var i = 0; i < properties.length; i++) {
            let property = properties[i];
            propertyCards.push(
                <PropertyCard
                    key={"property_card" + property[ID]}
                    callbackTrigger={() => setTriggerFetchProperties(!triggerFetchProperties)}
                    property={property} 
                    setSelectedHome={(home) => {
                        setSelectedHome(home);
                        setSelectedHomeCallback(home);
                        if (isValidCoordinates(home[COORDINATES])) {
                            selectedHomeMap.setCenter([home[COORDINATES][LONGITUDE], home[COORDINATES][LATITUDE]]);
                            selectedHomeMarker.setLngLat([home[COORDINATES][LONGITUDE], home[COORDINATES][LATITUDE]]).setPopup(new maplibregl.Popup().setHTML(home[ADDRESS_LINE_ONE]));
                        }
                    }}
                    isSelectedHome={selectedHome[ID] === property[ID]}
                    user={user}
                />
            );
        }
        return propertyCards;
    }

    if (selectedHomeLoading) return;

    return renderPropertyCards();
}

export default PropertyCards;