import React, { useState } from 'react';
import axios from 'axios';

import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    useToast
} from '@chakra-ui/react';

import { FaRegStar, FaStar, FaTrashAlt } from 'react-icons/fa';
import { FaHouseChimney } from 'react-icons/fa6';
import { MdClose, MdEdit } from 'react-icons/md';
import { dotPulse } from 'ldrs';

import {
    ADDITIONAL_ANNUAL_FEES,
    ADDRESS_CITY,
    ADDRESS_LINE_ONE, ADDRESS_LINE_TWO,
    ADDRESS_STATE,
    ADDRESS_ZIP_CODE,
    APARTMENT,
    CONDOMINIUM,
    DEFAULT_DELETE_TIMEOUT,
    DEFAULT_PATCH_TIMEOUT,
    DELETE,
    DUPLEX,
    ID,
    IS_FAVORITE,
    MAINTENANCE_PERCENT,
    MANUFACTURED,
    MONTHLY_HOA_FEE,
    MULTIPLEX,
    PATCH,
    PROPERTY_TYPE,
    PURCHASE_PRICE,
    RENOVATION_COSTS,
    SINGLE_FAMILY_HOME,
    TOWNHOME,
    TRAILER
} from '../../../utils/constants.js';
import { dollarfyPrice, prettifyPropertyType } from '../../../utils/utility.js';

import './PropertyCard.css';

function PropertyCard(props) {

    const user = props.user;
    const property = props.property;
    const callbackTrigger = props.callbackTrigger;
    const isSelectedHome = props.isSelectedHome;

    const [showPropertyModal, setShowPropertyModal] = useState(false);
    const setSelectedHome = props.setSelectedHome;

    const toast = useToast();
    dotPulse.register();


    const handleUpdateProperty = (data, callback) => {
        axios({
            method: PATCH,
            url: '/api/v1/user/buyer/properties/' + user[ID] + '/' + property[ID],
            timeout: DEFAULT_PATCH_TIMEOUT,
            data: data,
        }).then(response => {
            if (200 === response.status) {
                callbackTrigger();
            }
        }).catch(error => {

        });
    }

    const PropertyModal = () => {

        const [editProperty, setEditProperty] = useState(false);
        const [propertyForm] = useState([]);
        const [deletePropertyLoading, setDeletePropertyLoading] = useState(false);

        const handleDeleteProperty = () => {

            setDeletePropertyLoading(true);
            axios({
                method: DELETE,
                url: '/api/v1/user/buyer/properties/' + user[ID] + '/' + property[ID],
                timeout: DEFAULT_DELETE_TIMEOUT,
            }).then(response => {
                if (response.data === 200) {
                    // Successful delete, so we need to remove the loan from our state.
                    toast({
                        title: 'Deleted Home',
                        description: "Successfully deleted your property",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                        position: 'top'
                    });
                    setDeletePropertyLoading(false);
                    callbackTrigger();
                }
            }).catch(error => {
                setDeletePropertyLoading(false);
            })
        }

        return (
            <>
                <Modal size='sm' isOpen={showPropertyModal} onClose={() => setShowPropertyModal(false)} isCentered>
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader style={{
                            paddingBottom: "0px",
                        }}>
                            <p style={{
                                fontSize: "1.0em",
                                fontWeight: "bold",
                            }}>
                                {property[ADDRESS_LINE_ONE]} {property[ADDRESS_LINE_TWO]}
                            </p>
                        </ModalHeader>
                        <ModalCloseButton onClose={() => setShowPropertyModal(false)} onMouseDown={() => setShowPropertyModal(false)}/>
                        <ModalBody style={{
                            paddingBottom: "20px",
                            paddingTop: "0px",
                        }}>
                            <p style={{
                                color: "grey",
                                fontSize: "0.8em"
                            }}>
                                {property[ADDRESS_CITY]}, {property[ADDRESS_STATE]} {property[ADDRESS_ZIP_CODE]}
                            </p>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "10px 0px 0px 0px",
                                width: "100%",
                            }}>
                                <div style={{
                                    width: "calc(40% - 10px)"
                                }}>
                                    <div>
                                        <p className="property_card__title">
                                            PURCHASE PRICE
                                        </p>
                                        {
                                            editProperty ?
                                            <Input 
                                                className="default_input"
                                                name={PURCHASE_PRICE}
                                                onChange={(e) => {
                                                    propertyForm[e.target.name] = parseFloat(e.target.value);
                                                }}
                                                placeholder={"$" + dollarfyPrice(property[PURCHASE_PRICE])}
                                                size='sm'
                                            /> :
                                            <p>
                                                ${dollarfyPrice(property[PURCHASE_PRICE])}
                                            </p>
                                        }
                                    </div>
                                    <div style={{
                                        margin: "10px 0px 0px 0px",
                                    }}>
                                        <p className="property_card__title">
                                            ADDITIONAL FEES
                                        </p>
                                        {
                                            editProperty ?
                                            <Input 
                                                className="default_input"
                                                name={ADDITIONAL_ANNUAL_FEES}
                                                onChange={(e) => {
                                                    propertyForm[e.target.name] = parseFloat(e.target.value);
                                                }}
                                                placeholder={"$" + dollarfyPrice(property[ADDITIONAL_ANNUAL_FEES]) + "/yr"}
                                                size='sm'
                                            /> :
                                            <p>
                                                ${dollarfyPrice(property[ADDITIONAL_ANNUAL_FEES])}/yr.
                                            </p>
                                        }
                                    </div>
                                    <div style={{
                                        margin: "10px 0px 0px 0px",
                                    }}>
                                        <p className="property_card__title">
                                            HOA
                                        </p>
                                        {
                                            editProperty ?
                                            <Input 
                                                className="default_input"
                                                name={MONTHLY_HOA_FEE}
                                                onChange={(e) => {
                                                    propertyForm[e.target.name] = parseFloat(e.target.value);
                                                }}
                                                placeholder={"$" + dollarfyPrice(property[MONTHLY_HOA_FEE]) + "/mo."}
                                                size='sm'
                                            /> :
                                            <p>
                                                ${dollarfyPrice(property[MONTHLY_HOA_FEE])}/mo.
                                            </p>
                                        }
                                    </div>
                                </div>
                                <div style={{
                                    width: "calc(60% - 10px)"
                                }}>
                                   <div>
                                        <p className="property_card__title">
                                            PROPERTY TYPE
                                        </p>
                                        {
                                            editProperty ?
                                            <Select 
                                                name={PROPERTY_TYPE}
                                                variant='outline'
                                                size='sm'
                                                className="default_input"
                                                onChange={(e) => {
                                                    propertyForm[e.target.name] = e.target.value;
                                                }}
                                            >
                                                <option selected={property[PROPERTY_TYPE] === SINGLE_FAMILY_HOME} value={SINGLE_FAMILY_HOME}>Single Family Home</option>
                                                <option selected={property[PROPERTY_TYPE] === APARTMENT} value={APARTMENT}>Apartment</option>
                                                <option selected={property[PROPERTY_TYPE] === CONDOMINIUM} value={CONDOMINIUM}>Condominium</option>
                                                <option selected={property[PROPERTY_TYPE] === TOWNHOME} value={TOWNHOME}>Townhome</option>
                                                <option selected={property[PROPERTY_TYPE] === DUPLEX} value={DUPLEX}>Duplex</option>
                                                <option selected={property[PROPERTY_TYPE] === MULTIPLEX} value={MULTIPLEX}>Multiplex</option>
                                                <option selected={property[PROPERTY_TYPE] === TRAILER} value={TRAILER}>Trailer</option>
                                                <option selected={property[PROPERTY_TYPE] === MANUFACTURED} value={MANUFACTURED}>Manufactured</option>
                                            </Select> :
                                            <p>
                                                {prettifyPropertyType(property[PROPERTY_TYPE])}
                                            </p>
                                        }
                                        
                                    </div>
                                    <div style={{
                                        margin: "10px 0px 0px 0px",
                                    }}>
                                        <p className="property_card__title">
                                            RENOVATION COSTS
                                        </p>
                                        {
                                            editProperty ?
                                            <Input 
                                                className="default_input"
                                                name={RENOVATION_COSTS}
                                                onChange={(e) => {
                                                    propertyForm[e.target.name] = parseFloat(e.target.value);
                                                }}
                                                placeholder={"$" + dollarfyPrice(property[RENOVATION_COSTS])}
                                                size='sm'
                                            /> :
                                            <p>
                                                ${dollarfyPrice(property[RENOVATION_COSTS])}
                                            </p>
                                        }
                                    </div>
                                    <div style={{
                                        margin: "10px 0px 0px 0px",
                                    }}>
                                        <p className="property_card__title">
                                            MAINTENANCE
                                        </p>
                                        {
                                            editProperty ?
                                            <Input 
                                                className="default_input"
                                                name={MAINTENANCE_PERCENT}
                                                onChange={(e) => {
                                                    propertyForm[e.target.name] = parseFloat(e.target.value);
                                                }}
                                                placeholder={property[MAINTENANCE_PERCENT] + "%"}
                                                size='sm'
                                            /> :
                                            <p>
                                                {property[MAINTENANCE_PERCENT]}%
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                            <ButtonGroup style={{
                                alignItems: "right",
                                float: "right",
                                margin: "15px 0px 0px 0px",
                            }}>
                                {
                                    editProperty ?
                                    <>
                                        <IconButton 
                                            className="negative_button"
                                            icon={<MdClose/>}
                                            onMouseDown={() => setEditProperty(false)}
                                            size='sm'
                                        ></IconButton> 
                                        <Button
                                            className="positive_button"
                                            onMouseDown={() => handleUpdateProperty({...propertyForm})}
                                            size='sm'
                                        >
                                            Save
                                        </Button>
                                    </> :
                                    <>
                                        <IconButton 
                                            className="negative_button hover"
                                            icon={<MdEdit/>}
                                            onMouseDown={() => setEditProperty(true)}
                                            size='sm'
                                        ></IconButton> 
                                        <Button
                                            className="positive_button hover"
                                            leftIcon={<FaTrashAlt/>}
                                            onMouseDown={() => handleDeleteProperty()}
                                            size='sm'
                                        >
                                            {
                                                deletePropertyLoading ?
                                                <l-dot-pulse
                                                    size="25"
                                                    speed="1.25" 
                                                    color="white" 
                                                ></l-dot-pulse> :
                                                <>Delete</>
                                            }
                                        </Button>
                                    </>
                                }
                            </ButtonGroup>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>
        );
    }

    return (
        <>
            {showPropertyModal ? <PropertyModal/> : null}
            <Card minW='2xs' onMouseDown={() => setSelectedHome(property)} className="property_card hover" style={{
                backgroundColor: isSelectedHome ? "#05b96b" : "#efefef",
            }}>
                <CardBody className="property_card__body">
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        <div style={{
                            display: "flex",
                        }}>
                            <FaHouseChimney className="property_card__icon" style={{
                                color: isSelectedHome ? "white" : "black",
                            }}/>
                            <p className="property_card__mobile_price" style={{
                                color: isSelectedHome ? "white" : "black",
                            }}>
                                ${dollarfyPrice(property[PURCHASE_PRICE])}
                            </p>
                            <p className="property_card__web_price" style={{
                                color: isSelectedHome ? "white" : "black",
                            }}>
                                ${dollarfyPrice(property[PURCHASE_PRICE])}
                            </p>
                        </div>
                        {
                            property && property[IS_FAVORITE] === true ? 
                            <FaStar 
                                className="hover"
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    handleUpdateProperty({is_favorite: false});
                                }}
                                style={{
                                    color: isSelectedHome ? "white" : "black",
                                    height: "20px",
                                    width: "20px"
                            }}/> :
                            <FaRegStar 
                                className="hover"
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    handleUpdateProperty({is_favorite: true});
                                }}
                                style={{
                                    color: isSelectedHome ? "white" : "black",
                                    height: "20px",
                                    width: "20px",
                            }}/>
                        }
                    </div>
                    <div className="property_card__info_box">
                        <p className="property_card__text" style={{
                            color: isSelectedHome ? "white" : "black",
                        }}>
                            {property[ADDRESS_LINE_ONE]} {property[ADDRESS_LINE_TWO]}
                        </p>
                        <p className="property_card__text" style={{
                            color: isSelectedHome ? "white" : "black",
                        }}>
                            {property[ADDRESS_CITY] + ", " + property[ADDRESS_STATE] + " " + property[ADDRESS_ZIP_CODE]}
                        </p>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

export default PropertyCard;